import Link from 'next/link';
import ISPLink from '@isp/link';

const InternalLink = ({ children, href, as, shallow = false, ...props }) => (
	<Link href={href} as={as} shallow={shallow} legacyBehavior>
		<ISPLink destination={as} {...props}>
			{children}
		</ISPLink>
	</Link>
);

export default InternalLink;

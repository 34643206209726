import { getOrCreateStore } from '@Lib/with-redux-store';
import get from 'lodash/get';
import NextI18Next from '@Lib/i18n';
import Router from 'next/router';
import { appendTagManagerScript, getTagManagerId } from '@Helpers/scripts/TagManager';
import * as UserActions from '@Reducers/user/actions';
import storage from '@Storage';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import {
	EssentialCookies,
	FunctionalCookies,
	TrackingCookies,
	UsercentricConsents,
	UsercentricsCookies,
	MarketingCookies,
	ROW_MARKETS_USING_MARKETING_COOKIES,
} from '@Constants/cookies';
import { Markets } from '@Constants/market';

const STORAGE_NAMESPACE = 'ISP_';

export type CookieType = EssentialCookies | FunctionalCookies | TrackingCookies | MarketingCookies;

export const isMarketingCookiesEnabled = () => {
	const store = getOrCreateStore({});
	const isRow = get(store.getState(), 'configuration.market.isRow');
	const code = get(store.getState(), 'configuration.market.code');
	return !isRow || ROW_MARKETS_USING_MARKETING_COOKIES.includes(code.toLowerCase() as Markets);
};

export const isGTMScriptAppended = () => {
	const store = getOrCreateStore({});
	const { gtmTracingAccepted } = get(store.getState(), 'user', false);
	return gtmTracingAccepted;
};

export const setGAConsentStatus = (status = false) => {
	const store = getOrCreateStore({});
	store.dispatch(UserActions.SET_GA_CONSENT_STATUS(status));
};

export const setAIConsentStatus = (status = false) => {
	const store = getOrCreateStore({});
	store.dispatch(UserActions.SET_TRACING_CONSENT_STATUS(status));
};

export const acceptIndividualConsent = (consent: UsercentricConsents) => {
	const expirationDate = new Date();
	expirationDate.setFullYear(expirationDate.getFullYear() + 1);
	switch (consent) {
		case UsercentricConsents.API_JWT_COOKIE:
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.API_JWT}`, 'true');
			break;
		case UsercentricConsents.ASP_NET_COOKIES:
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.ASP_NET_CORE_COOKIES}`, 'true');
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.ASP_NET_CORE_SESSION}`, 'true');
			break;
		case UsercentricConsents.CURRENT_LANGUAGE_COOKIE:
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.NEXT_I18_NEXT}`, 'true');
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.PUBLIC_LANGUAGE_SELECTION}`, 'true');
			break;
		case UsercentricConsents.CONSENT_COOKIE:
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.COOKIE_CONSENT}`, 'true');
			break;
		case UsercentricConsents.LOCATION_MARKET_COOKIE:
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.LOCATION_MARKET}`, 'true');
			break;
		case UsercentricConsents.APPLICATION_INSIGHT_COOKIES:
			localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_BUFFER}`, 'true');
			localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_SENT_BUFFER}`, 'true');
			localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_SESSION}`, 'true');
			localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_USER}`, 'true');
			setAIConsentStatus(true);
			break;
		case UsercentricConsents.USER_PREFERRED_LANGUAGE_COOKIE:
			localStorage.setItem(FunctionalCookies.ISP_USER_PREFERRED_LANGUAGE, 'true');
			storage.setCookie(
				`${FunctionalCookies.ISP_USER_PREFERRED_LANGUAGE}_${Router.query.market}`,
				geti18nLanguage(NextI18Next.i18n.language),
				null,
				{
					expires: expirationDate,
					path: '/',
				}
			);
			break;
		case UsercentricConsents.GOOGLE_TAG_MANAGER:
			localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GA}`, 'true');
			localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GAT}`, 'true');
			localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GID}`, 'true');
			if (!isGTMScriptAppended()) {
				setGAConsentStatus(true);
				appendTagManagerScript(getTagManagerId());
			}
			break;
		case UsercentricConsents.GOOGLE_ANALYTICS_COOKIE:
			if (isMarketingCookiesEnabled()) {
				localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GOOGLE_ANALYTICS_COOKIE}`, 'true');
			}
			break;
		case UsercentricConsents.LINKEDIN_INSIGHT_TAG:
			if (isMarketingCookiesEnabled()) {
				localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.LINKEDIN_INSIGHT_TAG}`, 'true');
			}
			break;
		case UsercentricConsents.LINKEDIN_ANALYTICS_COOKIE:
			if (isMarketingCookiesEnabled()) {
				localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.LINKEDIN_ANALYTICS_COOKIE}`, 'true');
			}
			break;
		case UsercentricConsents.GOOGLE_CAMPAIGN_MANAGER:
			if (isMarketingCookiesEnabled()) {
				localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.GOOGLE_CAMPAIGN_MANAGER}`, 'true');
			}
			break;
		case UsercentricConsents.GOOGLE_MARKETING_PRODUCTS:
			if (isMarketingCookiesEnabled()) {
				localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.GOOGLE_MARKETING_PRODUCTS}`, 'true');
			}
			break;
		case UsercentricConsents.FACEBOOK_SOCIAL_PLUGINS:
			if (isMarketingCookiesEnabled()) {
				localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.FACEBOOK_SOCIAL_PLUGINS}`, 'true');
			}
			break;
		case UsercentricConsents.SITE_PERSONALIZATION:
		case UsercentricConsents.USERCENTRIC_CMP:
		default:
			break;
	}
};

export const clearIndividualConsent = (consent: UsercentricConsents) => {
	const store = getOrCreateStore({});
	const defaultLanguage = get(store.getState(), 'configuration.languageConfiguration.defaultLanguage');
	const expirationDate = new Date();
	expirationDate.setFullYear(expirationDate.getFullYear() + 1);
	switch (consent) {
		case UsercentricConsents.API_JWT_COOKIE:
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.API_JWT}`, 'false');
			break;
		case UsercentricConsents.ASP_NET_COOKIES:
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.ASP_NET_CORE_COOKIES}`, 'false');
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.ASP_NET_CORE_SESSION}`, 'false');
			break;
		case UsercentricConsents.CURRENT_LANGUAGE_COOKIE:
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.NEXT_I18_NEXT}`, 'false');
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.PUBLIC_LANGUAGE_SELECTION}`, 'false');
			break;
		case UsercentricConsents.CONSENT_COOKIE:
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.COOKIE_CONSENT}`, 'false');
			break;
		case UsercentricConsents.LOCATION_MARKET_COOKIE:
			localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.LOCATION_MARKET}`, 'false');
			break;
		case UsercentricConsents.APPLICATION_INSIGHT_COOKIES:
			localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_BUFFER}`, 'false');
			localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_SENT_BUFFER}`, 'false');
			localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_SESSION}`, 'false');
			localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_USER}`, 'false');
			break;
		case UsercentricConsents.USER_PREFERRED_LANGUAGE_COOKIE:
			localStorage.setItem(FunctionalCookies.ISP_USER_PREFERRED_LANGUAGE, 'false');
			storage.deleteCookie(`${FunctionalCookies.ISP_USER_PREFERRED_LANGUAGE}_${Router.query.market}`, `${new Date()}`);
			storage.setCookie(
				`${FunctionalCookies.ISP_USER_PREFERRED_LANGUAGE}_${Router.query.market}`,
				defaultLanguage,
				null,
				{
					expires: expirationDate,
					path: '/',
				}
			);
			break;
		case UsercentricConsents.GOOGLE_TAG_MANAGER:
			localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GA}`, 'false');
			localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GAT}`, 'false');
			localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GID}`, 'false');
			break;
		case UsercentricConsents.GOOGLE_ANALYTICS_COOKIE:
			if (isMarketingCookiesEnabled()) {
				localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GOOGLE_ANALYTICS_COOKIE}`, 'false');
			}
			break;
		case UsercentricConsents.LINKEDIN_INSIGHT_TAG:
			if (isMarketingCookiesEnabled()) {
				localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.LINKEDIN_INSIGHT_TAG}`, 'false');
			}
			break;
		case UsercentricConsents.LINKEDIN_ANALYTICS_COOKIE:
			if (isMarketingCookiesEnabled()) {
				localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.LINKEDIN_ANALYTICS_COOKIE}`, 'false');
			}
			break;
		case UsercentricConsents.GOOGLE_CAMPAIGN_MANAGER:
			if (isMarketingCookiesEnabled()) {
				localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.GOOGLE_CAMPAIGN_MANAGER}`, 'false');
			}
			break;
		case UsercentricConsents.GOOGLE_MARKETING_PRODUCTS:
			if (isMarketingCookiesEnabled()) {
				localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.GOOGLE_MARKETING_PRODUCTS}`, 'false');
			}
			break;
		case UsercentricConsents.FACEBOOK_SOCIAL_PLUGINS:
			if (isMarketingCookiesEnabled()) {
				localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.FACEBOOK_SOCIAL_PLUGINS}`, 'false');
			}
			break;
		case UsercentricConsents.USERCENTRIC_CMP:
		case UsercentricConsents.SITE_PERSONALIZATION:
		default:
			break;
	}
};

export const acceptEssentialCookies = () => {
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.API_JWT}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.ASP_NET_CORE_COOKIES}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.ASP_NET_CORE_SESSION}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.LOCATION_MARKET}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.NEXT_I18_NEXT}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.PUBLIC_LANGUAGE_SELECTION}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.COOKIE_CONSENT}`, 'true');
};

export const acceptFunctionalCookies = () => {
	localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_BUFFER}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_SENT_BUFFER}`, 'true');
	localStorage.setItem(FunctionalCookies.ISP_USER_PREFERRED_LANGUAGE, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_SESSION}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_USER}`, 'true');
};

export const acceptTrackingCookies = () => {
	localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GOOGLE_ANALYTICS_COOKIE}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GA}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GAT}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GID}`, 'true');
	if (!isGTMScriptAppended()) {
		setGAConsentStatus(true);
		appendTagManagerScript(getTagManagerId());
	}
};

export const acceptMarketingCookies = () => {
	localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.LINKEDIN_INSIGHT_TAG}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.LINKEDIN_ANALYTICS_COOKIE}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.GOOGLE_CAMPAIGN_MANAGER}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.GOOGLE_MARKETING_PRODUCTS}`, 'true');
	localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.FACEBOOK_SOCIAL_PLUGINS}`, 'true');
};

export const acceptCookies = () => {
	acceptEssentialCookies();
	acceptFunctionalCookies();
	acceptTrackingCookies();
	if (isMarketingCookiesEnabled()) acceptMarketingCookies();
};

export const clearEssentialCookies = () => {
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.API_JWT}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.ASP_NET_CORE_COOKIES}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.ASP_NET_CORE_SESSION}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.LOCATION_MARKET}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.NEXT_I18_NEXT}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.PUBLIC_LANGUAGE_SELECTION}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${EssentialCookies.COOKIE_CONSENT}`, 'false');
};

export const clearFunctionalCookies = () => {
	localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_BUFFER}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_SENT_BUFFER}`, 'false');
	localStorage.setItem(FunctionalCookies.ISP_USER_PREFERRED_LANGUAGE, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_SESSION}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${FunctionalCookies.AI_USER}`, 'false');
};

export const clearTrackingCookies = () => {
	localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GOOGLE_ANALYTICS_COOKIE}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GA}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GAT}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${TrackingCookies.GID}`, 'false');
};

export const clearUsercentricsCookies = () => {
	localStorage.removeItem(`${UsercentricsCookies.UC_CONSENTS}`);
	localStorage.removeItem(`${UsercentricsCookies.UC_SETTINGS}`);
	localStorage.removeItem(`${UsercentricsCookies.USERCENTRICS}`);
};

export const clearMarketingCookies = () => {
	localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.LINKEDIN_INSIGHT_TAG}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.LINKEDIN_ANALYTICS_COOKIE}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.GOOGLE_CAMPAIGN_MANAGER}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.GOOGLE_MARKETING_PRODUCTS}`, 'false');
	localStorage.setItem(`${STORAGE_NAMESPACE}${MarketingCookies.FACEBOOK_SOCIAL_PLUGINS}`, 'false');
};

export const clearAcceptedCookies = () => {
	clearEssentialCookies();
	clearFunctionalCookies();
	clearTrackingCookies();
	clearUsercentricsCookies();
	if (isMarketingCookiesEnabled()) clearMarketingCookies();
	document.cookie.split(';').forEach(c => {
		document.cookie = c
			.replace(/^ +/, '')
			.replace(/=.*/, '=;expires='.concat(new Date().toUTCString()).concat(';path=/'));
	});
};

export const isCookieAccepted = (cookieType: CookieType) => {
	if (typeof window !== 'undefined') {
		const isAccepted = localStorage.getItem(
			`${cookieType === FunctionalCookies.ISP_USER_PREFERRED_LANGUAGE ? '' : STORAGE_NAMESPACE}${cookieType.toString()}`
		);

		return isAccepted && isAccepted === 'true';
	}
	return false;
};

export const getCookieNameList = () => {
	const cookieNames: string[] = [];

	cookieNames.concat(Object.keys(EssentialCookies));
	cookieNames.concat(Object.keys(FunctionalCookies));
	cookieNames.concat(Object.keys(TrackingCookies));
	cookieNames.concat(Object.keys(UsercentricsCookies));
	if (isMarketingCookiesEnabled()) cookieNames.concat(Object.keys(MarketingCookies));

	return cookieNames;
};

export const clearExternalCookies = () => {
	const protectedCookies: string[] = getCookieNameList();

	document.cookie.split(';').forEach(cookie => {
		let isCookieProtected = false;
		protectedCookies.forEach(protectedCookie => {
			if (cookie.includes(protectedCookie)) {
				isCookieProtected = true;
			}
		});
		if (!isCookieProtected) {
			document.cookie = cookie
				.replace(/^ +/, '')
				.replace(/=.*/, '=;expires='.concat(new Date().toUTCString()).concat(';path=/'));
		}
	});
};

export const setCookie = (key: string, value: string | boolean) => {
	const expirationDate = new Date();
	expirationDate.setFullYear(expirationDate.getFullYear() + 1);
	storage.setCookie(key, value, null, {
		expires: expirationDate,
		path: '/',
	});
};

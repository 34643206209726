import { initializeUserCentrics } from './UsercentricsScript';
import { initializeTagManager } from './TagManager';

export const handleOnMountScripts = () => {
	initializeUserCentrics();
	initializeTagManager();

	// handleAIConsent();
	// if ((config.APP_ENV || '').toUpperCase() !== AppConfig.ENV.LOCAL) loadAppInsights();
};

import { useState, useEffect } from 'react';
import NextI18Next from '@Lib/i18n';
import { useTranslation } from 'react-i18next';
import { getEquipmentCatalogueProducts, EquipmentCatalogueData } from '@Apis/shop';
import Header from '@Partials/Equipment/Header';
import SearchEquipment from '@Partials/Equipment/Search';
import Catalogue from '@Partials/Equipment/Catalogue';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import Feature from '@Shared/feature/Feature';
import { EQUIPMENT_DEFAULT_PAGE_SIZE, EQUIPMENT_DEFAULT_PAGE_NUMBER } from '@Constants/product/equipment';
import { FeatureKeys } from '@Constants/common';

const Equipment = () => {
	const [catalogueData, setCatalogueData] = useState<EquipmentCatalogueData>();
	const [catalogueProductsLoading, setCatalogueProductsLoading] = useState<boolean>(false);
	const [catalogueProductsError, setCatalogueProductsError] = useState<boolean>(false);
	const [searchKeyword, setSearchKeyword] = useState<string>('');

	const language = geti18nLanguage(NextI18Next.i18n.language);
	const { t } = useTranslation(['home', 'shop']);

	const getCatalogueProducts = (
		pageNumber = EQUIPMENT_DEFAULT_PAGE_NUMBER,
		pageSize = EQUIPMENT_DEFAULT_PAGE_SIZE,
		term = searchKeyword
	) => {
		setCatalogueData(null);
		setCatalogueProductsLoading(true);
		setCatalogueProductsError(false);
		setSearchKeyword(term);

		return getEquipmentCatalogueProducts(pageNumber, pageSize, term, language)
			.then(res => setCatalogueData(res))
			.catch(() => setCatalogueProductsError(true))
			.finally(() => setCatalogueProductsLoading(false));
	};

	useEffect(() => {
		getCatalogueProducts();
	}, [language]);

	return (
		<div>
			<Header translate={t} />
			<Feature
				feature={FeatureKeys.ARVATO_BLOCKED}
				toggle={
					<>
						<SearchEquipment translate={t} getCatalogueProducts={getCatalogueProducts} />
						<Catalogue
							searchKeyword={searchKeyword}
							catalogueData={catalogueData}
							getCatalogueProducts={getCatalogueProducts}
							catalogueProductsError={catalogueProductsError}
							catalogueProductsLoading={catalogueProductsLoading}
						/>
					</>
				}
			>
				<div />
			</Feature>
		</div>
	);
};

export default Equipment;

import get from 'lodash/get';
import find from 'lodash/find';
import { domToReact } from 'html-react-parser';
import ISPIcon, { IconSize, IconType, IconVariant } from '@isp/icon';
import ISPLink, { LinkVariant } from '@isp/link';
import LinkWithIcon, { IconPosition } from '@isp/link-with-icon';
import { CMSTable, CMSTableWithIcons } from '@Shared/cms-content/CMSContent';
import { Patterns } from '@Constants/common';
import { ListItemTypes, TransformAttributes } from '@Constants/umbraco';
import '@Partials/RemanParts/Sections/index.scss';

export function transform(node) {
	if (node.type === 'tag' && node.name === 'span' && (node.attribs.class || '').includes('icon-blue')) {
		return renderIcon(node, IconVariant.BLUE, IconSize.LARGE);
	}
	if (node.type === 'tag' && node.name === 'span' && (node.attribs.class || '').includes('icon-gray')) {
		return renderIcon(node, IconVariant.GRAY, IconSize.LARGE);
	}
	if (
		node.type === 'tag' &&
		(node.name === 'span' || node.name === 'div') &&
		(node.attribs.class || '').includes(TransformAttributes.COLLAPSIBLE_PAGE_CONTENT_ID)
	) {
		return renderCollapsibleContent(node);
	}
	if (node.type === 'tag' && node.name === 'li') {
		if ((node.parent.attribs.style || '').includes(TransformAttributes.LIST_STYLE_DISC)) {
			return renderListItem(node, ListItemTypes.DISC);
		}
		return renderListItem(node, ListItemTypes.ICON);
	}
	if (node.type === 'tag' && node.name === 'p') {
		const hasFilterableTable = find(node.children, { data: TransformAttributes.FILTERABLE_TABLE_TEMPLATE });
		const hasFreeContentTable = find(node.children, {
			data: TransformAttributes.FREE_CONTENT_FILTERABLE_TABLE_TEMAPLTE,
		});
		if (hasFilterableTable) {
			return renderFilterableTable();
		}
		if (hasFreeContentTable) {
			return renderFreeContentFilterableTable();
		}
		return renderParagraph(node);
	}
	if (node.type === 'tag' && node.name === 'a') {
		return renderLinkItem(node);
	}
	if (node.type === 'tag' && node.name === 'table') {
		if ((node.attribs.style || '').includes(TransformAttributes.TABLE_WITH_ICONS_ID)) {
			return renderIconTable(node);
		}
		return renderTable(node, get(node, 'parent.attribs.class', '') !== TransformAttributes.COLLAPSIBLE_PAGE_CONTENT_ID);
	}
	if (node.type === 'tag' && node.name === 'tbody') {
		return handleTableHighlight(node);
	}
	return node;
}

export const renderFilterableTable = () => {
	return <div id={TransformAttributes.FILTERABLE_TABLE_ID}></div>;
};

export const renderFreeContentFilterableTable = () => {
	return <div id={TransformAttributes.FREE_CONTENT_FILTERABLE_TABLE_ID}></div>;
};

export const renderCollapsibleContent = node => {
	const initiallyCollapsed = (node.attribs.class || '').includes(TransformAttributes.INITIALLY_COLLAPSED);
	return (
		<div style={{ display: 'none' }} data-collapsible data-initially-collapsed={initiallyCollapsed}>
			{domToReact(node.children, { replace: transform })}
		</div>
	);
};

export const renderListItem = (node, icon) => {
	return (
		<li data-type={icon}>
			<section>
				{icon === ListItemTypes.ICON && (
					<ISPIcon className="list-icon" size={IconSize.LARGE} type={IconType.CHECK_SMALL} variant={IconVariant.BLUE} />
				)}
				<p>{domToReact(node.children, { replace: transform })}</p>
			</section>
		</li>
	);
};
export const renderIcon = (node, variant, size) => {
	const iconType = get(node.children, '[0].data');
	return <ISPIcon size={size} type={IconType[iconType] as IconType} variant={variant} />;
};

export const renderParagraph = node => {
	return <p style={node.style}>{domToReact(node.children, { replace: transform })}</p>;
};

export const renderLinkItem = node => {
	const href = get(node, 'attribs.href') || '';
	const target = get(node, 'attribs.target') || '';

	if (
		href.includes(TransformAttributes.MARKET_URL_TEMPLATE) &&
		!node.parent.attribs[TransformAttributes.MARKET_URL_TEMPLATE_ID]
	) {
		return <InternalAnchor node={node} />;
	}

	if (
		node.parent.type === 'tag' &&
		(get(node.parent, 'attribs.class') || '').includes(TransformAttributes.ATTACHMENT_LINK_ID)
	) {
		return (
			<LinkWithIcon
				className="DaimlerCS-Demi fs-14"
				iconSize={IconSize.TINY}
				iconType={IconType.DOWNLOAD2}
				iconPosition={IconPosition.LEFT}
				variant={LinkVariant.SECONDARY}
				destination={href}
				target={target}
			>
				{domToReact(node.children, { replace: transform })}
			</LinkWithIcon>
		);
	}

	if (
		node.parent.type === 'tag' &&
		(get(node.parent, 'attribs.class') || '').includes(TransformAttributes.DAIMLER_LINK_BLUE_ID)
	) {
		return (
			<ISPLink inline variant={LinkVariant.QUINARY} destination={href} target={target}>
				{domToReact(node.children, { replace: transform })}
			</ISPLink>
		);
	}

	if (
		node.parent.type === 'tag' &&
		get(node.children[0], 'name') === 'span' &&
		(get(node.children[0], 'attribs.class') || '').includes(TransformAttributes.DAIMLER_LINK_BLUE_ID)
	) {
		return (
			<ISPLink inline variant={LinkVariant.QUINARY} destination={href} target={target}>
				{domToReact(node.children, { replace: transform })}
			</ISPLink>
		);
	}

	if (!href.match(Patterns.INTERNAL_URL)) {
		const routeUrl = `${href}`;
		return `<a href="${routeUrl}" target="${target}">${domToReact(node.children, { replace: transform })}</a>`;
	}
	return node;
};

export const renderTable = (node, collapsible) => {
	return (
		<CMSTable
			style={get(node, 'attribs.style', '')}
			id={get(node, 'attribs.id', null)}
			collapsible={collapsible}
			otherAttribs={get(node, 'attribs', [])}
		>
			{domToReact(node.children, { replace: transform })}
		</CMSTable>
	);
};

export const handleTableHighlight = node => {
	const nodeAttribs = get(node, 'attribs', []);
	const tableHeaderRow = get(node, 'children', []).filter(element => element.type === 'tag');
	const tableHeaderCells = get(tableHeaderRow, '[0].children', []).filter(element => element.type === 'tag');

	const columnsToHighlight = [];
	tableHeaderCells.forEach((element, index) => {
		const hex = get(element.attribs, TransformAttributes.DATA_TABLE_HIGHLIGHT, null);
		if (hex) {
			columnsToHighlight.push({ index, hex });
		}
	});
	return (
		<tbody {...nodeAttribs}>
			<colgroup>
				{[...Array(tableHeaderCells.length)].map((_, index) => {
					const hex = get(
						find(columnsToHighlight, item => item?.index === index),
						'hex'
					);
					return <col key={index} style={{ backgroundColor: hex || 'unset' }} />;
				})}
			</colgroup>
			{domToReact(node.children, { replace: transform })}
		</tbody>
	);
};

export const renderIconTable = node => {
	return <CMSTableWithIcons>{domToReact(node.children, { replace: transform })}</CMSTableWithIcons>;
};

export const InternalAnchor = ({ node }) => {
	const href = get(node, 'attribs.href', '');
	const target = get(node, 'attribs.target', '');

	return (
		<span data-internal-url-template>
			<ISPLink inline variant={LinkVariant.QUINARY} destination={href} target={target}>
				{domToReact(node.children, { replace: transform })}
			</ISPLink>
		</span>
	);
};

import storage from '@Storage';

const DEFAULT_TIMEOUT = 2000;

function checkDomain() {
	if (window.location.host.split('.').length > 2) {
		return `${window.location.host.replace(window.location.host.split('.')[0], '')}`;
	}
	return `.${window.location.host}`;
}

export default function DeleteMarketingCookies() {
	setTimeout(() => {
		const cookies = document.cookie.split(';');
		cookies.map(cookie => {
			if (cookie.trim().startsWith('_')) {
				storage.deleteMarketingCookie(`${cookie.substring(0, cookie.indexOf('='))}`, '0', checkDomain());
			}
			return cookie;
		});
	}, DEFAULT_TIMEOUT);
}

import { SeverityLevel } from '@microsoft/applicationinsights-web';

export { SeverityLevel };

export enum EventPurpose {
	APPLICATION_INSIGHTS = 'APPLICATION_INSIGHTS',
	GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
}

export enum EventName {
	BUTTON_CLICK = 'BUTTON_CLICK',
}

export enum EventLocation {
	DSB_SUBMIT = 'DSB_SUBMIT',
	VEHICLE_SAVE = 'VEHICLE_SAVE',
}

export enum LogReason {
	FAILED_TO_FETCH_CONTENT = 'FAILED_TO_FETCH_CONTENT',
	FAILED_TO_FETCH_CONFIGURATION = 'FAILED_TO_FETCH_CONFIGURATION',
	API_JWT_NOT_FOUND = 'API_JWT_NOT_FOUND',
	SAVE_ERROR = 'SAVE_ERROR',
	DELETE_ERROR = 'DELETE_ERROR',
}

export enum LogRelation {
	FAQ_MAIL = 'FAQ_MAIL',
	FAQ_PAGE = 'FAQ_PAGE',
	MARKET_CONFIG = 'MARKET_CONFIG',
	EXTERNAL_URLS = 'EXTERNAL_URLS',
	API_JWT = 'NO_API_JWT',
	VEHICLE = 'EDIT_VEHICLE',
	DEALER = 'DEALER',
	DSB = 'DSB',
	NEWSLETTER = 'NEWSLETTER',
	ECOMMERCE_QR_CODE = 'ECOMMERCE_QR_CODE',
}

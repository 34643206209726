import { Markets } from '@Constants/market';

export const CMS_API_KEY = '2a50b47b75aa44e78b59e864e6b8e2b1';
export const PAGE_REQUEST_TIMEOUT = 20000;

export const DEFAULT_MARKET = 'DE';
export const DEFAULT_LANGUAGE = 'en';

export const ISO_MAPPING = {
	'cs-CZ': 'cs',
	'da-DK': 'da',
	'el-GR': 'el',
	'sv-SE': 'sv',
	'zh-CN': 'zh',
	'ko-KR': 'ko',
	'ja-JP': 'ja', // although it is jp according to ISO we use ja
	'en-JP': 'en-JA', // language ISO code is en-JA in Umbraco
};

export enum TransformAttributes {
	COLLAPSIBLE_PAGE_CONTENT_ID = 'collapsible-page-content',
	TABLE_WITH_ICONS_ID = 'table-with-icons',
	FILTERABLE_TABLE_ID = 'filterable-table',
	FREE_CONTENT_FILTERABLE_TABLE_ID = 'free-content-filterable-table',
	ATTACHMENT_LINK_ID = 'attachment-link',
	DAIMLER_LINK_BLUE_ID = 'daimler-link-blue',
	DATA_TABLE_HIGHLIGHT = 'data-table-highlight',
	DATA_COLLAPSIBLE_ID = 'data-collapsible-id',
	LIST_STYLE_DISC = 'list-style-type: disc',
	FILTERABLE_TABLE_TEMPLATE = '{{FILTERABLE_TABLE}}',
	FREE_CONTENT_FILTERABLE_TABLE_TEMAPLTE = '{{FREE_CONTENT_FILTERABLE_TABLE}}',
	MARKET_URL_TEMPLATE = '[market]',
	MARKET_URL_TEMPLATE_ID = 'data-internal-url-template',
	INITIALLY_COLLAPSED = 'initially-collapsed',
}

export enum ListItemTypes {
	DISC = 'DISC',
	ICON = 'ICON',
}

export const MarketIdentifierAliases = {
	[Markets.UNITED_KINGDOM]: [Markets.UNITED_KINGDOM_UK],
};

import FallbackCarImage from '@Static/images/fallback_car_image.png';
import FallbackVanImage from '@Static/images/fallback_van_image.png';
import { Markets } from '@Constants/market';
import { FinalVehicleType } from '@Constants/product';

export const PART_DETAILS_PARTS_COUNT = 5;
export const DEFAULT_PAGE_SIZE = 10;
export const RECT_OFFSET = 150;
export const RECT_OFFSET_UP = 290;
export const INNER_WINDOW_HEIGHT = 784;
export const RECT_OFFSET_SESQUI = 235;
export const PART_CATEGORY_DROPDOWN_HEIGHT = 200;
export const WEBPARTS_MAX_CAMPAING_TEXT_LENGTH = 750;
export const INITIAL_SELECTED_CATEGORY_ID = '-1';

export const DEFAULT_VEHICLE_LIST_PAGE_INDEX = 1;
export const DEFAULT_VEHICLE_LIST_PAGE_SIZE = 10;
export const LOAD_MORE_VEHICLE_COUNT = 5;
export const NEXT_SERVICE_DELAYED_MIN = -91;
export const WEBPARTS_ACTIVE_POSITON_COLOR = 'rgba(0, 171, 239, 0.5)';
export const LICENSE_PLATE_NOT_ALLOWED_COUNTRIES = [Markets.HONG_KONG, Markets.CHINA];
export const LICENSE_PLATE_VIN_MAPPING_COUNTRIES = [Markets.UNITED_KINGDOM, Markets.FRANCE, Markets.NETHERLANDS];
export const MARKETS_WITH_DEFAULT_MILEAGE_MI = [Markets.UNITED_KINGDOM];

export enum VehicleIdMapping {
	PC = 'G, P',
	VAN = 'T',
	SMART = 'F',
}

export enum VEHICLE_FIELD_LIMITS {
	NAME = 100,
	PLATE_NUMBER = 30,
}

export enum ZOOM_SLIDER {
	MIN_VALUE = 1,
	MAX_VALUE = 8,
	STEP_SIZE = 0.5,
}

export enum LogisticInfoOrderStatus {
	ORDERED,
	PROCESSED,
}

export enum VehicleBrands {
	PASSENGER_CAR = 'PASSENGER_CAR',
	COMMERCIAL_VEHICLE = 'COMMERCIAL_VEHICLE',
	SMART = 'SMART',
}

export enum LinasQueryType {
	PART = 'part_number',
	TEXT = 'text',
}

export enum LinasSearchButtonKeys {
	ALL = 1,
	WIS,
	XOT,
	PARTS,
}

export enum WebpartsCatalogueType {
	BY_VIN,
	BY_MODEL,
}

export enum SortingButtonGroupType {
	IMPORTANCE = 1,
	ALPHABETICAL,
	LAST_OPENED,
	UPCOMING_SERVICE,
	RECENT_ORDERS,
}

export enum AddVehicleResponseCode {
	SUCCESS = 'SUCCESS',
	CONFLICT = 'CONFLICT',
	NOT_FOUND = 'NOT_FOUND',
}

export enum VehicleListFilterType {
	RECENTLY_USED = 1,
	UPCOMING_SERVICE,
	RECENT_ORDERS,
	KDM,
	CALLBACK,
	RELEVANCE,
	TO_BE_DELETED,
}

export const DEFAULT_VEHICLE_LIST_FILTER_TYPE = VehicleListFilterType.RELEVANCE;

export const FALLBACK_VEHICLE_IMAGE = {
	[FinalVehicleType.PKW]: FallbackCarImage,
	[FinalVehicleType.VAN]: FallbackVanImage,
};

export enum VehicleDetailsTabs {
	GENERAL = 'general',
	TECHNICAL = 'technical',
	FILLINGS = 'fillings',
}

export enum VehicleMoreInfoType {
	NOT_SET = 'NOT_SET',
	COLOR = 'COLOR',
	ENGINE = 'ENGINE',
	SA_CODES = 'SA_CODES',
	COOLANT = 'COOLANT',
	ENGINE_OIL = 'ENGINE_OIL',
	BRAKE_FLUID = 'BRAKE_FLUID',
	FO_TEXTS = 'FO_TEXTS',
	REFRIGERANT = 'REFRIGERANT',
	REFRIGERATOR_OIL = 'REFRIGERATOR_OIL',
	GEAR_OIL = 'GEAR_OIL',
	FRONT_AXLE_GEAR_OIL = 'FRONT_AXLE_GEAR_OIL',
	REAR_AXLE_GEAR_OIL = 'REAR_AXLE_GEAR_OIL',
	TRANSFERCASE_GEAR_OIL = 'TRANSFERCASE_GEAR_OIL',
}

export enum SearchTermType {
	NOT_SET = 'NOT_SET',
	PART_NUMBER = 'PART_NUMBER',
	VIN_NUMBER = 'VIN_NUMBER',
}

export enum VEHICLE_REGISTRATION_DATE_VALIDATION_STATUS {
	REG_DATE_TOO_OLD = 'REG_DATE_TOO_OLD',
	REG_DATE_TOO_EARLY = 'REG_DATE_TOO_EARLY',
	REGISTRATION_DATE_ALREADY_EXIST = 'REGISTRATION_DATE_ALREADY_EXIST',
}

export enum AggregateTypes {
	AUTOMATIC_TRANSMISSION = 'AUTOMATIC_TRANSMISSION',
	BODY = 'BODY',
	ELECTRIC_MOTOR = 'ELECTRIC_MOTOR',
	ENGINE = 'ENGINE',
	EXHAUST_BOX = 'EXHAUST_BOX',
	FRONT_AXLE = 'FRONT_AXLE',
	FUEL_CELL = 'FUEL_CELL',
	FUELCELL = 'FUEL_CELL',
	HIGH_VOLTAGE_BATTERY = 'HIGH_VOLTAGE_BATTERY',
	MANUAL_TRANSMISSION = 'MANUAL_TRANSMISSION',
	PLATFORM = 'PLATFORM',
	REAR_AXLE = 'REAR_AXLE',
	STEERING = 'STEERING',
	TRANSFER_CASE = 'TRANSFER_CASE',
	TRANSMISSION = 'TRANSMISSION',
	VEHICLE = 'VEHICLE',
}

export enum FinBasedPartSearchResponseType {
	NONE,
	WEBPARTS,
	IPARTS,
}

export interface AggregateOption {
	modelDesc: string;
	modelId: string;
	modelRemarks: string;
	productId: string;
	validCountries: string[];
	label: string;
	id?: string;
	preSelected: boolean;
}

export interface AggregateSelection {
	aggregateType: AggregateTypes;
	modelId: string;
	productId: string;
	label: string;
}

export interface CatalogAggregate {
	key: string;
	id?: string;
	label?: string;
	modelDesc?: string;
	modelId?: string;
	modelRemarks?: string;
	productId?: string;
	options: AggregateOption[];
}

export interface VehicleCatalog {
	modelDesc: string;
	modelId: string;
	modelRemarks: string;
	productId: string;
	productRemarks: string;
	validCountries: string[];
	label: string;
	dataCardExists: boolean;
	aggregates: CatalogAggregate[];
	isAllAggregateSelected: boolean;
	preselected: boolean;
	id?: string;
	remarkLabel?: string;
	validCountriesLabel?: string;
}

export interface VehicleCatalogFilterOptions {
	model: boolean;
	datacard: boolean;
	saVersion: boolean;
	steering: boolean;
	serial: boolean;
	transmission: boolean;
	color: boolean;
	codes: boolean;
	spring: boolean;
}

/* Digital Service Booklet */

export enum DSBServiceType { // ServiceExtend is related field to decide.
	NOT_FOUND = '0',
	SERVICE_A = '1',
	SERVICE_B = '2',
	SERVICE_INTERIM = '3',
}

export const SERVICE_EXTEND_VALUES = [
	DSBServiceType.SERVICE_A,
	DSBServiceType.SERVICE_B,
	DSBServiceType.SERVICE_INTERIM,
];

export enum OilDetailType {
	ENGINE_OIL = 1,
	MANUAL_TRANSMISSION,
	AUTOMATIC_TRANSMISSION,
	TRANSFER_CASE,
	FRONT_AXLE,
	REAR_AXLE,
	STEERING,
	DUMPER_SYSTEM,
	FLUID_COUPLING,
}

export const OIL_DETAIL_TYPE_TRANSLATION = {
	[OilDetailType.ENGINE_OIL]: 'engine-oil',
	[OilDetailType.MANUAL_TRANSMISSION]: 'manual-transmission',
	[OilDetailType.AUTOMATIC_TRANSMISSION]: 'automatic-transmission',
	[OilDetailType.TRANSFER_CASE]: 'transfer-case',
	[OilDetailType.FRONT_AXLE]: 'front-axle',
	[OilDetailType.REAR_AXLE]: 'rear-axle',
	[OilDetailType.STEERING]: 'steering',
	[OilDetailType.DUMPER_SYSTEM]: 'dumper-system',
	[OilDetailType.FLUID_COUPLING]: 'fluid-coupling',
};

export const SERVICE_TYPE_TRANSLATION = {
	[DSBServiceType.SERVICE_A]: 'dsb-service-a',
	[DSBServiceType.SERVICE_B]: 'dsb-service-b',
	[DSBServiceType.SERVICE_INTERIM]: 'dsb-service-interim',
};

export enum DSBMileageType {
	KM,
	MILES,
	H,
	MI,
}

export const MILEAGE_INDICATOR_TRANSLATION = {
	[DSBMileageType.KM]: 'kilometer-abbrevation',
	[DSBMileageType.MILES]: 'miles',
	[DSBMileageType.H]: 'hours-abbrevation',
	[DSBMileageType.MI]: 'miles-abbrevation',
};

export enum DSBResponseStatus {
	VALID,
	GENERAL_EXCEPTION,
	CUSTOM_EXCEPTION,
	CONSTRAINT_EXCEPTION,
	NO_PERMISSION_FOR_REG_DATE,
	SERVICE_DOCUMENTATION_ERROR,
	DSB_NOT_SUPPORTED,
}

export enum DSBServiceKind {
	FIRST_REG_SERVICE,
	REGULAR_SERVICE,
	BODYWORK_SERVICE,
	AGGREGATE_SERVICE,
}
export const COMPLETED_SERVICE_COUNT_ON_LOAD = 3;
export const COMPLETED_SERVICE_CALL_COUNT = 4;
export const YEAR_COUNT_ON_FILTER = 30;
export const REMAINING_DAYS_LIMIT_TODAY = 0;
export const REMAINING_DAYS_LIMIT_ONE_DAY = 1;
export const REMAINING_DAYS_LIMIT_DELAYED_ONE_DAY = -1;
export const REMAINING_DAYS_LIMIT_DELAYED = -91;

export const JOB_CARD_NUMBER_LENGTH = 9;

export const FIRST_DOT_INDEX = 2;
export const SECOND_DOT_INDEX = 5;
export const DATE_MAXIMUM_CHARACTER = 10;

export const TEXT_AREA_MAX_CHARACTERS = 197;
export const TEXT_AREA_HEIGHT = 77;

export const VALIDATION_TRIGGER_INITIAL_VALUE = 0;
export const DSB_SELECTABLE_DAY_LIMIT = 9999;
export const DSB_ADDRESS_LOADING_DELAY = 1000;
export const DSB_SAVED_NOTIFICATION_TIMEOUT = 3000;
export const DSB_SAVED_SCROLL_DELAY = 500;
export const DSB_OPTIONS_SHOW_DAYS_LEFT_LIMIT = 9;
export const DSB_EDIT_BUTTON_ENABLE_DELAY = 100;
export const WORKSHOP_CODE_MIN_LENGTH = 3;
export const WORKSHOP_CODE_MAX_LENGTH = 9;

export const FREE_TEXT_SEARCH_TOTAL_RESULT_COUNT = 50;
export const MAX_FREE_TEXT_SEARCH_RESULTS = 5;

export const ES2_KEYS_FROM_FOOTNOTES = 3;

export const VEHICLE_ORDERS_SCROLL_DELAY = 1000;

export enum DSBVersions {
	V1 = '1',
	V2 = '2',
}

export enum WebPartsVersions {
	V1 = '1',
	V2 = '2',
}

export enum ServiceDocumentationModalType {
	NEW_ENTRY = 'NEW_ENTRY',
	EDIT = 'EDIT',
}

export const MAX_CHAR_NUM_VEHICLE_INFORMATION_FIELDS = 8;
export const MILEAGE_UNTIL_NEXT_SERVICE_KM_INDICATOR = 10000;
export const MILEAGE_UNTIL_NEXT_SERVICE_MILE_INDICATOR = 6000;
export const SERVICE_PERIOD_INTERVAL_PKW = 365;
export const SERVICE_PERIOD_INTERVAL_VAN = 730;
export const MI_TO_KM_CONVERSION_FACTOR = 1.60934;
export const KM_TO_MI_CONVERSION_FACTOR = 0.621371;

export interface Address {
	name: string;
	street: string;
	postalCode: string;
	town: string;
	country: string;
	isSelected?: boolean;
}

export enum DSBDocumentationInput {
	MILEAGE = 'mileage',
	REMAINING_DISTANCE = 'remainingDistance',
	REMAINING_TIME = 'remainingTime',
	NEXT_SERVICE_AT = 'nextServiceAt',
	MILEAGE_UNTIL_NEXT_SERVICE = 'mileageUntilNextService',
	NEXT_SERVICE_ON = 'nextServiceOn',
	SERVICE_DATE = 'serviceDate',
	JOB_CARD_NUMBER = 'jobCardNumber',
	COMPANY_ADDRESS = 'companyAddress',
	PREFERRED_DISTANCE_UNIT = 'preferredDistanceUnit',
	OIL_QUALITY = 'oilQuality',
}

export enum DSBDocumentationInputError {
	REMAINING_DISTANCE = 'remainingDistanceErrors',
	REMAINING_TIME = 'remainingTimeErrors',
	NEXT_SERVICE_AT = 'nextServiceAtErrors',
	MILEAGE_UNTIL_NEXT_SERVICE = 'mileageUntilNextServiceErrors',
	NEXT_SERVICE_ON = 'nextServiceOnErrors',
}

export enum DSBApiStatus {
	DSB_RESPONSE_EMPTY = -1,
	DSB_NOT_SUPPORTED,
	INVALID_WORKSHOP_CODE,
	CUSTOM_WORKSHOP_CODE,
	MILEAGE_OR_SERVICE_DATE_NOT_IN_RANGE,
	MILEAGE_OR_SERVICE_DATE_NOT_IN_RANGE_2,
	SERVICE_DATE_INVALID,
	MILEAGE_EXCEEDS_UPPER_LIMIT,
	SERVICE_DATE_TOO_OLD,
	SERVICE_DATE_TOO_EARLY,
	SERVICE_DATE_ALREADY_EXIST,
	INDICATOR_DOES_NOT_EXIST,
	WORKSHOP_CODE_INVALID,
	SERVICE_TYPE_INVALID,
	START_MILEAGE_TOO_EARLY,
	REMAINING_DAYS_NOT_IN_RANGE,
	REMAINING_MILEAGE_NOT_IN_RANGE,
	MISSING_MANDATORY,
	MISSING_MANDATORY_2,
	SUCCESSFUL_SECURITY_CODE,
	MILEAGE_TOO_EARLY,
	INVALID_OPERATION_CODE,
	UNKNOWN_VEHICLE,
	SERVICE_SCOPE_DATA_UNAVAILABLE,
	GENERIC_COMPARISON_ERROR,
	DEALER_CODE_INVALID,
	DELIVERY_CHECK_SHOULD_BE_EXECUTED,
	NOT_VALID_ENTRY,
}

export const DSB_API_TRANSLATION = {
	[DSBApiStatus.DSB_NOT_SUPPORTED]: 'vehicles:dsb-not-supported',
	[DSBApiStatus.INVALID_WORKSHOP_CODE]: 'vehicles:dsb-invalid-service-code',
	[DSBApiStatus.CUSTOM_WORKSHOP_CODE]: 'vehicles:dsb-custom-workshop-code',
	[DSBApiStatus.MILEAGE_OR_SERVICE_DATE_NOT_IN_RANGE]: 'vehicles:dsb-mileage-or-service-date-not-in-range',
	[DSBApiStatus.MILEAGE_OR_SERVICE_DATE_NOT_IN_RANGE_2]: 'vehicles:dsb-mileage-or-service-date-not-in-range-2',
	[DSBApiStatus.SERVICE_DATE_INVALID]: 'vehicles:dsb-service-date-invalid',
	[DSBApiStatus.MILEAGE_EXCEEDS_UPPER_LIMIT]: 'vehicles:dsb-mileage-exceeds-upper-limit',
	[DSBApiStatus.SERVICE_DATE_TOO_OLD]: 'vehicles:dsb-service-date-too-old',
	[DSBApiStatus.SERVICE_DATE_TOO_EARLY]: 'vehicles:dsb-service-date-too-early',
	[DSBApiStatus.SERVICE_DATE_ALREADY_EXIST]: 'vehicles:dsb-service-date-already-exist',
	[DSBApiStatus.INDICATOR_DOES_NOT_EXIST]: 'vehicles:dsb-indicator-does-not-exist',
	[DSBApiStatus.WORKSHOP_CODE_INVALID]: 'vehicles:dsb-wrong-workshop-code',
	[DSBApiStatus.SERVICE_TYPE_INVALID]: 'vehicles:dsb-service-type-invalid',
	[DSBApiStatus.START_MILEAGE_TOO_EARLY]: 'vehicles:dsb-start-mileage-too-early',
	[DSBApiStatus.REMAINING_DAYS_NOT_IN_RANGE]: 'vehicles:dsb-remaining-days-not-in-range',
	[DSBApiStatus.REMAINING_MILEAGE_NOT_IN_RANGE]: 'vehicles:dsb-remaining-mileage-not-in-range',
	[DSBApiStatus.MISSING_MANDATORY]: 'vehicles:dsb-missing-mandatory',
	[DSBApiStatus.MISSING_MANDATORY_2]: 'vehicles:dsb-mandatory-field-entry-required',
	[DSBApiStatus.SUCCESSFUL_SECURITY_CODE]: 'vehicles:dsb-successful-security-code',
	[DSBApiStatus.MILEAGE_TOO_EARLY]: 'vehicles:dsb-mileage-too-early',
	[DSBApiStatus.INVALID_OPERATION_CODE]: 'vehicles:dsb-invalid-operation-code',
	[DSBApiStatus.UNKNOWN_VEHICLE]: 'vehicles:dsb-unknown-vehicle',
	[DSBApiStatus.SERVICE_SCOPE_DATA_UNAVAILABLE]: 'vehicles:dsb-service-scope-data-unavailable',
	[DSBApiStatus.GENERIC_COMPARISON_ERROR]: 'vehicles:dsb-generic-comparison-error',
	[DSBApiStatus.DEALER_CODE_INVALID]: 'vehicles:dsb-dealer-code-invalid',
	[DSBApiStatus.DELIVERY_CHECK_SHOULD_BE_EXECUTED]: 'vehicles:dsb-delivery-check-should-be-executed',
	[DSBApiStatus.NOT_VALID_ENTRY]: 'vehicles:dsb-not-valid-entry',
};

export const VEHICLE_LICENSE_TYPE_MAPPING = {
	[FinalVehicleType.PC]: FinalVehicleType.PC,
	[FinalVehicleType.PKW]: FinalVehicleType.PC,
	[FinalVehicleType.VAN]: FinalVehicleType.VAN,
	[FinalVehicleType.SMART]: FinalVehicleType.SMART,
};
export interface AddWork {
	id?: AddWorkId;
	indSelected: boolean;
	description: string;
	addWorks: string[];
	addWorkType: AddWorkType;
}

export enum AddWorkType {
	UNFILTERED = 0,
	FILTERED = 1,
	ENGINE_OIL_FILTER = 2,
}

export interface AddWorkId {
	addWorkId: string;
	technicalKey: string;
}

export const DEFAULT_SERVICE_CHECKBOX_TYPES = [AddWorkType.ENGINE_OIL_FILTER, AddWorkType.FILTERED];

export enum SubmitType {
	SUBMIT,
	SUBMIT_EXPORT,
	DEFAULT,
}

export enum CHAPTER_TYPES {
	WORKSHOP_INFORMATION,
	VEHICLE_INFORMATION,
	SERVICE_INFORMATION,
}

export enum PdfExportTypes {
	KDM_EXPORT_PDF = 'KDM_EXPORT_PDF',
}
export interface VehicleInfo {
	createdDate: string;
	customerName: string;
	imageUrl: string;
	modelName: string;
	note: string;
	plateNumber: string;
	type: FinalVehicleType;
	subType: FinalVehicleType;
	isOldVehicle: boolean;
	aggregateSelections: AggregateSelection[];
}

export interface EngineDetails {
	isFuelEngine: boolean;
	number: string;
	partNumber: string;
}

export interface FOCode {
	group: string;
	description: string;
}

export interface VehicleDetails {
	calibrationId: string;
	cvnNumber: string;
	engines: EngineDetails[];
	euTypeApprovalNumber: string;
	foCodes: FOCode[];
	initialRegistration: string;
	fillingsData: ParsedVehicleFillings;
	paints: {
		code: string;
		name: string;
	}[];
	saCodes: {
		code: string;
		description: string;
	}[];
	steering: {
		id: string;
		informativeNote: string;
		isLeftHand: boolean;
	};
	transmission: {
		number: string;
		partNumber: string;
	};
	vehicleInformation: VehicleInfo;
	vinNumber: string;
}

export const INITIAL_VEHICLE_DETAILS_DATA: VehicleDetails = {
	calibrationId: '',
	cvnNumber: '',
	engines: [],
	euTypeApprovalNumber: '',
	foCodes: [],
	initialRegistration: '',
	fillingsData: {
		engineOilData: [],
		breakFluidData: [],
		coolantData: [],
		refrigerantData: [],
		refrigeratorOilData: [],
		gearOilData: [],
		frontAxleGearOilData: [],
		rearAxleGearOilData: [],
		transferGearOilData: [],
	},
	paints: [],
	saCodes: [],
	steering: {
		id: '',
		informativeNote: '',
		isLeftHand: true,
	},
	transmission: {
		number: '',
		partNumber: '',
	},
	vehicleInformation: {
		createdDate: '',
		customerName: '',
		imageUrl: '',
		modelName: '',
		note: '',
		plateNumber: '',
		type: FinalVehicleType.PKW,
		subType: FinalVehicleType.SMART,
		isOldVehicle: false,
		aggregateSelections: [],
	},
	vinNumber: '',
};

export interface VehicleMbSpecList {
	type: VehicleFillingDataType;
	fillings: VehicleFillingData[];
}
export interface VehicleFillingData {
	spec: string;
	saeClass: string;
	text: string;
	quantity: string;
}

export enum VehicleFillingDataType {
	UNDEFINED = -1,
	ENGINE_OIL,
	BRAKE_FLUID,
	COOLANT,
	REFRIGERANT = 8,
	REFRIGERATOR_OIL,
	GEAR_OIL,
	FRONT_AXLE_GEAR_OIL,
	REAR_AXLE_GEAR_OIL,
	TRANSFERCASE_GEAR_OIL,
}

export interface ParsedVehicleFillings {
	engineOilData: VehicleFillingData[];
	breakFluidData: VehicleFillingData[];
	coolantData: VehicleFillingData[];
	refrigerantData: VehicleFillingData[];
	refrigeratorOilData: VehicleFillingData[];
	gearOilData: VehicleFillingData[];
	frontAxleGearOilData: VehicleFillingData[];
	rearAxleGearOilData: VehicleFillingData[];
	transferGearOilData: VehicleFillingData[];
}

export const FILLING_TYPE_TAG_MAPPING = {
	[VehicleFillingDataType.ENGINE_OIL]: 'engine-oil',
	[VehicleFillingDataType.BRAKE_FLUID]: 'brake-fluids',
	[VehicleFillingDataType.COOLANT]: 'coolant',
	[VehicleFillingDataType.REFRIGERANT]: 'refrigerant',
	[VehicleFillingDataType.REFRIGERATOR_OIL]: 'refrigerator-oil',
	[VehicleFillingDataType.GEAR_OIL]: 'gear-oil',
	[VehicleFillingDataType.FRONT_AXLE_GEAR_OIL]: 'front-axle-gear-oil',
	[VehicleFillingDataType.REAR_AXLE_GEAR_OIL]: 'rear-axle-gear-oil',
	[VehicleFillingDataType.TRANSFERCASE_GEAR_OIL]: 'transfercase-gear-oil',
};

export const MORE_INFO_TYPE_FILLING_MAPPING = {
	[VehicleFillingDataType.ENGINE_OIL]: VehicleMoreInfoType.ENGINE_OIL,
	[VehicleFillingDataType.BRAKE_FLUID]: VehicleMoreInfoType.BRAKE_FLUID,
	[VehicleFillingDataType.COOLANT]: VehicleMoreInfoType.COOLANT,
	[VehicleFillingDataType.REFRIGERANT]: VehicleMoreInfoType.REFRIGERANT,
	[VehicleFillingDataType.REFRIGERATOR_OIL]: VehicleMoreInfoType.REFRIGERATOR_OIL,
	[VehicleFillingDataType.GEAR_OIL]: VehicleMoreInfoType.GEAR_OIL,
	[VehicleFillingDataType.FRONT_AXLE_GEAR_OIL]: VehicleMoreInfoType.FRONT_AXLE_GEAR_OIL,
	[VehicleFillingDataType.REAR_AXLE_GEAR_OIL]: VehicleMoreInfoType.REAR_AXLE_GEAR_OIL,
	[VehicleFillingDataType.TRANSFERCASE_GEAR_OIL]: VehicleMoreInfoType.TRANSFERCASE_GEAR_OIL,
};

export const MORE_INFO_TRANSLATION_KEY_MAPPING = {
	[VehicleMoreInfoType.ENGINE_OIL]: 'vehicles:engine-oil',
	[VehicleMoreInfoType.BRAKE_FLUID]: 'vehicles:brake-fluids',
	[VehicleMoreInfoType.COOLANT]: 'vehicles:coolant',
	[VehicleMoreInfoType.REFRIGERANT]: 'vehicles:refrigerant',
	[VehicleMoreInfoType.REFRIGERATOR_OIL]: 'vehicles:refrigerator-oil',
	[VehicleMoreInfoType.GEAR_OIL]: 'vehicles:gear-oil',
	[VehicleMoreInfoType.FRONT_AXLE_GEAR_OIL]: 'vehicles:front-axle-gear-oil',
	[VehicleMoreInfoType.REAR_AXLE_GEAR_OIL]: 'vehicles:rear-axle-gear-oil',
	[VehicleMoreInfoType.TRANSFERCASE_GEAR_OIL]: 'vehicles:transfercase-gear-oil',
	[VehicleMoreInfoType.SA_CODES]: 'vehicles:sa-codes',
	[VehicleMoreInfoType.ENGINE]: 'vehicles:engine',
	[VehicleMoreInfoType.FO_TEXTS]: 'vehicles:fo-text-groups',
	[VehicleMoreInfoType.COLOR]: 'vehicles:color',
};

export enum VehicleSection {
	OVERVIEW = 'OVERVIEW',
	PARTS = 'PARTS',
}

export enum FootNoteType {
	LEGACY_COLOR = 'legacyColor',
	TEXT = 'text',
}

export enum PlantInfoTextType {
	FROM = 'from',
	UPTO = 'Upto',
}

export interface OilQuality {
	value: string;
	PKW: boolean;
	VAN: boolean;
	details: {
		id: {
			detailId: number;
		};
		oilQuality: string;
		viscosity: string;
		description: string;
	}[];
}

export enum AddVehicleVINErrorMessages {
	API_EXCEPTION = 'common:something-went-wrong',
	CHECK_FORMAT = 'vehicles:check-vin-format',
	ALREADY_SAVED = 'vehicles:vehicle-already-saved',
	INVALID = 'vehicles:invalid-vin',
	UNSUPPORTED = 'vehicles:unsupported-vehicle-trucks-error',
	DEFAULT = '',
}

export const toEngine = data => ({
	isFuelEngine: data?.isFuelEngine || false,
	number: data?.number || '',
	partNumber: data?.partNumber || '',
});

export const toPaint = data => ({
	code: data?.code || '',
	name: data?.name || '',
});

export const toSaCode = data => ({
	code: data?.code || '',
	description: data?.description || data?.designation || '',
});

export const toSteering = data => ({
	id: data?.id || '',
	informativeNote: data?.informativeNote || '',
	isLeftHand: data?.isLeftHand ?? true,
});

export const toTransmission = data => ({
	number: data?.number || '',
	partNumber: data?.partNumber || '',
});

export const toVehicleInformation = data => ({
	createdDate: data?.createDate || '',
	customerName: data?.customerName || '',
	imageUrl: data?.imageUrl || '',
	modelName: data?.modelName || '',
	note: data?.note || '',
	plateNumber: data?.plateNumber || '',
	type: data?.type || FinalVehicleType.PKW,
	subType: data?.subType || FinalVehicleType.SMART,
	isOldVehicle: data?.isOldVehicle || false,
	aggregateSelections: data?.aggregateSelections || [],
});

export const toVehicleDetails = details => ({
	calibrationId: details?.calibrationId || '',
	cvnNumber: details?.cvnNumber || '',
	engines: (details?.engines || []).map(item => toEngine(item)),
	euTypeApprovalNumber: details?.euTypeApprovalNumber || '',
	foCodes: details?.foCodes || [],
	initialRegistration: details?.initialRegistration || '',
	paints: (details?.paints || []).map(item => toPaint(item)),
	saCodes: (details?.saCodes || []).map(item => toSaCode(item)),
	steering: toSteering(details?.steering),
	transmission: toTransmission(details?.transmission),
	vehicleInformation: toVehicleInformation(details?.vehicleInformation),
	vinNumber: details?.vinNumber || '',
});

export interface SaCode {
	code: string;
	description: string;
}

export interface Paint {
	code: string;
	name: string;
}

export type TechnicalDataTypes = SaCode[] | EngineDetails[] | FOCode[] | Paint[];

import { Component } from 'react';
import { connect } from 'react-redux';
import every from 'lodash/every';
import { FeatureConfigurationType } from '@Reducers/configuration/models';

interface FeatureProps {
	children?: JSX.Element | JSX.Element[];
	featureConfiguration: FeatureConfigurationType;
	feature: string | string[];
	toggle?: JSX.Element | JSX.Element[];
}

class Feature extends Component<FeatureProps, {}> {
	render() {
		const { feature, featureConfiguration, children, toggle } = this.props;
		const features = typeof feature === 'string' ? [feature] : feature;
		const hasFeature = every(features, function checkFeature(f) {
			return featureConfiguration[f];
		});

		return hasFeature ? children : toggle || '';
	}
}

const mapStateToProps = state => ({
	featureConfiguration: state.configuration.featureConfiguration,
});

export default connect(mapStateToProps)(Feature);

import { IconType, IconVariant } from '@isp/icon';
import { CurrencySymbols } from '@isp/price';
import { SpecialOffer } from '@Apis/orders';
import {
	GET_SEARCH_PARTS_BY_PART_NUMBER,
	GET_VEHICLE_PARTS_BY_VIN_AND_PART_NUMBER,
	GET_VEHICLE_PARTS_BY_VIN_NUMBER,
} from '@Reducers/vehicle/actions';
import { PartAvailability } from '@Constants/shop';
import { VehicleCatalogFilterOptions } from '@Constants/vehicle';
import { SearchHistoryTypes } from '@Constants/common';

export const PART_TEASER_ALIGN_BREAKPOINT = 700;
export const WEBPARTS_BULK_ORDER_TEMPLATE_FILE = 'B2BConnectUpload';
export const EXPLOSION_DRAWING_HREF_SVG_SUBSTRING = 'usage_SVG';
export const BULK_ORDER_UPLOAD_SIZE_LIMIT = 102_400;
export const EXPLOSION_DRAWING_TOOLTIP_DELAY = 500;
export const WHEELS_LINKOUT_AVAILABLE_COUNTRIES = ['fr', 'it', 'nl', 'ro'];
export const ALTERNATE_REMAN_PART_AVAILABLE_MARKETS = [
	'at',
	'be',
	'ca',
	'cz',
	'dk',
	'fr',
	'de',
	'gr',
	'it',
	'lu',
	'nl',
	'pt',
	'pl',
	'es',
	'ch',
	'se',
	'gb',
	'kr',
	'ae',
	'jp',
];
export const ALTERNATE_STAR_PART_AVAILABLE_MARKETS = [
	'gr',
	'pt',
	'it',
	'fr',
	'ro',
	'es',
	'de',
	'nl',
	'be',
	'lu',
	'at',
	'ch',
	'cz',
	'sk',
	'se',
	'dk',
	'pl',
	'ca',
	'za',
	'au',
	'cn',
	'bg',
	'cy',
	'ee',
];
export const OPEN_WEBPARTS_APP_ID = 'ispc';
export const ES2_KEY = 'ES2';
export const PART_LIST_HEADER_ID = 'vehicle-part-list-header';
export const PART_SEARCH_RESULT_CONTAINER_ID = 'part-search-result-container';
export const PART_REFRESH = 'refreshParts';
export const WEBPARTS_CAMPAIGN_ITEM_PER_PAGE = 5;
export const BANNER_TITLE_LENGTH = 50;
export const BANNER_TITLE_LENGTH_WITH_IMAGE = 35;
export const BANNER_DESCRIPTION_LENGTH = 140;
export const BANNER_BUTTON_LENGTH = 40;
export const SERIES_ID_SUBSTRING_LENGTH = 3;
export const PART_SEARCH_RESULT_LIMIT = 5;
export const EXPLOSION_DRAWING_IMAGE_WIDTH = 105;
export const AGGREGATE_NUMBER_MAX_WIDTH = 150;

export enum PartsCatalogueCategoryIds {
	MATERIAL_CATEGORY_ID = '22',
	ALL_CATEGORY_ID = '-1',
	PACKAGE_CATEGORY_ID = '00',
}

export enum WebpartsContexts {
	SPECIAL_DEALS = 'OO',
	SHOPPING_BASKET = 'SB',
	ORDER_HISTORY = 'OH',
	TIRES = 'TI',
	WHEELS = 'WH',
}

export enum WebpartsBasketValidationStatuses {
	UNKNOWN_ERROR = -1,
	SUCCESS,
	MULTIPLE_CURRENCY,
	MULTIPLE_VAT_RATES,
}

export enum CatalogueDataTypes {
	PART,
	PACKAGE,
	MATERIAL = 3,
	ALL,
}

export enum CatalogueGroupTypes {
	MAIN_GROUP,
	SUB_GROUP,
	SA_GROUP,
}

export enum FinBasedPartSearchResponseType {
	NONE,
	WEBPARTS,
	IPARTS,
}

export enum CatalogueFilterOptionType {
	ALL = 'all',
	MODEL = 'model',
	DATACARD = 'datacard',
	SA_VERSION = 'saVersion',
	STEERING = 'steering',
	SERIAL = 'serial',
	TRANSMISSION = 'transmission',
	COLOR = 'color',
	CODES = 'codes',
	SPRING = 'spring',
}

// We can make this like WEBPARTS_SEARCH IPARTS_SEARCH or FULL_SEARCH in the feature
export enum PartSearchType {
	WITH_VIN,
	WITH_VIN_AND_PART,
	WITHOUT_VIN,
}

export const DeliveryIconProps = {
	[PartAvailability.RED_LED_NOT_ALLOWED]: {
		type: IconType.CIRCLE_EMPTY,
		variant: IconVariant.BLOODRED,
	},
	[PartAvailability.GREEN_WITH_CHECKMARK]: {
		type: IconType.CIRCLE_WITH_CHECKMARK,
		variant: IconVariant.STATUSOK,
	},
	[PartAvailability.GREEN]: {
		type: IconType.CIRCLE,
		variant: IconVariant.STATUSOK,
	},
	[PartAvailability.YELLOW]: {
		type: IconType.CIRCLE,
		variant: IconVariant.AMBER,
	},
	[PartAvailability.ORANGE]: {
		type: IconType.CIRCLE,
		variant: IconVariant.ORANGE,
	},
	[PartAvailability.RED]: {
		type: IconType.CIRCLE,
		variant: IconVariant.BLOODRED,
	},
	[PartAvailability.BLACK]: {
		type: IconType.CIRCLE,
		variant: IconVariant.BLACK,
	},
	[PartAvailability.BLUE]: {
		type: IconType.CIRCLE,
		variant: IconVariant.BLUE,
	},
	[PartAvailability.WHITE]: {
		type: IconType.CIRCLE_EMPTY,
		variant: IconVariant.VERY_DARK_GREY,
	},
	[PartAvailability.GRAY_UNKNOWN]: {
		type: IconType.CIRCLE,
		variant: IconVariant.GRAY,
	},
};

export const PART_SEARCH_TYPE_ACTION_MAP = {
	[PartSearchType.WITH_VIN]: GET_VEHICLE_PARTS_BY_VIN_NUMBER,
	[PartSearchType.WITH_VIN_AND_PART]: GET_VEHICLE_PARTS_BY_VIN_AND_PART_NUMBER,
	[PartSearchType.WITHOUT_VIN]: GET_SEARCH_PARTS_BY_PART_NUMBER,
};

export const PART_SEARCH_TYPE_HISTORY_MAP = {
	[PartSearchType.WITH_VIN]: SearchHistoryTypes.PARTS_WITH_VIN,
	[PartSearchType.WITHOUT_VIN]: SearchHistoryTypes.PARTS,
};

export enum PartsBasketType {
	GeneralSearch = 0,
	FINBased = 1,
}

export interface PartDetailUserInfo {
	retailerId: string;
	deliveryAddressId: string;
	companyId: string;
	isActivelyPreffered: boolean;
}

export interface PartDetailPrice {
	currency: CurrencySymbols;
	listPricePerUnit: string;
	netPricePerUnit: string;
	coreValuePerUnit: string;
	vatRate: number;
	specialOffer: SpecialOffer;
	productSuggestion: string;
}

export interface PartDetailAvailability {
	type: PartAvailability;
	hint: string;
}

export interface PartShoppingInfo {
	dealerInfo: PartDetailUserInfo;
	price: PartDetailPrice;
	availability: PartDetailAvailability;
}

export const WEBPARTS_BASKET_UPDATE_DELAY = 800;

export interface SelectedMainGroupOptions {
	modelId: string;
	productId: string;
}

export interface SubGroupOption {
	id: string;
	label: string;
}

export interface WebpartsCampaign {
	id: string;
	title: string;
	name: string;
	subtitle: string;
	text: string;
	teaserImageName: string;
	teaserImageUrl: string;
	titleImageName: string;
	titleImageUrl: string;
	excelDocumentUrl: string;
	flyerDocumentName: string;
	flyerDocumentUrl: string;
	hasParts: boolean;
}

export enum ModelIdentificationFields {
	SERIES = 'series',
	MODEL = 'model',
	PART_NAME = 'partName',
	CATALOG = 'catalog',
	MAINGROUP = 'mainGroup',
	SUBGROUP = 'subGroup',
}

export enum ModelIdentificationView {
	SERIES_SELECTION_VIEW,
	ITEM_SELECTION_VIEW,
}

export interface ModelIdentificationItemType {
	model: string;
	partName: string;
	partNumber: string;
	catalog: string;
	mainGroup: CatalogueGroupType;
	subGroup: CatalogueGroupType;
}

export interface CatalogueGroupType {
	id: string;
	label: string;
}

export enum SteeringType {
	LEFT = 'L',
	RIGHT = 'R',
}

export enum CatalogAggregateTypes {
	AUTOMATIC_TRANSMISSION = 'GA',
	BODY = 'FH',
	ELECTRIC_MOTOR = 'E',
	ENGINE = 'M',
	EXHAUST_BOX = 'AS',
	FRONT_AXLE = 'VA',
	FUELCELL = 'N',
	HIGH_VOLTAGE_BATTERY = 'B',
	MANUAL_TRANSMISSION = 'GM',
	PLATFORM = 'P',
	REAR_AXLE = 'HA',
	STEERING = 'LG',
	TRANSFER_CASE = 'VG',
	TRANSMISSION = 'G',
	VEHICLE = 'F',
}

export const VIRTUOSO = {
	PART_LIST_STYLE: { height: '100%', minHeight: '68vh' },
	EXPLOSION_DRAWING_TOOLTIP_STYLE: { height: '300px', width: '380px' },
	RANGE_START_INDEX: 0,
	RANGE_END_INDEX: 10,
	RANGE_START_INDEX_TOOLTIP: 0,
	RANGE_END_INDEX_TOOLTIP: 2,
};

export const initialCatalogueFilterOptionsState: VehicleCatalogFilterOptions = {
	model: true,
	datacard: true,
	saVersion: true,
	steering: true,
	serial: true,
	transmission: true,
	color: true,
	codes: true,
	spring: true,
};

export const MAIN_GROUP_CONFIGS = { SEARCH_SAVE_DELAY: 500, COLLAPSE_ANIMATON_TIMER: 1000 };

export enum PackageView {
	PACKAGE_LIST,
	PACKAGE_DETAILS,
}

import { actionTypes } from '@Reducers/remanSearch/actionTypes';
import { ModalScreenTypes } from '@Constants/parts';

const initialState = {
	countryInfo: {
		currency: '',
		countryId: 0,
	},
	productCategoryList: [],
	productsWithPartNumberSearched: [],
	productsWithVinSearched: [],
	vehicleType: null,
	searchedEngines: [],
	screenType: ModalScreenTypes.LOADING,
	vinSelectedCategoryTitle: '',
	enginesSectionId: null,
};

export const remanSearchReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_COUNTRY_INFO:
			return {
				...state,
				countryInfo: action.countryInfo,
			};
		case actionTypes.SET_SEARCHED_PRODUCT_WITH_PART_NUMBER:
			return {
				...state,
				productsWithPartNumberSearched: action.productsWithPartNumberSearched,
			};
		case actionTypes.SET_SEARCHED_PRODUCT_WITH_VIN_NUMBER:
			return {
				...state,
				productsWithVinSearched: action.productsWithVinSearched,
			};
		case actionTypes.CLEAR_SEARCHED_PRODUCT:
			return {
				...state,
				productsWithPartNumberSearched: [],
				productsWithVinSearched: [],
				searchedEngines: [],
			};
		case actionTypes.SET_VEHICLE_TYPE:
			return {
				...state,
				vehicleType: action.vehicleType,
			};
		case actionTypes.SET_SEARCHED_ENGINES:
			return {
				...state,
				searchedEngines: action.searchedEngines,
			};
		case actionTypes.SET_REMAN_SCREEN_TYPE:
			return {
				...state,
				screenType: action.screenType,
			};
		case actionTypes.SET_PRODUCT_CATEGORY_LIST:
			return {
				...state,
				productCategoryList: action.productCategoryList,
			};
		case actionTypes.SET_VIN_CATEGORY_TITLE:
			return {
				...state,
				vinSelectedCategoryTitle: action.vinSelectedCategoryTitle,
			};
		case actionTypes.SET_BREADCRUMB_ENGINE_ID:
			return {
				...state,
				enginesSectionId: action.enginesSectionId,
			};
		default:
			return state;
	}
};

export default remanSearchReducer;

export const actionTypes = {
	LOGOUT: 'LOGOUT',
	GET_LICENSES: 'GET_LICENSES',
	GET_USER_INFO: 'GET_USER_INFO',
	GET_DIGITAL_SERVICE_BOOKLET_STATUS: 'GET_DIGITAL_SERVICE_BOOKLET_STATUS',
	UPDATE_TERMS_OF_USE_STATUS: 'UPDATE_TERMS_OF_USE_STATUS',
	GET_REPUBLISHER_REQUEST_STATUS: 'GET_REPUBLISHER_REQUEST_STATUS',
	REQUEST_REPUBLISHER_ROLE: 'REQUEST_REPUBLISHER_ROLE',
	TOGGLE_SHOW_TERMS_OF_USE_MODAL: 'TOGGLE_SHOW_TERMS_OF_USE_MODAL',
	SET_GA_CONSENT_STATUS: 'SET_GA_CONSENT_STATUS',
	SET_TRACING_CONSENT_STATUS: 'SET_TRACING_CONSENT_STATUS',
	SET_ONE_DOC_DATA: 'SET_ONE_DOC_DATA',
	GET_SATISFACTION_FEEDBACK_SCORE_USER_ENABLE: 'GET_SATISFACTION_FEEDBACK_SCORE_USER_ENABLE',
	CREATE_SATISFACTION_FEEDBACK_SCORE: 'CREATE_SATISFACTION_FEEDBACK_SCORE',
};

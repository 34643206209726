import { actionTypes } from '@Reducers/orders/actionTypes';

const initialState = {
	arvatoOrderDetailsReceived: false,
	webpartsOrdersError: false,
	arvatoOrdersError: false,
	ordersApiError: false,
	orders: undefined,
	loadingSaveOrderRating: false,
	activePage: 1,
	loadingOrderRatingQuestions: false,
	orderRatingQuestions: [],
	detailedArvatoOrders: undefined,
	ratingsLoading: false,
	orderRatings: [],
	ordersCacheDataUsed: false,
};

export const ordersReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_ORDERS:
			return {
				...state,
				orders: action.orders,
				ordersApiError: action.ordersApiError,
				webpartsOrdersError: action.webpartsOrdersError,
				arvatoOrdersError: action.arvatoOrdersError,
				ordersCacheDataUsed: action.ordersCacheDataUsed,
			};
		case actionTypes.SAVE_ORDER_RATING:
			return {
				...state,
				loadingSaveOrderRating: action.loadingSaveOrderRating,
			};
		case actionTypes.GET_ORDER_RATING_QUESTIONS:
			return {
				...state,
				loadingOrderRatingQuestions: action.loadingOrderRatingQuestions,
				orderRatingQuestions: action.orderRatingQuestions,
			};
		case actionTypes.CLEAR_ORDER_RATING:
		case actionTypes.GET_ORDER_RATINGS:
			return {
				...state,
				orderRatings: action.orderRatings,
				ratingsLoading: action.ratingsLoading,
			};
		case actionTypes.CLEAR_ARVATO_ORDER_DETAILS:
		case actionTypes.GET_ARVATO_ORDER_DETAILS:
			return {
				...state,
				detailedArvatoOrders: action.detailedArvatoOrders,
			};
		default:
			return state;
	}
};

export default ordersReducer;

export interface TriggerEventProps {
	event: string;
	element: HTMLElement;
}

export default function TriggerEvent(eventProps: TriggerEventProps) {
	const { event, element } = eventProps;
	if (typeof (window as any).MouseEvent === 'function') {
		const evt = new MouseEvent(event, {
			view: window,
			bubbles: true,
			cancelable: true,
			clientX: 0,
		});
		element.dispatchEvent(evt);
	}
}

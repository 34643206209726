import getAppEnvironment from '@Helpers/common/getAppEnvironment';
import LICENSE_REQUEST_URLS from '@Network/external-url/license-request-url.json';
import { Environments, WindowTargetAttributes } from '@Constants/common';

export const goToXshopOverview = () => {
	const APP_ENV = getAppEnvironment();
	const url =
		APP_ENV === Environments.PROD ? LICENSE_REQUEST_URLS.PROD.XSHOP_RMI[1] : LICENSE_REQUEST_URLS.NON_PROD.XSHOP_RMI[1];
	window.open(url, WindowTargetAttributes.BLANK);
};

import { IconType } from '@isp/icon';
import { ProductKeys } from '@Constants/product';

export const CAMPAIGN_PER_PAGE = 1;
export const ROUTE = /\[((\.\.\.)?\w+)?\]/g;
export const MARGIN_TOP_SCROLL_BUTTON = 120;
export const STICKY_TOP_SCOLL_BUTTON = 40;
export const HOME_LOGO_WIDTH = 194;
export const HOME_LOGO_HEIGHT = 54;

export interface Feedback {
	feedbackValue?: FeedbackValue;
	url?: string;
	releaseVersion?: string;
	notes?: string;
}

export enum HomeModules {
	AT_A_GLANCE_MODULE = 'AT_A_GLANCE_MODULE',
	BENEFITS_MODULE = 'BENEFITS_MODULE',
	PLATFORM_PREVIEW_MODULE = 'PLATFORM_PREVIEW_MODULE',
	QUOTE_MODULE = 'QUOTE_MODULE',
	REGISTER_MODULE = 'REGISTER_MODULE',
}

export enum FeedbackValue {
	POSITIVE = 'POSITIVE',
	NEGATIVE = 'NEGATIVE',
}

export enum FeedbackBackground {
	WHITE = 'bg-white',
	WHITE_SMOKE = 'bg-white-smoke',
	WHITE_TWO = 'bg-white-two',
}

export enum HomePartialKeys {
	HOME = 'home',
	HOME_SECTION = 'home_section',
	HOME_GYOW = 'home_gyow',
	HOME_SOLUTION_FINDER = 'home_solution_finder',
	HOME_CAMPAIGNS = 'home_campaigns',
	HOME_NEWS = 'home_news',
}

export enum ContentStates {
	ERROR = 'ERROR',
	LOADING = 'LOADING',
	READY = 'READY',
}

export const SUGGESTED_PRODUCTS_CONTENT = {
	[ProductKeys.WIS]: {
		productCode: '000000006523113299',
		icon: IconType.SERVICE_EXECUTION,
		title: 'home:wis',
		description: 'shop:product-suggestion-wis',
	},
	[ProductKeys.PARTS_INFORMATION]: {
		productCode: '000000006523111199',
		icon: IconType.BOOKSCREW,
		title: 'home:parts-information',
		description: 'shop:product-suggestion-parts-information',
	},
	[ProductKeys.XENTRY_TIPS]: {
		productCode: '000000006523111077',
		icon: IconType.ACCIDENT,
		title: 'home:xentry-tips',
		description: 'shop:product-suggestion-xentry-tips',
	},
	[ProductKeys.XENTRY_DIAGNOSIS_SYSTEM]: {
		productCode: null,
		icon: IconType.ACCIDENT,
		title: 'shop:xentry-diagnostic-systems',
		description: 'shop:product-suggestion-xentry-diagnostic-system',
	},
	[ProductKeys.XENTRY_PASS_THRU_EU]: {
		productCode: null,
		icon: IconType.ACCIDENT,
		title: 'shop:xentry-pass-thru-eu',
		description: 'shop:product-suggestion-xentry-pass-thru-eu',
	},
};

import { ReactElement, FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import Icon, { IconType, IconVariant, IconSize } from '@isp/icon';
import Accordion from '@isp/accordion';
import AccordionItem from '@isp/accordion-item';
import Badge, { BadgeThemes, BadgeSize } from '@isp/badge';
import { triggerScrollEvent } from '@Helpers/common/document';
import { ChildrenContent } from '@Constants/common/interfaces';
import '@Shared/InteractionCard/index.scss';

interface InteractionCardProps {
	collapsible?: boolean;
	index?: number;
	title?: string;
	footer?: ReactElement | string;
	className?: string;
	initalExpanded?: boolean;
	disabled?: boolean;
	disabledErrorMsg?: string;
	icon?: IconType;
	isOpen?: boolean;
	children: ChildrenContent;
	onCollapseChange?(isExpanded: boolean): void;
	hasActiveLicense?: boolean;
}
const InteractionCard: FC<InteractionCardProps> = ({
	children,
	collapsible,
	index,
	title,
	footer,
	className,
	disabledErrorMsg,
	icon,
	initalExpanded = false,
	disabled = false,
	isOpen = false,
	hasActiveLicense = false,
	onCollapseChange = () => {},
}) => {
	const [expanded, setExpanded] = useState(initalExpanded);

	const getContent = () => {
		return (
			<>
				{disabled && (
					<div className="train__flex train__center m-t-7 m-l-5">
						<Icon type={IconType.ERROR} size={IconSize.SMALLER} variant={IconVariant.BLOODRED} />
						<p className="DaimlerCS-Regular fs-14 m-l-3 ">{disabledErrorMsg}</p>
					</div>
				)}
				<div className={classNames({ disable: disabled && hasActiveLicense })}>
					<div className={`p-5 ${collapsible && 'p-t-0'}`}>{children}</div>
					{footer && <div className="interaction-card__footer p-5">{footer}</div>}
				</div>
			</>
		);
	};

	useEffect(() => {
		setExpanded(isOpen);
	}, [isOpen]);

	const handleAccordionItemClick = () => {
		setExpanded(!expanded);
		onCollapseChange(!expanded);
		triggerScrollEvent();
	};

	return (
		<div className={classNames('interaction-card', 'bg-white-smoke', className)}>
			{!collapsible && getContent()}
			{collapsible && (
				<Accordion>
					<AccordionItem
						isOpen={expanded}
						arrowSize={IconSize.MEDIUM}
						onClick={handleAccordionItemClick}
						accordionItemTitleClassName="p-5 disp-flex train__space-between"
						title={
							<div className="train train__center fs-16 p-r-3">
								{icon ? (
									<Icon type={icon} size={IconSize.SMALLER} variant={IconVariant.BLUE} />
								) : (
									<Badge className="m-r-2" value={index} theme={BadgeThemes.BLACK} size={BadgeSize.SMALLER} />
								)}
								<span>{title}</span>
							</div>
						}
					>
						{getContent()}
					</AccordionItem>
				</Accordion>
			)}
		</div>
	);
};

export default InteractionCard;

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOGGLE_LUCKY_DRAW_MODAL, TOGGLE_NO_ACTIVE_LUCKY_DRAW_MODAL } from '@Reducers/modals/actions';
import { RootState } from '@Redux';
import { LuckyDrawPageContent } from '@Umbraco/lucky-draw';
import { Events } from '@Constants/common';

enum CMSLinkActions {
	OPEN_LUCKY_DRAW_MODAL = 'lucky-draw-modal',
}

export const useCMSInternalLink = () => {
	const dispatch = useDispatch();
	const luckyDrawContent = useSelector<RootState, LuckyDrawPageContent>(state => state.sections.luckyDrawContent);
	const linkActionMapRef = useRef(new Map());

	const getLinkAction = (link: HTMLElement) => {
		const refAttribute = link.getAttribute('data-ref');
		switch (refAttribute) {
			case CMSLinkActions.OPEN_LUCKY_DRAW_MODAL:
				return () => {
					if (luckyDrawContent) dispatch(TOGGLE_LUCKY_DRAW_MODAL());
					else dispatch(TOGGLE_NO_ACTIVE_LUCKY_DRAW_MODAL());
				};
			default:
				return () => {};
		}
	};

	useEffect(() => {
		const resetListeners = () => {
			linkActionMapRef.current.forEach((action, link) => {
				link.removeEventListener(Events.CLICK, action);
			});
			linkActionMapRef.current.clear();
		};

		const addListeners = () => {
			const links = document.querySelectorAll(`[data-type="link"]:not([data-processed="true"])`);
			links.forEach((link: HTMLElement) => {
				const action = getLinkAction(link);
				linkActionMapRef.current.set(link, action);
				link.addEventListener(Events.CLICK, action);
				link.classList.add('link');
				link.setAttribute('data-processed', 'true');
			});
		};

		resetListeners();
		addListeners();

		return () => {
			resetListeners();
			document.querySelectorAll(`[data-type="link"]`).forEach((link: HTMLElement) => {
				link.removeAttribute('data-processed');
			});
		};
	}, [luckyDrawContent]);
};

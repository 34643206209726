import get from 'lodash/get';
import NextI18Next from '@Lib/i18n';

// TODO: Remove export from line 6 & Update file name when geti18nLanguage is not exported anymore

export const geti18nLanguage = (language: string, useWithMarket = false): string => {
	if (language) {
		const marketIncluded: boolean = language.includes('-');
		if (marketIncluded) {
			return useWithMarket ? language : get(language.split('-').slice(0, -1), '[0]');
		}
		return language;
	}
	return null;
};

export const getLanguage = () => geti18nLanguage(NextI18Next.i18n.language);

import requestData from '@Network/index';

export function getAllMarkets() {
	return requestData('GET', 'MARKETS', { shouldRetry: true })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getMarketConfigurations(market: string) {
	return requestData('GET', 'MARKET_CONFIGURATION', { interpolate: { id: market }, shouldRetry: true })
		.then(res => {
			return res.data;
		})
		.catch(() => {
			return null;
		});
}

export function getApplicationSettings() {
	return requestData('GET', 'APP_SETTINGS', { shouldRetry: true })
		.then(res => {
			return res.data;
		})
		.catch(() => {
			return null;
		});
}

export function getActiveUserCount() {
	return requestData('GET', 'ACTIVE_USER_COUNT')
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import InteractionCard from '@Shared/InteractionCard';
import ButtonWithIcon, { ButtonVariant } from '@isp/button-with-icon';
import { IconType } from '@isp/icon';
import { GoToExternalURL } from '@Helpers/ExternalURLs';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { Buttons, Components, getComponentId, Pages } from '@Constants/google-analytics';
import { ShopProductKeys } from '@Constants/shop';

interface ArvatoBlockedCardProps {
	className?: string;
	equipmentPage?: boolean;
	productKey?: ShopProductKeys;
}

const ArvatoBlockedCard: FC<ArvatoBlockedCardProps> = ({ className = '', equipmentPage = false, productKey }) => {
	const { t: translate } = useTranslation(['shop']);

	const onClick = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		GoToExternalURL('XENTRY_DIAGNOSIS_URL');
	};

	return (
		<InteractionCard className={className}>
			<p className="color-black-two m-b-5">
				{translate(`shop:${equipmentPage ? 'equipment-' : ''}purchase-in-xentry-shop`)}
			</p>
			<ButtonWithIcon
				className="p-h-5"
				variant={ButtonVariant.PRIMARY}
				iconType={IconType.EXTERNAL_LINK}
				onClick={e => onClick(e)}
				id={getComponentId(
					[productKey || Pages.EQUIPMENT, Components.ARVATO_BLOCKED, Components.XENTRY_DIAGNOSIS, Buttons.GO_TO_URL],
					true,
					true
				)}
			>
				{translate('shop:go-to-xentry-shop')}
			</ButtonWithIcon>
		</InteractionCard>
	);
};

export default ArvatoBlockedCard;

import { actionTypes } from '@Reducers/faq/actionTypes';

export const initialFAQState = {
	supportData: null,
	productRoutes: [],
};

export const faqReducer = (state = initialFAQState, action) => {
	switch (action.type) {
		case actionTypes.CONTINUE_SUPPORT_MODAL:
		case actionTypes.COMPLETE_SUPPORT_MODAL:
			return {
				...state,
				supportData: action.supportData,
			};
		case actionTypes.GET_PRODUCT_ROUTES:
			return {
				...state,
				productRoutes: action.productRoutes,
			};
		default:
			return state;
	}
};

export default faqReducer;

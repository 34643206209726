import router from 'next/router';
import {
	getBasketTotalCount,
	getOrderAddresses,
	updateHardwareId,
	getAccessStatus,
	webpartsOrderCheckout,
	updateBasketMultipleItems,
	getEquipmentBasketProducts,
	getDigitalBasketProducts,
	getDiagnosisBasketProducts,
	deleteWebpartsFromBasket,
	getWebpartsBasketProducts,
	getWebpartsCheckoutBasket,
	deleteEquipmentProduct,
	deleteDiagnosisProduct,
	deleteDigitalProduct,
	deleteDiagnosisBasket,
	deleteEquipmentBasket,
	deleteDigitalBasket,
	deleteWebpartsBasket,
} from '@Apis/shop';
import { getDeselectedProductsOfBasket, setShowNetPrices } from '@Helpers/webparts';
import { TOGGLE_ORDER_WITHOUT_PAYMENT_STATUS_MODAL } from '@Reducers/modals/actions';
import { actionTypes } from '@Reducers/shop/actionTypes';
import {
	AllBasketsContainer,
	DeleteWebpartsRequestBody,
	WebpartsPlaceOrderList,
	UpdateBasketMultipleItemsRequestBody,
	UpdateBasketOptions,
	PartBasket,
	PartCheckoutDealer,
} from '@Reducers/shop/models';
import { PromiseStatus } from '@Constants/common';
import { OrderType } from '@Constants/orders';
import {
	SHOP_BASKET_NOTIFICATION_TIMEOUT,
	ShoppingBasketAccessStatus,
	SHOP_BASKET_NOTIFICATION_DISPLAY_TIMEOUT,
	MARKETS_NOT_ALLOW_DIGITAL_PRODUCT,
} from '@Constants/shop';
import { Markets } from '@Constants/market';

export const GET_BASKET_TOTAL_COUNT = () => (dispatch, getState) => {
	const { shop } = getState();

	dispatch({
		type: actionTypes.GET_BASKET_TOTAL_COUNT,
		basketCount: shop.basketCount,
	});

	return getBasketTotalCount()
		.then(response => {
			dispatch({
				type: actionTypes.GET_BASKET_TOTAL_COUNT,
				basketCount: response ?? 0,
			});
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_BASKET_TOTAL_COUNT,
			});
		});
};

export const TOGGLE_SHOPPING_BASKET_NOTIFICATION = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_SHOPPING_BASKET_NOTIFICATION,
		showBasketNotification: !getState().shop.showBasketNotification,
	});
};

export const SET_PART_SEARCH_INSIDE_BASKET = isPartSearchInsideBasket => dispatch => {
	dispatch({
		type: actionTypes.SET_PART_SEARCH_INSIDE_BASKET,
		isPartSearchInsideBasket,
	});
};

export const UPDATE_BASKET_MULTIPLE_ITEMS = (data: {
	requestData: UpdateBasketMultipleItemsRequestBody;
	cartType: OrderType;
	totalItemCount: number;
}) => dispatch => {
	const { requestData, cartType, totalItemCount } = data;

	dispatch({
		type: actionTypes.UPDATE_BASKET_MULTIPLE_ITEMS,
		addToBasketLoading: true,
		addToBasketError: false,
		multipleItemsAdded: false,
	});

	return updateBasketMultipleItems(requestData)
		.then(() => {
			dispatch({
				type: actionTypes.UPDATE_BASKET_MULTIPLE_ITEMS,
				updatedProductId: null,
				addToBasketLoading: false,
				cartType,
				quantity: totalItemCount,
				lastUpdatedBasketType: cartType,
				multipleItemsAdded: true,
			});
			dispatch(GET_BASKET_TOTAL_COUNT());
			setTimeout(() => {
				dispatch(TOGGLE_SHOPPING_BASKET_NOTIFICATION());
			}, SHOP_BASKET_NOTIFICATION_DISPLAY_TIMEOUT);
			setTimeout(() => {
				dispatch(TOGGLE_SHOPPING_BASKET_NOTIFICATION());
			}, SHOP_BASKET_NOTIFICATION_TIMEOUT);
		})
		.catch(err => {
			dispatch({
				type: actionTypes.UPDATE_BASKET_MULTIPLE_ITEMS,
				addToBasketError: true,
				addToBasketLoading: false,
			});
			throw err;
		});
};

export const UPDATE_BASKET = (options: UpdateBasketOptions) => (dispatch, getState) => {
	const { shop } = getState();
	const { data, addedProductName, showNotification, updateBasketFunction, cartType, quantity, useTimeZone } =
		options || {};
	const productId = 'productId' in data ? data.productId : null;

	dispatch({
		type: actionTypes.UPDATE_BASKET,
		basketCount: shop.basketCount,
		addToBasketLoading: true,
		addToBasketError: false,
		updatedProductId: productId,
		multipleItemsAdded: false,
		addedProductName,
	});
	const basketFunction = updateBasketFunction;

	return basketFunction(data, useTimeZone)
		.then(() => {
			dispatch({
				type: actionTypes.UPDATE_BASKET,
				updatedProductId: null,
				addedProductName,
				addToBasketLoading: false,
				cartType,
				quantity,
				lastUpdatedBasketType: cartType,
			});
			dispatch(GET_BASKET_TOTAL_COUNT());
			if (showNotification) {
				setTimeout(() => {
					dispatch(TOGGLE_SHOPPING_BASKET_NOTIFICATION());
				}, SHOP_BASKET_NOTIFICATION_DISPLAY_TIMEOUT);
				setTimeout(() => {
					dispatch(TOGGLE_SHOPPING_BASKET_NOTIFICATION());
				}, SHOP_BASKET_NOTIFICATION_TIMEOUT);
			}
			return true;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.UPDATE_BASKET,
				addToBasketError: true,
				addToBasketLoading: false,
				addedProductName: null,
				multipleItemsAdded: false,
			});
			throw err;
		});
};

export const DELETE_PRODUCT_FROM_BASKET = (dbItemId, basketType: OrderType) => (dispatch, getState) => {
	const { shop } = getState();
	const BASKET_TYPE_DELETION_FUNCTION_MAPPING = {
		[OrderType.EQUIPMENT]: deleteEquipmentProduct,
		[OrderType.DIAGNOSIS]: deleteDiagnosisProduct,
		[OrderType.DIGITAL]: deleteDigitalProduct,
	};
	dispatch({
		type: actionTypes.DELETE_PRODUCT_FROM_BASKET,
		lastDeletedBasketType: shop.lastDeletedBasketType || null,
		basketDataLoading: true,
		basketDataError: false,
	});

	return BASKET_TYPE_DELETION_FUNCTION_MAPPING[basketType](dbItemId)
		.then(() => {
			dispatch({
				type: actionTypes.DELETE_PRODUCT_FROM_BASKET,
				lastDeletedBasketType: basketType,
				basketDataLoading: false,
				basketDataError: false,
			});
			dispatch(GET_BASKET_TOTAL_COUNT());
		})
		.catch(err => {
			dispatch({
				type: actionTypes.DELETE_PRODUCT_FROM_BASKET,
				lastDeletedBasketType: shop.lastDeletedBasketType || null,
				basketDataError: true,
				basketDataLoading: false,
			});
			throw err;
		});
};

export const DELETE_WEBPARTS_FROM_BASKET = (deleteWebpartsRequestBody: DeleteWebpartsRequestBody) => (
	dispatch,
	getState
) => {
	const { shop } = getState();

	dispatch({
		type: actionTypes.DELETE_PRODUCT_FROM_BASKET,
		lastDeletedBasketType: shop.lastDeletedBasketType || null,
		basketDataLoading: true,
	});

	return deleteWebpartsFromBasket(deleteWebpartsRequestBody)
		.then(() => {
			dispatch({
				type: actionTypes.DELETE_WEBPARTS_FROM_BASKET,
				lastDeletedBasketType: OrderType.WEBPARTS,
				basketDataLoading: false,
			});
			dispatch(GET_BASKET_TOTAL_COUNT());
		})
		.catch(err => {
			dispatch({
				type: actionTypes.DELETE_WEBPARTS_FROM_BASKET,
				lastDeletedBasketType: OrderType.WEBPARTS,
				basketDataLoading: false,
			});
			throw err;
		});
};

export const GET_ALL_BASKET_DATA = (isRowMarket: boolean, useTimeZone?: boolean) => dispatch => {
	const basketRequests = [];
	const { market } = router.query;
	let basketData: AllBasketsContainer = {
		digitalBasket: null,
		diagnosisBasket: null,
		equipmentBasket: null,
		webpartsBasket: null,
	};

	dispatch({
		type: actionTypes.GET_ALL_BASKET_DATA,
		basketData,
		basketDataLoading: true,
		diagnosisBasketDataLoading: !isRowMarket,
		digitalBasketDataLoading: true,
		equipmentBasketDataLoading: !isRowMarket,
		webpartsBasketDataLoading: true,
		basketDataError: false,
	});

	if (!isRowMarket) {
		basketRequests.push(getEquipmentBasketProducts(useTimeZone));
		basketRequests.push(getDiagnosisBasketProducts(useTimeZone));
	}

	if (!MARKETS_NOT_ALLOW_DIGITAL_PRODUCT.includes(market as Markets)) {
		basketRequests.push(getDigitalBasketProducts(useTimeZone));
	}

	basketRequests.push(getWebpartsBasketProducts(useTimeZone));

	Promise.allSettled(basketRequests)
		.then(basketResults => {
			let webpartsBasketDataError = false;
			let equipmentBasketDataError = false;
			let digitalBasketDataError = false;
			let diagnosisBasketDataError = false;

			basketResults.forEach(basketResult => {
				if (basketResult?.status === PromiseStatus.FULFILLED) {
					switch (basketResult?.value?.basketType) {
						case OrderType.WEBPARTS:
							basketData = { ...basketData, webpartsBasket: basketResult?.value };
							break;
						case OrderType.EQUIPMENT:
							basketData = { ...basketData, equipmentBasket: basketResult?.value };
							break;
						case OrderType.DIAGNOSIS:
							basketData = { ...basketData, diagnosisBasket: basketResult?.value };
							break;
						case OrderType.DIGITAL:
							basketData = { ...basketData, digitalBasket: basketResult?.value };
							break;
						default:
							break;
					}
				} else {
					switch (basketResult?.reason?.basketType) {
						case OrderType.WEBPARTS:
							basketData = { ...basketData, webpartsBasket: null };
							webpartsBasketDataError = true;
							break;
						case OrderType.EQUIPMENT:
							basketData = { ...basketData, equipmentBasket: null };
							equipmentBasketDataError = true;
							break;
						case OrderType.DIAGNOSIS:
							basketData = { ...basketData, diagnosisBasket: null };
							diagnosisBasketDataError = true;
							break;
						case OrderType.DIGITAL:
							basketData = { ...basketData, digitalBasket: null };
							digitalBasketDataError = true;
							break;
						default:
							break;
					}
				}
			});
			dispatch({
				type: actionTypes.GET_ALL_BASKET_DATA,
				basketData,
				basketDataLoading: false,
				webpartsBasketDataError,
				equipmentBasketDataError,
				digitalBasketDataError,
				diagnosisBasketDataError,
			});
			return basketData;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_ALL_BASKET_DATA,
				basketData: null,
				basketDataError: true,
				basketDataLoading: false,
			});
			throw err;
		});
};

export const GET_DIAGNOSIS_BASKET_PRODUCTS = (useTimeZone?: boolean) => (dispatch, getState) => {
	const { shop } = getState();
	const { basketData } = shop;
	dispatch({
		type: actionTypes.GET_DIAGNOSIS_BASKET_PRODUCTS,
		diagnosisBasketDataLoading: true,
		diagnosisBasketDataError: false,
		basketData,
	});
	return getDiagnosisBasketProducts(useTimeZone)
		.then(response => {
			dispatch({
				type: actionTypes.GET_DIAGNOSIS_BASKET_PRODUCTS,
				basketData: { ...basketData, diagnosisBasket: response },
				diagnosisBasketDataError: false,
				diagnosisBasketDataLoading: false,
			});
			return response;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_DIAGNOSIS_BASKET_PRODUCTS,
				basketData: { ...basketData, diagnosisBasket: null },
				diagnosisBasketDataError: true,
				diagnosisBasketDataLoading: false,
			});
			throw err;
		});
};

export const GET_DIGITAL_BASKET_PRODUCTS = (useTimeZone?: boolean) => (dispatch, getState) => {
	const { shop } = getState();
	const { basketData } = shop;
	dispatch({
		type: actionTypes.GET_DIGITAL_BASKET_PRODUCTS,
		digitalBasketDataLoading: true,
		digitalBasketDataError: false,
		basketData,
	});
	return getDigitalBasketProducts(useTimeZone)
		.then(response => {
			dispatch({
				type: actionTypes.GET_DIGITAL_BASKET_PRODUCTS,
				basketData: { ...basketData, digitalBasket: response },
				digitalBasketDataError: false,
				digitalBasketDataLoading: false,
			});
			return response;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_DIGITAL_BASKET_PRODUCTS,
				basketData: { ...basketData, digitaltBasket: null },
				digitalBasketDataError: true,
				digitalBasketDataLoading: false,
			});
			throw err;
		});
};

export const GET_EQUIPMENT_BASKET_PRODUCTS = (useTimeZone?: boolean) => (dispatch, getState) => {
	const { shop } = getState();
	const { basketData } = shop;
	dispatch({
		type: actionTypes.GET_EQUIPMENT_BASKET_PRODUCTS,
		equipmentBasketDataLoading: true,
		equipmentBasketDataError: false,
		basketData,
	});
	return getEquipmentBasketProducts(useTimeZone)
		.then(response => {
			dispatch({
				type: actionTypes.GET_EQUIPMENT_BASKET_PRODUCTS,
				basketData: { ...basketData, equipmentBasket: response },
				equipmentBasketDataError: false,
				equipmentBasketDataLoading: false,
			});
			return response;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_EQUIPMENT_BASKET_PRODUCTS,
				basketData: { ...basketData, equipmentBasket: null },
				equipmentBasketDataError: true,
				equipmentBasketDataLoading: false,
			});
			throw err;
		});
};

export const GET_WEBPARTS_BASKET_PRODUCTS = (useTimeZone?: boolean) => (dispatch, getState) => {
	const { shop } = getState();
	const { basketData } = shop;
	dispatch({
		type: actionTypes.GET_WEBPARTS_BASKET_PRODUCTS,
		webpartsBasketDataLoading: true,
		webpartsBasketDataError: false,
		basketData,
	});
	return getWebpartsBasketProducts(useTimeZone)
		.then(response => {
			dispatch({
				type: actionTypes.GET_WEBPARTS_BASKET_PRODUCTS,
				basketData: { ...basketData, webpartsBasket: response },
				webpartsBasketDataError: false,
				webpartsBasketDataLoading: false,
			});
			return response;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_WEBPARTS_BASKET_PRODUCTS,
				basketData: { ...basketData, webpartsBasket: null },
				webpartsBasketDataError: true,
				webpartsBasketDataLoading: false,
			});
			throw err;
		});
};

export const GET_WEBPARTS_BASKET_CHECKOUT_SUMMARY = (
	partBasket: PartBasket,
	partBasketCheckoutSummaryDealerList: PartCheckoutDealer[] = [],
	useTimeZone?: boolean
) => dispatch => {
	const { finNumber } = partBasket;
	const deselectedPartIds = getDeselectedProductsOfBasket(partBasket);

	dispatch({
		type: actionTypes.GET_WEBPARTS_BASKET_CHECKOUT_SUMMARY,
		webpartsBasketDataLoading: true,
		webpartsBasketDataError: false,
		checkoutOrderAddressLoading: true,
		checkoutOrderAddressError: false,
	});
	return getWebpartsCheckoutBasket({ finNumber, partBasketCheckoutSummaryDealerList, deselectedPartIds }, useTimeZone)
		.then(response => {
			dispatch({
				type: actionTypes.GET_WEBPARTS_BASKET_CHECKOUT_SUMMARY,
				webpartsCheckoutBasket: response,
				webpartsBasketDataError: false,
				webpartsBasketDataLoading: false,
				checkoutOrderAddressLoading: false,
				checkoutOrderAddressError: false,
			});
			return response;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_WEBPARTS_BASKET_CHECKOUT_SUMMARY,
				webpartsCheckoutBasket: null,
				webpartsBasketDataError: true,
				webpartsBasketDataLoading: false,
				checkoutOrderAddressLoading: false,
				checkoutOrderAddressError: true,
			});
			throw err;
		});
};

export const GET_ORDER_ADDRESSES = () => dispatch => {
	dispatch({
		type: actionTypes.GET_ORDER_ADDRESSES,
		orderAddresses: null,
		orderAddressesLoading: true,
		orderAddressesError: false,
	});

	return getOrderAddresses()
		.then(response => {
			dispatch({
				type: actionTypes.GET_ORDER_ADDRESSES,
				orderAddresses: response.data,
				orderAddressesLoading: false,
			});
			return response.data;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_ORDER_ADDRESSES,
				orderAddresses: null,
				orderAddressesLoading: false,
				orderAddressesError: true,
			});
			throw err;
		});
};

export const UPDATE_HARDWARE_ID = (systemNumber: string, hardwareId: string) => dispatch => {
	dispatch({
		type: actionTypes.UPDATE_HARDWARE_ID,
		systemId: null,
		hardwareId: null,
	});
	return updateHardwareId(systemNumber, hardwareId)
		.then(hardwareIdUpdated => {
			dispatch({
				type: actionTypes.UPDATE_HARDWARE_ID,
				systemId: systemNumber,
				hardwareId,
			});
			return hardwareIdUpdated;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.UPDATE_HARDWARE_ID,
				systemId: null,
				hardwareId: null,
			});
			throw err;
		});
};

export const GET_ACCESS_STATUS = () => dispatch => {
	return getAccessStatus()
		.then(response => {
			dispatch({
				type: actionTypes.GET_ACCESS_STATUS,
				accessStatus: response.status,
			});
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_ACCESS_STATUS,
				accessStatus: ShoppingBasketAccessStatus.ERROR,
			});
			throw err;
		});
};

export const UPDATE_ACCESS_STATUS = (status: ShoppingBasketAccessStatus) => dispatch => {
	dispatch({
		type: actionTypes.UPDATE_ACCESS_STATUS,
		accessStatus: status,
	});
};

export const SET_ROW_ORDER_PRODUCTS = (productsOrderedROW = null) => dispatch => {
	dispatch({
		type: actionTypes.SET_ROW_ORDER_PRODUCTS,
		productsOrderedROW,
	});
};

export const PLACE_WEBPARTS_ORDER = (args: {
	partSubBasketOrderList: WebpartsPlaceOrderList[];
	clientOrderNumber: string;
	comment: string;
}) => dispatch => {
	dispatch({
		type: actionTypes.PLACE_WEBPARTS_ORDER,
		errorPlaceOrderWithoutPayment: false,
		loadingPlaceOrderWithoutPayment: true,
	});
	return webpartsOrderCheckout({
		...args,
	})
		.then(response => {
			dispatch({
				type: actionTypes.PLACE_WEBPARTS_ORDER,
				loadingPlaceOrderWithoutPayment: false,
				orderedProducts: response || [],
			});
			dispatch(TOGGLE_ORDER_WITHOUT_PAYMENT_STATUS_MODAL());
			dispatch(GET_BASKET_TOTAL_COUNT());
		})
		.catch(() => {
			dispatch({
				type: actionTypes.PLACE_WEBPARTS_ORDER,
				errorPlaceOrderWithoutPayment: true,
				loadingPlaceOrderWithoutPayment: false,
				orderedProducts: [],
			});
		});
};

export const UPDATE_LAST_DELETED_BASKET = () => dispatch => {
	dispatch({
		type: actionTypes.UPDATE_LAST_DELETED_BASKET,
		lastDeletedBasketType: null,
	});
};

export const SET_SHOW_NET_PRICE = (showNetPrice = true) => dispatch => {
	setShowNetPrices(showNetPrice);
	dispatch({
		type: actionTypes.SET_SHOW_NET_PRICE,
		showNetPrice,
	});
};

export const SET_WEBPARTS_CHECKOUT_COMMENT = (comment: string) => dispatch => {
	dispatch({
		type: actionTypes.SET_WEBPARTS_CHECKOUT_COMMENT,
		webpartsCheckoutComment: comment,
	});
};

export const SET_CACHE_STATUS_FOR_DIAGNOSIS_LICENSES = (status: boolean) => dispatch => {
	dispatch({
		type: actionTypes.SET_CACHE_STATUS_FOR_DIAGNOSIS_LICENSES,
		cacheDataUsedDiagnosisLicense: status,
	});
};

export const BULK_DELETE_NON_WEBPARTS_BASKET = (basketType: OrderType) => dispatch => {
	const BASKET_TYPE_BULK_DELETION_FUNCTION_MAPPING = {
		[OrderType.EQUIPMENT]: deleteEquipmentBasket,
		[OrderType.DIAGNOSIS]: deleteDiagnosisBasket,
		[OrderType.DIGITAL]: deleteDigitalBasket,
	};
	dispatch({
		type: actionTypes.BULK_DELETE_NON_WEBPARTS_BASKET,
		lastDeletedBasketType: basketType,
		basketDataLoading: true,
		basketDataError: false,
	});

	return BASKET_TYPE_BULK_DELETION_FUNCTION_MAPPING[basketType]()
		.then(() => {
			dispatch({
				type: actionTypes.BULK_DELETE_NON_WEBPARTS_BASKET,
				lastDeletedBasketType: basketType,
				basketDataLoading: false,
				basketDataError: false,
			});
			dispatch(GET_BASKET_TOTAL_COUNT());
		})
		.catch(err => {
			dispatch({
				type: actionTypes.BULK_DELETE_NON_WEBPARTS_BASKET,
				lastDeletedBasketType: basketType,
				basketDataError: true,
				basketDataLoading: false,
			});
			throw err;
		});
};

export const BULK_DELETE_WEBPARTS_BASKET = data => (dispatch, getState) => {
	const { shop } = getState();
	const { basketId } = data && data[0];
	const basketDataToBeDeleted = data.map(obj => ({ positionIds: obj.positionIds, basketDealer: obj.basketDealer }));
	dispatch({
		type: actionTypes.BULK_DELETE_WEBPARTS_BASKET,
		lastDeletedBasketType: OrderType.WEBPARTS,
		basketDataLoading: true,
		bulkDeleteFailedBasketIds: shop.bulkDeleteFailedBasketIds,
	});

	return deleteWebpartsBasket(basketDataToBeDeleted)
		.then(() => {
			dispatch({
				type: actionTypes.BULK_DELETE_WEBPARTS_BASKET,
				lastDeletedBasketType: OrderType.WEBPARTS,
				basketDataLoading: false,
				bulkDeleteFailedBasketIds: shop.bulkDeleteFailedBasketIds,
			});
			dispatch(GET_BASKET_TOTAL_COUNT());
		})
		.catch(() => {
			dispatch({
				type: actionTypes.BULK_DELETE_WEBPARTS_BASKET,
				lastDeletedBasketType: OrderType.WEBPARTS,
				basketDataLoading: false,
				bulkDeleteFailedBasketIds: [...shop.bulkDeleteFailedBasketIds, basketId],
			});
		});
};

export const RESET_FAILED_DELETION_BASKET_IDS = () => dispatch => {
	dispatch({
		type: actionTypes.RESET_FAILED_DELETION_BASKET_IDS,
		bulkDeleteFailedBasketIds: [],
	});
};

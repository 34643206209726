import find from 'lodash/find';
import every from 'lodash/every';
import isUndefined from 'lodash/isUndefined';
import some from 'lodash/some';
import filter from 'lodash/filter';
import get from 'lodash/get';
import { Retailer } from '@Reducers/organization/models';
import { DealerStatuses } from '@Constants/retailer';

export const getRetailersStates = (dealers: Retailer[]) => {
	const numberOfRetailers = get(dealers, 'length', 0);

	const numberOfApprovedRetailers = get(filter(dealers, { status: DealerStatuses.APPROVED }), 'length', 0);

	const preferredRetailer = find(dealers, { isPreferred: true }) || null;

	const notPreferredRetails = filter(dealers, { isPreferred: false }) || null;

	const isAddRetailerState =
		numberOfRetailers === 0 || every(dealers, dealer => dealer.status === DealerStatuses.NOT_ANSWERED);

	const isPendingState =
		numberOfRetailers > 0 &&
		every(dealers, dealer =>
			[DealerStatuses.NOT_ANSWERED as string, DealerStatuses.PENDING as string].includes(dealer.status)
		) &&
		!isAddRetailerState;

	const isNoPreferredRetailerState =
		numberOfRetailers > 0 && some(dealers, dealer => dealer.status === DealerStatuses.APPROVED);

	const isPreferredRetailerAvailable = numberOfRetailers > 0 && !isUndefined(preferredRetailer);

	return {
		numberOfRetailers,
		numberOfApprovedRetailers,
		preferredRetailer,
		isAddRetailerState,
		isPendingState,
		isNoPreferredRetailerState,
		isPreferredRetailerAvailable,
		notPreferredRetails,
	};
};

import { useSelector } from 'react-redux';
import flatten from 'lodash/flatten';
import { RootState } from '@Redux';
import { getFooterMenuExternalUrls, getFooterOrder, getNewsletter, getQrCodeData } from '@Umbraco/footer';
import getImprintItems from '@Umbraco/imprint';
import { MenuItem, getMenu } from '@Umbraco/menu';
import { MainMenuCategories } from '@Constants/product';

export const getFooterContent = async (market, langAvailable) => {
	const [footerGroups, footerMenuExternalUrls, newsletter, qrCode, footerOrder] = await Promise.all([
		getImprintItems(market as string, langAvailable),
		getFooterMenuExternalUrls(market as string, langAvailable),
		getNewsletter(market as string, langAvailable),
		getQrCodeData(market as string, langAvailable),
		getFooterOrder(market as string, langAvailable),
	]);

	return {
		footerMenuExternalUrls,
		newsletter,
		footerGroups: (footerGroups || []).map(item => ({
			key: item.key,
			title: item.title,
		})),
		qrCode,
		footerOrder,
	};
};

export const getHeaderContent = async (market, langAvailable) => {
	const [menuItems] = await Promise.all([getMenu(market as string, langAvailable).then(menu => menu?.children)]);
	return { menuItems: menuItems ?? [] };
};

export const getCategorizedMenuItems = (itemTypes: MainMenuCategories[], productType = null) => {
	const menuItems = useSelector<RootState, MenuItem[]>(state => state.sections.headerContent.menuItems);
	return flatten(
		(menuItems.filter(item => itemTypes.includes(item.type as MainMenuCategories)) || []).map(
			child => (!productType || child.type === productType) && (child?.children || [])
		)
	);
};

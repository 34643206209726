import { ReactElement } from 'react';
import get from 'lodash/get';
import { renderToStaticMarkup } from 'react-dom/server';
import { transform } from '@Umbraco/helpers/transform';
import CONFIG from '@Umbraco/config.json';
import parse from 'html-react-parser';
import { client } from '../client';

export interface ImprintItem {
	id: string;
	key: string;
	title: string;
	description: string;
}

export const toImprintItem = item => ({
	id: item._id,
	key: item.identifier,
	title: item.title,
	description: renderToStaticMarkup(parse(item.text, { replace: transform }) as ReactElement),
});

export default function getImprintItems(market: string, lang: string) {
	const id = CONFIG.IMPRINT[market.toUpperCase()];

	return client(`/content/${id}/children/1/1000`, market, lang)
		.then(response => {
			const imprintItems = get(response, '_embedded.content', []);
			return imprintItems.map(toImprintItem);
		})
		.catch(() => null);
}

export const getConstentModalContent = (market: string, lang: string) => {
	const id = CONFIG.CONSENT_MODAL_CONFIG;
	return client(`/content/${id}`, market, lang)
		.then(response => {
			return response;
		})
		.catch(err => {
			throw err;
		});
};

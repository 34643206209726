import { useRouter } from 'next/router';
import Container from '@isp/container';
import { IconType } from '@isp/icon';
import Grid from '@isp/grid';
import GridItem from '@isp/grid-item';
import routes from '@Routes';
import Feature from '@Shared/feature/Feature';
import ArvatoBlockedCard from '@Partials/Shop/Shared/ArvatoBlockedCard';
import ProductCard from '@Partials/Equipment/Header/ProductCard';
import { FeatureKeys } from '@Constants/common';
import { ShopProductKeys } from '@Constants/shop';
import {
	EQUIPMENT_HEADER_PARTS_INFORMATION_PRODUCT_CODE,
	EQUIPMENT_HEADER_WIS_PRODUCT_CODE,
} from '@Constants/product/equipment';

const EquipmentHeader = ({ translate }) => (
	<div className="bg-white">
		<Container>
			<div className="p-v-12">
				<div className="w-70-p">
					<h1 className="DaimlerCAC-Regular fs-38 color-black-two" style={{ fontWeight: 'normal' }}>
						{translate('shop:search-equipment-header-title')}
					</h1>
					<Feature
						feature={FeatureKeys.ARVATO_BLOCKED}
						toggle={
							<p className="DaimlerCS-Regular fs-16 color-black-two p-t-4">
								{translate('shop:search-equipment-header-description')}
							</p>
						}
					>
						<div />
					</Feature>
				</div>
				<Feature feature={FeatureKeys.ARVATO_BLOCKED} toggle={<EquipmentProductCardContainer translate={translate} />}>
					<Grid guterless>
						<GridItem size="1/2">
							<ArvatoBlockedCard className="m-t-6" equipmentPage />
						</GridItem>
					</Grid>
				</Feature>
			</div>
		</Container>
	</div>
);

const EquipmentProductCardContainer = ({ translate }) => {
	const router = useRouter();
	const { market } = router.query;

	return (
		<div className="train__flex p-t-6 w-100-p">
			<div className="p-r-4 w-50-p">
				<ProductCard
					title={translate('home:wis')}
					description={translate('shop:search-equipment-header-wis-product-description')}
					icon={IconType.SERVICE_EXECUTION}
					productKey={ShopProductKeys.WIS}
					route={routes.WIS}
					market={market as string}
					productCode={EQUIPMENT_HEADER_WIS_PRODUCT_CODE}
				/>
			</div>
			<div className="p-l-4 w-50-p">
				<ProductCard
					title={translate('home:parts-information')}
					description={translate('shop:search-equipment-header-parts-information-product-description')}
					icon={IconType.BOOKSCREW}
					productKey={ShopProductKeys.PARTS_INFORMATION}
					route={routes.PARTS_INFORMATION}
					market={market as string}
					productCode={EQUIPMENT_HEADER_PARTS_INFORMATION_PRODUCT_CODE}
				/>
			</div>
		</div>
	);
};

export default EquipmentHeader;

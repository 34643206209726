import { FC, FunctionComponent, ReactNode } from 'react';
import { getStyleObjectFromString } from '@Helpers/style/getStyleObjectFromString';
import useMarketTemplate from '@Helpers/hooks/useAnchorMarketTemplate';
import { useVideoPlayer } from '@Helpers/hooks/useVideoPlayer';
import useCollapsible from '@Helpers/hooks/useCollapsible';
import { useCMSInternalLink } from '@Helpers/hooks/useCMSInternalLink';
import './CMSContent.scss';

export interface CMSContentProps {
	content: string;
	contentClassName?: string;
	theme?: CMSContentThemes;
	modal?: boolean;
}

export enum CMSTableThemes {
	DEFAULT = 'default',
	PLAIN = 'plain',
}

export enum CMSContentThemes {
	LIGHT = 'light',
	DARK = 'dark',
}

const CMSContent: FC<CMSContentProps> = ({
	content,
	contentClassName,
	theme = CMSContentThemes.DARK,
	modal = false,
}) => {
	useVideoPlayer();
	useMarketTemplate();
	useCollapsible();
	useCMSInternalLink();
	return (
		<div
			suppressHydrationWarning
			className={`cms-content ${contentClassName || 'p-5'} theme_${theme}`}
			data-modal={modal}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	);
};

export const CMSTable: FC<{
	children: ReactNode;
	theme?: CMSTableThemes;
	style: string;
	id: string | null;
	collapsible: boolean;
	otherAttribs?: any[];
}> = ({ id, children, theme = CMSTableThemes.DEFAULT, style = '', collapsible = true, otherAttribs = [] }) => {
	return (
		<>
			<table {...otherAttribs} id={id} className={`m-v-4 theme_${theme}`} style={getStyleObjectFromString(style)}>
				{children}
			</table>
			{collapsible && <p className="collapse-btn" />}
		</>
	);
};

export const CMSTableWithIcons: FunctionComponent<any> = ({ children, theme = CMSTableThemes.DEFAULT }) => (
	<table className={`cms-table-with-icons theme_${theme}`}>{children}</table>
);

export default CMSContent;

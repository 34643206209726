import { useEffect } from 'react';
import CMSCollapsible from '@Shared/cms-content/CMSCollapsible';
import { replaceNodeWithReactComponent } from '@Helpers/common/replaceNode';

const useCollapsible = () => {
	useEffect(() => {
		const collapsibleElements = document.querySelectorAll(`[data-collapsible='true']`);
		collapsibleElements.forEach((element: HTMLElement) => {
			if (element) {
				const initialyCollapsed = !!element.getAttribute('data-initially-collapsed');
				// !! is for string to boolean convertion
				replaceNodeWithReactComponent(
					element,
					<CMSCollapsible initiallyCollapsed={initialyCollapsed}>
						<div dangerouslySetInnerHTML={{ __html: element.innerHTML }} />
					</CMSCollapsible>
				);
				element.setAttribute('data-collapsible', 'false');
				element.setAttribute('data-initially-collapsed', 'false');
				element.style.display = 'unset';
			}
		});
	});
};

export default useCollapsible;

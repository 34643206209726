import { Component } from 'react';
import NAMESPACES from './namespaces';

export default function withDefaultNamespace(WrappedComponent) {
	return class DefaultNamespace extends Component {
		static async getInitialProps(ctx) {
			const appProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));
			const { pageProps } = appProps;
			let namespacesRequired = NAMESPACES;
			if (appProps?.namespacesRequired || pageProps?.namespacesRequired) {
				namespacesRequired = appProps?.namespacesRequired || pageProps.namespacesRequired;
			}
			return { ...appProps, namespacesRequired, pageProps: { ...pageProps, namespacesRequired } };
		}

		static getLayout = WrappedComponent.getLayout;

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};
}

import get from 'lodash/get';
import serviceEndpoints from '@Service-endpoints';
import { isCookieAccepted, isGTMScriptAppended, setGAConsentStatus } from '@Helpers/cookies/manageCookies';
import clearCookies from '@Helpers/cookies/DeleteGoogleMarketingCookies';
import getAppEnvironment from '@Helpers/common/getAppEnvironment';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { TrackingCookies } from '@Constants/cookies';
import { MIMETypes } from '@Constants/common';

export const initializeTagManager = () => {
	const config = { APP_ENV: getAppEnvironment() };
	const env = config.APP_ENV;
	const tagManagerId = get((serviceEndpoints as any).config[env], 'googleTagManagerId');
	const store = getOrCreateStore({});
	const { isASPUser } = store.getState().user;

	if (!isASPUser) {
		handleTagManagerConsent(tagManagerId);
	}
};

const getTagManagerFunction = (gtmId: string) => {
	return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer', '${gtmId}');`;
};

export const appendTagManagerScript = (gtmId: string) => {
	const script = document.createElement('script');
	script.innerHTML = getTagManagerFunction(gtmId);
	script.type = MIMETypes.JAVA_SCRIPT;
	script.dataset.usercentrics = 'Google Tag Manager';
	document.head.append(script);
};

export const getTagManagerId = () => {
	const config = {
		APP_ENV: getAppEnvironment(),
	};
	const env = config.APP_ENV;
	return get((serviceEndpoints as any).config[env], 'googleTagManagerId');
};

export const handleTagManagerConsent = (tagManagerId: string) => {
	const gtmTracingAccepted = isGTMScriptAppended();

	if (tagManagerId) {
		const isCookiesAccepted =
			isCookieAccepted(TrackingCookies.GA) &&
			isCookieAccepted(TrackingCookies.GAT) &&
			isCookieAccepted(TrackingCookies.GID);
		if (isCookiesAccepted && !gtmTracingAccepted) {
			setGAConsentStatus(true);
			appendTagManagerScript(tagManagerId);
		} else {
			clearCookies();
		}
	}
};

import isEmpty from 'lodash/isEmpty';
import requestData from '@Network/index';
import { toRetailer } from '@Reducers/organization/models';

export function getDealers() {
	return requestData('GET', 'DEALERS', { shouldRetry: true, useTimeZone: true })
		.then(res => {
			return (res?.data || []).map(dealer => toRetailer(dealer)).filter(dealer => !isEmpty(dealer.deliveryAddresses));
		})
		.catch(err => {
			throw err;
		});
}

export function addDealer(id) {
	return requestData('POST', 'DEALERS', { suffix: id })
		.then(res => {
			return res?.data;
		})
		.catch(err => {
			throw err;
		});
}

export function setPreferredDealerOrDeliveryAddress(data) {
	return requestData('POST', 'PREFERRED_DEALER_OR_DELIVERY_ADDRESS', { bodyData: data })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function hideDealerRequest(id) {
	return requestData('DELETE', 'HIDE_DEALER', { interpolate: { id } })
		.then(res => {
			return res?.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getRetailerOpeningHoursInfo(gssnId) {
	return requestData('GET', 'GET_RETAILER_OPENING_HOURS_INFO', { interpolate: { gssnId } })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function deleteRetailer(gssnId) {
	return requestData('DELETE', 'DELETE_RETAILER', { interpolate: { gssnId } })
		.then(res => {
			return res?.data;
		})
		.catch(err => {
			throw err;
		});
}

import { Events, ScrollIntoViewOptions, SCROLL_TRIGGER_DELAY_TIME } from '@Constants/common';

// Current Tab Activity Check

export const isTabActive = () => (typeof window === 'undefined' ? false : !document.hidden);

// Document Scroll Helpers

export { ScrollIntoViewOptions };

export const scrollToElement = (
	element: Element,
	block = ScrollIntoViewOptions.START,
	inline = ScrollIntoViewOptions.NEAREST
) => {
	element?.scrollIntoView({
		behavior: 'smooth',
		block,
		inline,
	});
};

export const scrollToCoordinates = (xCoord: number, yCoord: number) => {
	window.scrollTo(xCoord, yCoord);
};

export const scrollToOptions = (options: ScrollToOptions) => {
	window.scrollTo(options);
};

export const triggerScrollEvent = () => {
	setTimeout(() => window.dispatchEvent(new Event(Events.SCROLL)), SCROLL_TRIGGER_DELAY_TIME);
};

export const restoreScrollPosition = (routerFunction: Function) => {
	const topHeight = document.body.scrollTop || document.documentElement.scrollTop;
	routerFunction();
	scrollTo(0, topHeight);
};

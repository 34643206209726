import get from 'lodash/get';
import values from 'lodash/values';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import NextI18Next from '@Lib/i18n';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { actionTypes } from '@Reducers/modals/actionTypes';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import { UsercentricsData, UsercentricsLanguages } from '@Usercentrics/index';
import { Languages } from '@Constants/account';
import { Markets } from '@Constants/market';

export const getUCLanguage = (market: string) => {
	const language = geti18nLanguage(NextI18Next.i18n.language);
	const isChineseTraditional = market.toLowerCase() === Markets.TAIWAN && language === Languages.ZH;
	const isChineseCantonese = market.toLowerCase() === Markets.HONG_KONG && language === Languages.ZH;
	switch (true) {
		case isChineseTraditional:
			return UsercentricsLanguages.ZH_TW;
		case isChineseCantonese:
			return UsercentricsLanguages.ZH_HK;
		default:
			return language;
	}
};

export async function initializeUserCentrics() {
	const { default: Usercentrics } = await import('@usercentrics/cmp-browser-sdk');
	const store = getOrCreateStore({});
	const { userCentrics, code } = store.getState()?.configuration?.market || {};
	if (!userCentrics || !code) return;
	const options = { language: getUCLanguage(code) };
	let UC;
	const win = <any>window;
	if (typeof window !== undefined) {
		UC = new Usercentrics(userCentrics.id, options);
		await UC.init();
		win.ucInitialized = UC;
	}
	await UC.restoreUserSession(UC.getControllerId());
	const consentRequired = UC.getIsConsentRequired();
	if (consentRequired) {
		const store = getOrCreateStore({});
		store.dispatch({
			type: actionTypes.TOGGLE_USERCENTRICS_BANNER_MODAL,
			showUsercentricsBanner: true,
		});
	}
	window.postMessage('ucInitialized');
}

export async function useUsercentrics(callBack: Function) {
	const win = <any>window;
	let UC;
	if (typeof win !== 'undefined' && win.ucInitialized) {
		UC = win.ucInitialized;
		callBack(UC);
	} else if (typeof win !== 'undefined') {
		window.addEventListener('message', event => {
			if (event.data === 'ucInitialized') {
				UC = win.ucInitialized;
				callBack(UC);
			}
		});
	}
	return UC;
}

export async function getConsentModalData(callback: (ucData: UsercentricsData) => void) {
	await useUsercentrics(async userCentrics => {
		let ucData: UsercentricsData = null;
		const [categories, userConsents] = await Promise.all([
			userCentrics.getCategoriesFullInfo(),
			userCentrics.apiInstance.fetchUserConsents(),
		]);
		const settingsUI = userCentrics.getSettingsUI();
		const controllerId = userCentrics.getControllerId();
		const servicesWithConsentIds = keyBy(userConsents, 'templateId');
		const dataLabels = userCentrics.getSettingsLabels();
		const legacySettings = get(userCentrics, 'settingsV2._legacySettings');
		const servicesWithLabels = keyBy(get(dataLabels, 'services', []), 'id');
		const categoriesMerged = values(merge(keyBy(categories, 'slug'), keyBy(get(dataLabels, 'categories'), 'slug')));
		categoriesMerged.forEach(category => {
			const services = category.services.map(service => {
				return {
					controllerId,
					title: get(servicesWithLabels[service.id], 'name'),
					description: get(service, 'description'),
					id: get(service, 'id'),
					isEssential: get(service, 'isEssential'),
					status: get(service, 'consent.status'),
					consentId: get(servicesWithConsentIds[service.id], 'consentId'),
					processorId: get(service, 'processorId'),
					historyStatus: get(service, 'consent.status'),
					date: new Date().getTime(),
					processingCompany: get(service, 'processingCompany'),
					processingLocation: get(service, 'dataDistribution.processingLocation'),
					dataProcessingPurposes: get(service, 'dataPurposes'),
					technologiesUsed: get(service, 'technologiesUsed'),
					dataCollected: get(service, 'dataCollected'),
					legalBasis: get(service, 'legalBasis', []).filter(item => item),
					retentionPeriod: get(service, 'retentionPeriodDescription'),
					dataRecipients: get(service, 'dataRecipients', []).filter(item => item),
					dataProtectionOfficer: get(service, 'dataProtectionOfficer'),
					cookieMaxAgeSeconds: get(service, 'cookieMaxAgeSeconds'),
					urls: get(service, 'urls'),
					disclosures: get(service, 'deviceStorage.disclosures'),
				};
			});
			category.services = services;
		});
		ucData = {
			title: get(dataLabels, 'secondLayer.title'),
			description: get(dataLabels, 'secondLayer.description'),
			allCategoriesTitle: get(legacySettings, 'labels.btnSelectAll'),
			settingsTitle: get(legacySettings, 'labels.settings'),
			historyTitle: get(legacySettings, 'labels.history'),
			processingCompanyTitle: get(dataLabels, 'service.processingCompanyTitle'),
			processingLocationTitle: get(dataLabels, 'service.dataDistribution.processingLocationTitle'),
			dataProcessingPurposes: get(dataLabels, 'service.dataPurposes'),
			technologiesUsed: get(dataLabels, 'service.technologiesUsed'),
			dataCollected: get(dataLabels, 'service.dataCollected'),
			legalBasis: get(dataLabels, 'service.legalBasis'),
			retentionPeriod: get(dataLabels, 'service.retentionPeriod'),
			dataRecipients: get(dataLabels, 'service.dataRecipients'),
			dataProtectionOfficer: get(dataLabels, 'service.dataProtectionOfficer'),
			urls: {
				privacyPolicy: get(dataLabels, 'service.urls.privacyPolicyTitle'),
				optOut: get(dataLabels, 'service.urls.optOutTitle'),
			},
			cookieInformation: get(dataLabels, 'cookieInformation'),
			poweredBy: {
				label: get(dataLabels, 'poweredBy.label'),
				urlLabel: get(dataLabels, 'poweredBy.urlLabel'),
				url: get(settingsUI, 'poweredBy.url'),
			},
			categories: categoriesMerged,
		};
		callback(ucData);
	});
}

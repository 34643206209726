import { actionTypes } from '@Reducers/notification/actionTypes';

export const initialState = {
	showIncidentManagementBanner: true,
	incidentManagementNotificationData: [],
	leitstandNotificationsData: [],
	expiringLicenses: [],
	customNotifications: [],
};

export const notificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.TOGGLE_INCIDENT_MANAGEMENT_NOTIFICATION_BANNER:
			return {
				...state,
				showIncidentManagementBanner: action.showIncidentManagementBanner,
			};
		case actionTypes.SET_INCIDENT_MANAGEMENT_NOTIFICATION:
			return {
				...state,
				incidentManagementNotificationData: action.incidentManagementNotificationData,
				showIncidentManagementBanner: action.showIncidentManagementBanner,
			};
		case actionTypes.SET_LEITSTAND_NOTIFICATION:
			return {
				...state,
				leitstandNotificationsData: action.leitstandNotificationsData,
			};
		case actionTypes.SET_LICENSE_NOTIFICATION:
			return {
				...state,
				expiringLicenses: action.expiringLicenses,
			};
		case actionTypes.SET_CUSTOM_NOTIFICATIONS:
			return {
				...state,
				customNotifications: action.customNotifications,
			};
		default:
			return state;
	}
};

export default notificationReducer;

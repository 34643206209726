import { actionTypes } from '@Reducers/user/actionTypes';
import { TermsOfUse, TermsOfUseStatus } from '@Constants/common';
import { RmiLicenseType, OneDoCData } from './models';

export interface UserState {
	loginTriggered: boolean;
	firstName: string;
	lastName: string;
	organization: any;
	role: string[];
	isFirstLogin: boolean;
	country: string;
	termsOfUse: TermsOfUse;
	isUserLoggedIn: boolean;
	loginError;
	licenses: RmiLicenseType[];
	licensesException: boolean;
	gtmTracingAccepted: boolean;
	enableAppInsightTracing: boolean;
	oneDoCData: OneDoCData;
	digitalServiceBooklet: any;
	userId: string;
	satisfactionFeedbackScoreUserEnable: boolean;
	isASPUser: boolean;
}

export const initialUserState: UserState = {
	loginTriggered: false,
	firstName: null,
	lastName: null,
	organization: null,
	role: null,
	isFirstLogin: false,
	country: null,
	termsOfUse: {
		status: TermsOfUseStatus.ACCEPTED,
		version: null,
		expirationDate: '',
	},
	isUserLoggedIn: false,
	loginError: null,
	licenses: undefined,
	licensesException: false,
	gtmTracingAccepted: false,
	enableAppInsightTracing: false,
	oneDoCData: null,
	digitalServiceBooklet: null,
	userId: null,
	satisfactionFeedbackScoreUserEnable: false,
	isASPUser: false,
};

export const userReducer = (state = initialUserState, action) => {
	switch (action.type) {
		case actionTypes.LOGOUT:
			return {
				...state,
				user: action.user,
			};
		case actionTypes.GET_LICENSES:
			return {
				...state,
				licenses: action.licenses,
				licensesException: action.licensesException,
				digitalServiceBooklet: action.digitalServiceBooklet,
			};
		case actionTypes.GET_USER_INFO:
			return {
				...state,
				firstName: action.firstName,
				lastName: action.lastName,
				organization: action.organization,
				role: action.role,
				isFirstLogin: action.isFirstLogin,
				country: action.country,
				termsOfUse: action.termsOfUse,
				isUserLoggedIn: action.isUserLoggedIn,
				loginError: action.loginError,
			};
		case actionTypes.GET_DIGITAL_SERVICE_BOOKLET_STATUS:
			return {
				...state,
				digitalServiceBooklet: action.digitalServiceBooklet,
			};
		case actionTypes.UPDATE_TERMS_OF_USE_STATUS:
			return {
				...state,
				updateTermsOfUseLoading: action.updateTermsOfUseLoading,
				error: action.error,
			};
		case actionTypes.GET_REPUBLISHER_REQUEST_STATUS:
			return {
				...state,
				requestRepublisherStatusLoading: action.requestRepublisherStatusLoading,
				requestRepublisherStatusError: action.requestRepublisherStatusError,
				isRepublisherRoleRequested: action.isRepublisherRoleRequested,
			};
		case actionTypes.TOGGLE_SHOW_TERMS_OF_USE_MODAL:
			return {
				...state,
				loginTriggered: action.loginTriggered,
			};
		case actionTypes.SET_GA_CONSENT_STATUS:
			return {
				...state,
				gtmTracingAccepted: action.gtmTracingAccepted,
			};
		case actionTypes.SET_TRACING_CONSENT_STATUS:
			return {
				...state,
				enableAppInsightTracing: action.enableAppInsightTracing,
			};
		case actionTypes.SET_ONE_DOC_DATA:
			return {
				...state,
				oneDoCData: action.oneDoCData,
			};
		case actionTypes.GET_SATISFACTION_FEEDBACK_SCORE_USER_ENABLE:
			return {
				...state,
				satisfactionFeedbackScoreUserEnable: action.satisfactionFeedbackScoreUserEnable,
			};
		default:
			return state;
	}
};

export default userReducer;

import { Markets } from '@Constants/market';

export const MAX_COOKIE_SIZE = 0x1000;
export const ROW_MARKETS_USING_MARKETING_COOKIES = [Markets.AUSTRALIA];

export const TO_USERCENTRIC_CONSENTS_KEY = {
	'Usercentrics Consent Management Platform': 'USERCENTRIC_CMP',
	'AspNet Cookies': 'ASP_NET_COOKIES',
	'Api Jwt Cookie': 'API_JWT_COOKIE',
	'Current Language Cookie': 'CURRENT_LANGUAGE_COOKIE',
	'Consent Cookie': 'CONSENT_COOKIE',
	'Location Market Cookie': 'LOCATION_MARKET_COOKIE',
	'Site Personalization': 'SITE_PERSONALIZATION',
	'Application Insight Cookies': 'APPLICATION_INSIGHT_COOKIES',
	'User Preferred Language Cookie': 'USER_PREFERRED_LANGUAGE_COOKIE',
	'Google Tag Manager': 'GOOGLE_TAG_MANAGER',
	'Google Analytics': 'GOOGLE_ANALYTICS_COOKIE',
	'Facebook Pixel': 'FACEBOOK_PIXEL_COOKIE',
	'LinkedIn Insight Tag': 'LINKEDIN_INSIGHT_TAG',
	'LinkedIn Analytics': 'LINKEDIN_ANALYTICS_COOKIE',
	'Google Campaign Manager': 'GOOGLE_CAMPAIGN_MANAGER',
	'Facebook Social Plugins': 'FACEBOOK_SOCIAL_PLUGINS',
	'Google Marketing Products': 'GOOGLE_MARKETING_PRODUCTS',
};

export enum EssentialCookies {
	ASP_NET_CORE_COOKIES = '.AspNetCore.Cookies',
	ASP_NET_CORE_SESSION = '.AspNetCore.Session',
	API_JWT = 'apiJwt',
	NEXT_I18_NEXT = 'next-i18next',
	LOCATION_MARKET = 'location_market',
	COOKIE_CONSENT = 'CookieConsent',
	PUBLIC_LANGUAGE_SELECTION = 'publicLanguageSelection',
}

export enum FunctionalCookies {
	AI_BUFFER = 'AI_buffer',
	AI_SENT_BUFFER = 'AI_sentBuffer',
	ISP_USER_PREFERRED_LANGUAGE = 'ISP_USER_PREFERRED_LANGUAGE',
	AI_SESSION = 'ai_session',
	AI_USER = 'ai_user',
}

export enum TrackingCookies {
	GOOGLE_ANALYTICS_COOKIE = 'googleAnalytics',
	GA = '_ga',
	GID = '_gid',
	GAT = '_gat',
}

export enum MarketingCookies {
	LINKEDIN_INSIGHT_TAG = 'linkedInInsightTag',
	LINKEDIN_ANALYTICS_COOKIE = 'linkedinAnalytics',
	GOOGLE_CAMPAIGN_MANAGER = 'googleCampaignManager',
	FACEBOOK_SOCIAL_PLUGINS = 'facebookSocialPlugins',
	GOOGLE_MARKETING_PRODUCTS = 'googleMarketingProducts',
}

export enum UsercentricConsents {
	USERCENTRIC_CMP = 'Usercentrics Consent Management Platform',
	ASP_NET_COOKIES = 'AspNet Cookies',
	API_JWT_COOKIE = 'Api Jwt Cookie',
	CURRENT_LANGUAGE_COOKIE = 'Current Language Cookie',
	CONSENT_COOKIE = 'Consent Cookie',
	LOCATION_MARKET_COOKIE = 'Location Market Cookie',
	SITE_PERSONALIZATION = 'Site Personalization',
	APPLICATION_INSIGHT_COOKIES = 'Application Insight Cookies',
	USER_PREFERRED_LANGUAGE_COOKIE = 'User Preferred Language Cookie',
	GOOGLE_TAG_MANAGER = 'Google Tag Manager',
	GOOGLE_ANALYTICS_COOKIE = 'Google Analytics',
	LINKEDIN_INSIGHT_TAG = 'LinkedIn Insight Tag',
	LINKEDIN_ANALYTICS_COOKIE = 'LinkedIn Analytics',
	GOOGLE_CAMPAIGN_MANAGER = 'Google Campaign Manager',
	FACEBOOK_SOCIAL_PLUGINS = 'Facebook Social Plugins',
	GOOGLE_MARKETING_PRODUCTS = 'Google Marketing Products',
}

export enum UsercentricsCookies {
	UC_CONSENTS = 'ucConsents',
	USERCENTRICS = 'usercentrics',
	UC_SETTINGS = 'ucSettings',
}
export interface FirstLayerDescription {
	default: string;
	short: string;
	shortDesktop: string;
	shortMobile: string;
}

import { getOrCreateStore } from '@Lib/with-redux-store';

export enum LocalStorageKeys {
	DESELECTED_WEBPARTS_PRODUCTS_KEY = 'deselectedWebPartsProducts',
	DESELECTED_PACKAGE_PARTS_KEY = 'deselectedPackageParts',
	CATALOGUE_FILTER_OPTIONS_KEY = 'catalogueFilterOptions',
	SHOW_NET_PRICES = 'showNetPrices',
	SELECTED_BASKET_TYPE = 'selectedBasketType',
	MAIN_GROUP_COLLAPSE = 'main-group-collapse',
	LUCKY_DRAW_DO_NOT_REMIND = 'luckyDrawDoNotRemind',
}

export const getUserSpecificLocalStorageKey = (localStorageKey: LocalStorageKeys) => {
	const store = getOrCreateStore({});
	const userName = store?.getState()?.user?.userId || '';
	return `${localStorageKey}-${btoa(userName)}`;
};

export function setItemToLocalStorage(key: string, value: any) {
	localStorage.setItem(key, JSON.stringify(value));
}

export function getItemToLocalStorage(key: string) {
	const value = localStorage.getItem(key);
	if (value !== null) return JSON.parse(value);
	return null;
}

export const isLuckyDrawContestToBeReminded = contestId => {
	return !getItemToLocalStorage(`${LocalStorageKeys.LUCKY_DRAW_DO_NOT_REMIND}-${contestId}`);
};

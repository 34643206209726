export const actionTypes = {
	GET_CATALOGS: 'GET_CATALOGS',
	SET_CATALOGUE_VIN: 'SET_CATALOGUE_VIN',
	SET_REDIRECTION_STATUS: 'SET_REDIRECTION_STATUS',
	SET_IS_MODEL_OLD_VEHICLE: 'SET_IS_MODEL_OLD_VEHICLE',
	SET_CATALOGUE_FILTER_OPTIONS: 'SET_CATALOGUE_FILTER_OPTIONS',
	SET_SELECTED_SUBGROUP_INDEX: 'SET_SELECTED_SUBGROUP_INDEX',
	SET_NONSAVED_VEHICLE: 'SET_NONSAVED_VEHICLE',
	SET_SEARCHED_VEHICLE_STATUS: 'SET_SEARCHED_VEHICLE_STATUS',
	SET_CAMPAIGNS: 'SET_CAMPAIGNS',
	SET_SEARCH_TYPE: 'SET_SEARCH_TYPE',
	UPDATE_CATALOGUE_PATH: 'UPDATE_CATALOGUE_PATH',
	RESET_CATALOGUE_PATH: 'RESET_CATALOGUE_PATH',
};

import { createHash } from 'crypto';
import { EventApplication, EventDetail, SearchType, TagManagerEventKeys } from '@Constants/google-analytics';

export function PushDataToTagManagerForExternalUrls(
	eventName: string,
	urlKey: string,
	sourceUrl: string,
	destinationUrl: string
) {
	((window as any).dataLayer || []).push({
		event: eventName,
		urlKey,
		sourceUrl,
		destinationUrl,
	});
}

export function PushDataToTagManagerForButtons(buttonId: string, sourceUrl?: string) {
	((window as any).dataLayer || []).push({
		event: TagManagerEventKeys.BUTTON_CLICK,
		buttonId,
		sourceUrl,
	});
}

export function PushDataToTagManagerForFaqQuestions(eventName: string, questionTitle: string, sourceUrl: string) {
	((window as any).dataLayer || []).push({
		event: eventName,
		questionTitle,
		sourceUrl,
	});
}

export function PushDataToTagManagerForScrollDepth(eventName: string, scrollPath: string) {
	((window as any).dataLayer || []).push({
		event: eventName,
		scrollPath,
	});
}

export function PushDataToTagManagerForSearch(searchType: SearchType, searchTerm: string) {
	((window as any).dataLayer || []).push({
		event: TagManagerEventKeys.SEARCH,
		searchType,
		searchTerm,
	});
}

export function PushEventToDataLayer(event: string, detail?: EventDetail, application?: EventApplication) {
	(window as any).dataLayer = (window as any).dataLayer || [];
	(window as any).dataLayer.push({
		event,
		eventDetail: detail,
		application,
	});
}

export function hash(string) {
	return createHash('sha256').update(string).digest('hex');
}

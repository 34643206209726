export const actionTypes = {
	SET_CAMPAIGNS: 'SET_CAMPAIGNS',
	SET_NEWS: 'SET_NEWS',
	SET_MENU: 'SET_MENU',
	SET_NEWSLETTER: 'SET_NEWSLETTER',
	SET_FOOTER_GROUPS: 'SET_FOOTER_GROUPS',
	SET_FOOTER_MENU_EXTERNAL_URLS: 'SET_FOOTER_MENU_EXTERNAL_URLS',
	SET_FOOTER_ORDER: 'SET_FOOTER_ORDER',
	GET_SIDEBAR_CONTENT: 'GET_SIDEBAR_CONTENT',
	SET_PLATFORM_SEARCH_PREVIOUS_ROUTE: 'SET_PLATFORM_SEARCH_PREVIOUS_ROUTE',
	GET_LATEST_RELEASE: 'GET_LATESET_RELEASE',
	GET_READ_NEWS: 'GET_READ_NEWS',
	MARK_READ_NEWS: 'MARK_READ_NEWS',
	GET_LUCKY_DRAW_CONTENT: 'GET_LUCKY_DRAW_CONTENT',
	SET_STICKY_TOOLBAR_TOP_DISTANCE: 'SET_STICKY_TOOLBAR_TOP_DISTANCE',
};

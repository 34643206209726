import { combineReducers } from 'redux';
import catalogue from '@Reducers/catalogue/reducers';
import configuration from '@Reducers/configuration/reducers';
import faq from '@Reducers/faq/reducers';
import modals from '@Reducers/modals/reducers';
import notification from '@Reducers/notification/reducers';
import orders from '@Reducers/orders/reducers';
import organization from '@Reducers/organization/reducers';
import pageVisitHistory from '@Reducers/pageVisitHistory/reducers';
import sections from '@Reducers/sections/reducers';
import shop from '@Reducers/shop/reducers';
import user from '@Reducers/user/reducers';
import vehicle from '@Reducers/vehicle/reducers';
import payment from '@Reducers/payment/reducers';
import remanSearch from '@Reducers/remanSearch/reducers';

const rootReducer = combineReducers({
	catalogue,
	configuration,
	faq,
	modals,
	notification,
	orders,
	organization,
	pageVisitHistory,
	sections,
	shop,
	user,
	vehicle,
	payment,
	remanSearch,
});

export default rootReducer;

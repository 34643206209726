import { TristateSelectionStates } from '@isp/tristate-selection';
import { acceptIndividualConsent, clearIndividualConsent } from '@Helpers/cookies/manageCookies';
import { TO_USERCENTRIC_CONSENTS_KEY, UsercentricConsents } from '@Constants/cookies';

export interface CookieInformation {
	title: string;
	description: string;
	maximumAge: string;
	storedInformation: string;
	storedInformationDescription: string;
	domain: string;
	duration: string;
	type: string;
}

export interface ServiceUrls {
	privacyPolicy: string;
	optOut: string;
}

export interface ServiceSection {
	title: string;
	description: string;
}

export interface ServiceDisclosure {
	name: string;
	description: string;
	domain: string;
	maxAgeSeconds: number;
	type: string;
}

export interface ServiceProcessingCompany {
	name: string;
	address: string;
}

export interface PoweredByUsercentrics {
	label: string;
	urlLabel: string;
	url: string;
}

export interface UsercentricsService {
	id: string;
	isEssential: boolean;
	status: boolean;
	title: string;
	description: string;
	consentId: string;
	controllerId: string;
	processorId: string;
	date: string;
	historyStatus: boolean;
	processingCompany: ServiceProcessingCompany;
	dataProcessingPurposes: string[];
	technologiesUsed: string[];
	dataCollected: string[];
	legalBasis: string[];
	processingLocation: string;
	retentionPeriod: string;
	dataRecipients: string[];
	dataProtectionOfficer: string;
	urls: ServiceUrls;
	cookieMaxAgeSeconds: number;
	disclosures: ServiceDisclosure[];
}

export interface UsercentricsCategory {
	label: string;
	description: string;
	services: UsercentricsService[];
}

export interface UsercentricsData {
	title: string;
	description: string;
	allCategoriesTitle: string;
	settingsTitle: string;
	historyTitle: string;
	processingCompanyTitle: string;
	processingLocationTitle: string;
	dataProcessingPurposes: ServiceSection;
	technologiesUsed: ServiceSection;
	dataCollected: ServiceSection;
	legalBasis: ServiceSection;
	retentionPeriod: ServiceSection;
	dataRecipients: ServiceSection;
	dataProtectionOfficer: ServiceSection;
	urls: ServiceUrls;
	cookieInformation: CookieInformation;
	poweredBy: PoweredByUsercentrics;
	categories: UsercentricsCategory[];
}

export interface CookieStatus {
	[key: string]: TristateSelectionStates;
}

export interface ItemStatus {
	[key: string]: boolean;
}

export const cookieConsentBistateNextStatus = {
	[TristateSelectionStates.CHECKED]: TristateSelectionStates.EMPTY,
	[TristateSelectionStates.INDETERMINATE]: TristateSelectionStates.CHECKED,
	[TristateSelectionStates.EMPTY]: TristateSelectionStates.CHECKED,
};

export enum UsercentricsLanguages {
	ZH_TW = 'zh_tw',
	ZH_HK = 'zh_hk',
}

export const determineTristateStatus = (isChecked = false, isEmpty = false) => {
	switch (true) {
		case isChecked && isEmpty:
			return TristateSelectionStates.INDETERMINATE;
		case isChecked && !isEmpty:
			return TristateSelectionStates.CHECKED;
		default:
			return TristateSelectionStates.EMPTY;
	}
};

export const isCategoryDisabled = (category: UsercentricsCategory) => {
	let atLeastOneEssential: boolean;
	let atLeastOneOptional: boolean;

	category.services.forEach((service: UsercentricsService) => {
		if (service.isEssential) atLeastOneEssential = true;
		else atLeastOneOptional = true;
	});

	return atLeastOneEssential && !atLeastOneOptional;
};

export const changeConsentOnLocalStorage = (service: UsercentricsService, serviceStatus: boolean) => {
	const serviceConsentKey = UsercentricConsents[TO_USERCENTRIC_CONSENTS_KEY[service.title]];
	if (serviceStatus) acceptIndividualConsent(serviceConsentKey);
	else clearIndividualConsent(serviceConsentKey);
};

export const getUserDecisions = (categories: UsercentricsCategory[], serviceStatuses: CookieStatus) => {
	const userDecisions = [];
	categories.forEach((category: UsercentricsCategory) => {
		category.services.forEach((service: UsercentricsService) => {
			const serviceStatus = serviceStatuses[service.title] === TristateSelectionStates.CHECKED;
			userDecisions.push({
				serviceId: service.id,
				status: serviceStatus,
			});
			changeConsentOnLocalStorage(service, serviceStatus);
		});
	});

	return userDecisions;
};

import { TermsOfUseStatus } from '@Constants/common';

export const EDIT_USER_SAVE_TIMEOUT = 10000;
export const ADMIN_RIGHTS_DELAY = 3000;
export const SATISFACTION_FEEDBACK_SCORE_FACE_INTERVAL = 10000;
export const SATISFACTION_FEEDBACK_SCORE_FACE_ANIMATION = 1500;
export const SATISFACTION_FEEDBACK_SCORE_FACE_MODEL_DELAY = 1700;
export const TERMS_CONDITIONS_MODAL_MAX_LEFTOVER_COUNT = 42;
export const REDIRECTED_QUERY_PARAM = 'redirected';

export enum UserRoles {
	ASP = 'ASP',
	ISP = 'ISP',
	ADMIN = 'ADMIN',
	REPUBLISHER = 'REPUBLISHER',
	EMPLOYEE = 'EMPLOYEE',
}

export enum AdminRightStates {
	CONTENT = 'CONTENT',
	ERROR = 'ERROR',
	LOADING = 'LOADING',
	SUCCESS = 'SUCCESS',
}

export const USER_ROLE_TRANSLATIONS = {
	[UserRoles.ADMIN]: 'account:admin-role',
	[UserRoles.EMPLOYEE]: 'account:employee-role',
};

export enum EditUserEvents {
	QUIT = 'REGISTRATION_QUIT',
	COMPLETE = 'REGISTRATION_COMPLETE',
}

export const TERMS_CONDITIONS_MODAL_FORCE_ON_REFRESH_STATES = [
	TermsOfUseStatus.FORCE_ACCEPT,
	TermsOfUseStatus.NOT_ACCEPTED,
];
export const TERMS_CONDITIONS_MODAL_ACCEPT_LATER_STATES = [
	TermsOfUseStatus.NOT_ACCEPTED,
	TermsOfUseStatus.POSTPONED,
	TermsOfUseStatus.ACCEPTED,
];

export enum COMPANY_DATA_TRANSLATIONS {
	NAME = 'account:organization-name',
	ID = 'account:organization-id',
	TYPE = 'account:organization-type',
	TAX_NO = 'account:organization-tax-no',
}

export enum COMPANY_DETAIL_TRANSLATIONS {
	ADRESS = 'address:main-address',
	EMAIL = 'common:email',
	WEBSITE = 'account:organization-website',
}

export enum SatisfactionScoreFaces {
	FRUSTRATED = 'FRUSTRATED',
	DISAPPOINTED = 'DISAPPOINTED',
	UNSATISFIED = 'UNSATISFIED',
	NEUTRAL = 'NEUTRAL',
	SATISFIED = 'SATISFIED',
	HAPPY = 'HAPPY',
	EXCITED = 'EXCITED',
}

import { Retailer } from '@Reducers/organization/models';
import get from 'lodash/get';
import { WebPartsStatuses, DealerStatuses } from '@Constants/retailer';

export const GetWebPartsStatus = (dealers: Retailer[]): WebPartsStatuses => {
	let pendingCount = 0;
	let approvedCount = 0;

	for (let i = 0; i < get(dealers, 'length', 0); i += 1) {
		const dealer = dealers[i];
		switch (dealer.status) {
			case DealerStatuses.APPROVED:
				if (dealer.isPreferred) return WebPartsStatuses.APPROVED_AND_PREFERRED_RETAILER_EXISTS;
				approvedCount += 1;
				break;
			case DealerStatuses.PENDING:
				pendingCount += 1;
				break;
			default:
				break;
		}
	}

	if (approvedCount > 0) return WebPartsStatuses.APPROVED_AND_NO_PREFERRED_RETAILER;
	if (pendingCount > 0) return WebPartsStatuses.RETAILER_APPROVAL_PENDING;

	return WebPartsStatuses.NOT_ACCEPTED;
};

export const CheckWebPartsDealerStatus = (dealers: Retailer[]) => {
	const webPartsStatus = GetWebPartsStatus(dealers);
	const isWebpartsInactive = webPartsStatus === WebPartsStatuses.NOT_ACCEPTED;
	const isWebpartsPending = webPartsStatus === WebPartsStatuses.RETAILER_APPROVAL_PENDING;

	return { isWebpartsInactive, isWebpartsPending };
};

import get from 'lodash/get';
import { useTranslation } from '@Lib/i18n';
import Search from '@isp/search';
import '@Partials/Equipment/Search/index.scss';
import useRecentQueries from '@Helpers/hooks/useRecentQueries';
import {
	EQUIPMENT_DEFAULT_PAGE_SIZE,
	EQUIPMENT_DEFAULT_PAGE_NUMBER,
	MIN_LENGTH_EQUIPMENT_SEARCH_TERM,
} from '@Constants/product/equipment';
import { SearchHistoryTypes } from '@Constants/common';

const SearchEquipment = ({ translate, getCatalogueProducts }) => {
	const { t } = useTranslation(['vehicles']);
	const { recentQueries, fetchRecentQueries, postRecentQuery } = useRecentQueries(SearchHistoryTypes.EQUIPMENT);

	const searchEquipment = (value: string) => {
		if (value.trim() && get(value, 'length', 0) >= MIN_LENGTH_EQUIPMENT_SEARCH_TERM) {
			postRecentQuery(value);
			getCatalogueProducts(EQUIPMENT_DEFAULT_PAGE_NUMBER, EQUIPMENT_DEFAULT_PAGE_SIZE, value)?.then(fetchRecentQueries);
		}
	};

	const getAllEquipment = () => {
		getCatalogueProducts(EQUIPMENT_DEFAULT_PAGE_NUMBER, EQUIPMENT_DEFAULT_PAGE_SIZE, null);
	};

	return (
		<Search
			className="search-equipment"
			inputPlaceholder={translate('shop:equipment-search-item-number')}
			onSearch={searchEquipment}
			onClear={getAllEquipment}
			onFocusOut={getAllEquipment}
			recentQueriesLabel={t('vehicles:recent-queries')}
			recentQueries={recentQueries}
		/>
	);
};

export default SearchEquipment;

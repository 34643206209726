import requestData from '@Network/index';
import { MIMETypes } from '@Constants/common';

export function getOrganizationUsers() {
	return requestData('GET', 'ORGANIZATION_USERS')
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getOrganizationType() {
	return requestData('GET', 'ORGANIZATION_TYPE')
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getOrganization() {
	return requestData('GET', 'ORGANIZATION')
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function clearOrganizationCache() {
	return requestData('DELETE', 'ORGANIZATION_CACHE')
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function editOrganizationInfo(formData) {
	return requestData('PUT', 'ORGANIZATION', { formData, timeout: 300 }, MIMETypes.MULTIPART)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function assignAdminRight(id) {
	return requestData('POST', 'ORGANIZATION_ASSIGN_ADMIN', { interpolate: { userId: id } })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function revokeAdminRight(id) {
	return requestData('DELETE', 'ORGANIZATION_REVOKE_ADMIN', { interpolate: { userId: id } })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function updateOrganizationMailAddress(mailAdress) {
	return requestData('PUT', 'UPDATE_ORGANIZATION_MAIL_ADDRESS', { data: mailAdress })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getConsentSettings(fin) {
	return requestData('GET', 'CONSENT_AND_PARTS_ORDER', { interpolate: { fin } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function setVehicleConsentSettings(finOrVin) {
	return requestData('POST', 'CONSENT_SETTINGS', { bodyData: finOrVin })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

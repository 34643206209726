import get from 'lodash/get';
import { ExternalURLKeys, GetExternalURL } from '@Helpers/ExternalURLs';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { SET_ONE_DOC_DATA } from '@Reducers/user/actions';
import { TOGGLE_ONE_DOC_INITIAL_MODAL } from '@Reducers/modals/actions';
import requestData from '@Network/index';
import { isLoggedIn } from '@User-operations';
import ONE_DOC_LANGUAGES, { ONE_DOC_FALLBACK_LANGUAGES } from '@Shared/modals/OneDoC/languages';
import { SpaConstants, SpaParams, SpaPhoneType } from '@Constants/account';

export const parseOneDocEventData = (e: MessageEvent) => {
	const start = e.data.indexOf('(');
	const end = e.data.lastIndexOf(')');
	if (start !== -1 && end !== -1) {
		const method = e.data.substring(0, start);
		const jsonString = e.data.substring(start + 1, end);
		let payload;
		try {
			payload = JSON.parse(jsonString);
		} catch {
			payload = '';
		}
		return { method, payload };
	}
	return {};
};

export const getOneDocLanguage = (language, market) => {
	const marketLanguage = `${language}-${market.toUpperCase()}`;
	return ONE_DOC_LANGUAGES.includes(marketLanguage) ? marketLanguage : ONE_DOC_FALLBACK_LANGUAGES[language];
};

export const getOneDocConfiguration = (t, market, oneDocLanguage, userInfo) => {
	const { oneDoCData } = userInfo;
	const legalEntity = GetExternalURL(ExternalURLKeys.ONE_DOC_LEGAGL_ENTITY);

	const configuration = {};

	configuration[SpaParams.SYSTEM] = SpaConstants.ISP_CONNECT_SYSTEM;
	configuration[SpaParams.MARKET] = market.toUpperCase();
	configuration[SpaParams.LEGAL_ENTITY] = legalEntity;
	configuration[SpaParams.LANGUAGE_CODE] = oneDocLanguage;
	configuration[SpaParams.LOCATION] = `${oneDocLanguage}-${market.toUpperCase()}`;
	configuration[SpaParams.CONSENT_VARIANT] = SpaConstants.DOC_ONLINE_OPT_TIER_2;
	configuration[SpaParams.DIALOG_SUGGESTION] = '0';
	configuration[SpaParams.PHONE_TYPE] = SpaPhoneType.LANDLINE;
	configuration[SpaParams.THEME_NAME] = SpaConstants.ONEWEB_THEME;
	configuration[SpaParams.NEXT_BUTTON_LABEL] = t('submit');
	configuration[SpaParams.SKIP_BUTTON_LABEL] = t('cancel');

	configuration[SpaParams.FIRST_NAME] = get(oneDoCData, 'firstName');
	configuration[SpaParams.LAST_NAME] = get(oneDoCData, 'lastName');
	configuration[SpaParams.MAIL_ADDRESS] = get(oneDoCData, 'email');

	if (get(oneDoCData, 'phoneNumber')) {
		configuration[SpaParams.PHONE_TYPE] = SpaConstants.PHONE_TYPE_MOBILE;
		configuration[SpaParams.PHONE_NUMBER] = get(oneDoCData, 'phoneNumber');
	}
	return configuration;
};

export const handleOneDoC = () => {
	if (!isLoggedIn()) {
		return;
	}
	requestData('GET', 'CONSENT_DIALOG').then(res => {
		const { data } = res;
		if (data.shouldConsentsAsk) {
			const store = getOrCreateStore({});
			store.dispatch(SET_ONE_DOC_DATA(data.customer));
			store.dispatch(TOGGLE_ONE_DOC_INITIAL_MODAL());
		}
	});
};

/* eslint-disable */
const NAMESPACES = [
	'common',
	'account',
	'address',
	'footer',
	'home',
	'login',
	'orders',
	'products',
	'retailers',
	'rmi',
	'support',
	'users',
	'vehicles',
	'workshop-solutions',
	'dashboard',
	'shop',
	'technical-data',
];

module.exports = NAMESPACES;

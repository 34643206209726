import { isEmpty } from 'lodash';
import { toFormattedPrice } from '@isp/price';
import requestData from '@Network/index';
import {
	DEFAULT_VEHICLE_LIST_PAGE_INDEX,
	DEFAULT_VEHICLE_LIST_PAGE_SIZE,
	VehicleListFilterType,
} from '@Constants/vehicle';
import { initialCatalogueFilterOptionsState } from '@Constants/webparts';

export function getFilteredVehicleList(
	filter: VehicleListFilterType,
	pageIndex: number = DEFAULT_VEHICLE_LIST_PAGE_INDEX,
	pageSize: number = DEFAULT_VEHICLE_LIST_PAGE_SIZE
) {
	return requestData('GET', 'FILTERED_VEHICLE_LIST', { interpolate: { pageIndex, pageSize, filter } })
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw err;
		});
}

export function getUserVehiclesOrPart(options) {
	return requestData('GET', 'VEHICLE_AND_PART_SEARCH', { data: options, useTimeZone: true })
		.then(resp => {
			const { vehicleResult, partInfoResult } = resp;
			if (!isEmpty(partInfoResult?.data?.partList)) {
				partInfoResult.data.partList = partInfoResult?.data?.partList.map(item => {
					const { specialOffer, listPricePerUnit, netPricePerUnit, coreValuePerUnit } = item.price || {};
					const { specialOfferDiscountAbsolute } = specialOffer || {};
					return {
						...item,
						price: {
							...item.price,
							listPricePerUnit: toFormattedPrice(listPricePerUnit),
							netPricePerUnit: toFormattedPrice(netPricePerUnit),
							coreValuePerUnit: toFormattedPrice(coreValuePerUnit),
							specialOffer: !isEmpty(specialOffer) && {
								...specialOffer,
								specialOfferDiscountAbsolute: `- ${toFormattedPrice(specialOfferDiscountAbsolute)}`,
							},
						},
					};
				});
			}
			return { vehicleResult, partInfoResult };
		})
		.catch(err => {
			throw err;
		});
}

export function getSearchedPart(
	partText: string,
	fin: string,
	preferredLanguage: string,
	filterOptions = initialCatalogueFilterOptionsState,
	orderType: string
) {
	return requestData('GET', 'PART_SEARCH_BY_VIN', {
		interpolate: { fin },
		data: { partText, isSearch: true, ...filterOptions, orderType },
		preferredLanguage,
	})
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw err;
		});
}
export function getVinPart(
	partText: string,
	fin: string,
	preferredLanguage: string,
	filterOptions = initialCatalogueFilterOptionsState,
	orderType: string,
	modelId: string
) {
	const queryParams = {
		fin,
		orderType,
		partText,
		...filterOptions,
		modelId,
	};
	return requestData('GET', 'PART_SEARCH', {
		data: queryParams,
		preferredLanguage,
	})
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw err;
		});
}

export function getVehicleInfo(vin: string, isDatacardExist: boolean) {
	return requestData('GET', 'VEHICLE_INFO', { interpolate: { vin }, data: { datacardExists: isDatacardExist } })
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw err;
		});
}

export function getVehicleVinValidation(vin: string) {
	return requestData('GET', 'VEHICLE_VIN_VALIDATION', { interpolate: { vin } })
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw err;
		});
}

export function saveVehicle(
	vin: string,
	bodyData: {
		plateNumber: string;
		customerFullName: string;
	}
) {
	return requestData('POST', 'ORGANIZATION_VEHICLES', { suffix: vin, bodyData }) // NEW
		.then(resp => {
			return resp.data;
		})
		.catch(err => {
			throw err;
		});
}

export function deleteVehicle(vin) {
	return requestData('DELETE', 'ORGANIZATION_VEHICLES', { suffix: vin }) // NEW
		.then(resp => {
			return resp.data;
		})
		.catch(err => {
			throw err;
		});
}

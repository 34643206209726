import get from 'lodash/get';
import storage from '@Storage';
import serviceEndpoints from '@Service-endpoints';
import requestData from '@Network/index';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { LOGOUT } from '@Reducers/user/actions';
import getAppEnvironment from '@Helpers/common/getAppEnvironment';
import {
	clearCacheNewFeature,
	clearSessionStorageLuckyDrawDisplayed,
	clearStorageBasketSelection,
	clearStorageIncidentManagementBanner,
} from '@Helpers/storage';
import { OPEN_REGISTRATION } from '@Reducers/modals/actions';
import { userSettingsDb } from '@Dexie/userSettingsDB';
import { EssentialCookies } from '@Constants/cookies';
import { Patterns } from '@Constants/common';

const config = {
	APP_ENV: getAppEnvironment(),
};

export function isLoggedIn() {
	const store = getOrCreateStore({});
	return store.getState().user.isUserLoggedIn;
}

export function getAuthURL() {
	const env = config.APP_ENV;
	return get((serviceEndpoints as any).config[env], 'authUrl');
}

export function getLogoutURL() {
	const env = config.APP_ENV;
	return get((serviceEndpoints as any).config[env], 'logoutUrl');
}

export function getToken(cookie?: string) {
	const token = storage.getCookie(EssentialCookies.API_JWT, cookie);
	return token || '';
}

export function getUserInfo(cookie = null) {
	let options = {};
	const token = cookie === null ? getToken() : cookie;
	options = {
		customHeaders: {
			Authorization: `Bearer ${token}`,
		},
	};

	return requestData('GET', 'USER_INFO', options)
		.then(res => {
			const response = {
				...res.data,
				isUserLoggedIn: true,
				loginError: null,
			};
			return response;
		})
		.catch(err => {
			throw err;
		});
}

export function register() {
	const store = getOrCreateStore({});
	return store.dispatch(OPEN_REGISTRATION());
}

export function getLoginURL(returnURL?) {
	const authUrl = getAuthURL();
	const url = `${authUrl}?returnURL=${returnURL || window.location.pathname + window.location.search}`;

	return url;
}

export function login(returnURL?) {
	const loginURL = getLoginURL(returnURL);
	const isProductPage = window.location.pathname.match(Patterns.PRODUCTS_PAGE);

	if (window.location.hash.length > 0 && isProductPage) {
		window.location.href = loginURL.concat(`&hash=${window.location.hash.slice(1)}`);
	} else {
		window.location.href = loginURL;
	}
}

export function logout() {
	const store = getOrCreateStore({});
	store.dispatch(LOGOUT());
	clearStorageIncidentManagementBanner();
	clearCacheNewFeature();
	clearStorageBasketSelection();
	clearSessionStorageLuckyDrawDisplayed();

	userSettingsDb.userSettingsData.clear();

	const logoutUrl = getLogoutURL();
	window.location.href = logoutUrl;
}

export function updateTermsOfUseStatus({ version, status }) {
	return requestData('POST', 'UPDATE_TERMS_OF_USE_STATUS', { interpolate: { status, version } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getRepublisherRequestStatus() {
	return requestData('GET', 'REPUBLISHER_REQUEST_STATUS')
		.then(res => res)
		.catch(err => {
			throw err;
		});
}

export function requestRepublisherRole() {
	return requestData('POST', 'REQUEST_REPUBLISHER_ROLE')
		.then(res => res)
		.catch(err => {
			throw err;
		});
}

export function verifyTwoFactorAuthentication(email, authenticationCode, token) {
	return requestData('POST', 'VERIFY_TWO_FACTOR_AUTHENTICATION', {
		bodyData: { email, twoFactorCode: authenticationCode, token },
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getPersonalSettings() {
	return requestData('GET', 'PERSONAL_SETTINGS')
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function updatePersonalSettings(data) {
	return requestData('PUT', 'PERSONAL_SETTINGS', {
		bodyData: data,
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function createUserForPersonalSettings(data) {
	return requestData('POST', 'PERSONAL_SETTINGS', {
		bodyData: data,
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getSatisfactionFeedbackScoreUserEnable() {
	return requestData('GET', 'SATISFACTION_FEEDBACK_SCORE_USER_ENABLE')
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function createSatisfactionFeedbackScore(data) {
	return requestData('POST', 'SATISFACTION_FEEDBACK_SCORE', {
		bodyData: data,
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

import noop from 'lodash/noop';
import { EventName, EventPurpose, LogReason, LogRelation, SeverityLevel } from '@Constants/logging';

interface EventTrackingProperties {
	id?: string;
	msg?: string;
	path?: string;
	level?: SeverityLevel;
}
interface TraceTrackingProperties {
	id?: string;
	reason?: LogReason;
	description?: string | Object;
	relation?: LogRelation;
	market?: string;
	language?: string;
}

export const trackEvent = (purpose: EventPurpose[], eventName: EventName, properties?: EventTrackingProperties) => {
	noop(purpose, eventName, properties);
	/* if ((config.APP_ENV || '').toUpperCase() !== AppConfig.ENV.LOCAL) {
		const store = getOrCreateStore({});
		const { enableAppInsightTracing } = get(store.getState(), 'user') || false;
		if (!enableAppInsightTracing) return;
		if (purpose.includes(EventPurpose.APPLICATION_INSIGHTS)) ai.trackEvent({ name: eventName, properties });
		// if(purpose.includes(EventPurpose.APPLICATION_INSIGHTS))
		// TODO: Google Analytics tracking
	} */
};

export const trackTrace = (message: string, level: SeverityLevel, properties?: TraceTrackingProperties) => {
	noop(message, level, properties);
	/* if ((config.APP_ENV || '').toUpperCase() !== AppConfig.ENV.LOCAL) {
		const store = getOrCreateStore({});
		const { enableAppInsightTracing } = get(store.getState(), 'user') || false;
		if (!enableAppInsightTracing) return;
		ai.trackTrace({ message, severityLevel: level }, properties);
	} */
};

export const actionTypes = {
	SET_COUNTRY_INFO: 'SET_COUNTRY_CURRENCY_INFO',
	SET_SEARCHED_PRODUCT_WITH_PART_NUMBER: 'SET_SEARCHED_PRODUCT_WITH_PART_NUMBER',
	SET_SEARCHED_PRODUCT_WITH_VIN_NUMBER: 'SET_SEARCHED_PRODUCT_WITH_VIN_NUMBER',
	CLEAR_SEARCHED_PRODUCT: 'CLEAR_SEARCHED_PRODUCT',
	SET_VEHICLE_TYPE: 'SET_VEHICLE_TYPE',
	SET_SEARCHED_ENGINES: 'SET_SEARCHED_ENGINES',
	SET_REMAN_SCREEN_TYPE: 'SET_REMAN_SCREEN_TYPE',
	SET_PRODUCT_CATEGORY_LIST: 'SET_PRODUCT_CATEGORY_LIST',
	SET_VIN_CATEGORY_TITLE: 'SET_VIN_CATEGORY_TITLE',
	SET_BREADCRUMB_ENGINE_ID: 'SET_BREADCRUMB_ENGINE_ID',
};

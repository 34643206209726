import { actionTypes } from '@Reducers/catalogue/actionTypes';
import { CatalogueState } from '@Reducers/catalogue/models';
import { PartSearchType, initialCatalogueFilterOptionsState } from '@Constants/webparts';
import { SearchedVehicleStatus } from '@Constants/catalog';

export const initialCatalogueState: CatalogueState = {
	catalogs: null,
	aggregates: null,
	catalogueVIN: null,
	productClassId: null,
	selectedMainGroupOptions: null, // TODO: rename
	isModelOldVehicle: null,
	productIds: null, // TODO: rename
	catalogError: null,
	catalogueFilterOptions: initialCatalogueFilterOptionsState,
	subGroupIndex: null,
	nonSavedVehicleInformation: null,
	searchedVehicleStatus: SearchedVehicleStatus.NONE,
	campaigns: null,
	searchType: PartSearchType.WITHOUT_VIN,
	vehicleDataCardAvailable: false,
	path: null,
};

export const catalogueReducer = (state = initialCatalogueState, action) => {
	switch (action.type) {
		case actionTypes.SET_CAMPAIGNS:
			return {
				...state,
				campaigns: action.campaigns,
			};
		case actionTypes.SET_SEARCHED_VEHICLE_STATUS:
			return {
				...state,
				searchedVehicleStatus: action.searchedVehicleStatus,
			};
		case actionTypes.SET_NONSAVED_VEHICLE:
			return {
				...state,
				nonSavedVehicleInformation: action.nonSavedVehicleInformation,
			};
		case actionTypes.SET_SEARCH_TYPE:
			return {
				...state,
				searchType: action.searchType,
			};
		case actionTypes.GET_CATALOGS:
			return {
				...state,
				vehicleDataCardAvailable: action.vehicleDataCardAvailable,
				catalogs: action.catalogs,
			};
		case actionTypes.SET_IS_MODEL_OLD_VEHICLE:
			return {
				...state,
				isModelOldVehicle: action.isModelOldVehicle,
			};
		case actionTypes.SET_CATALOGUE_VIN:
			return {
				...state,
				catalogueVIN: action.catalogueVIN,
			};
		case actionTypes.SET_CATALOGUE_FILTER_OPTIONS:
			return {
				...state,
				catalogueFilterOptions: action.catalogueFilterOptions,
			};
		case actionTypes.SET_SELECTED_SUBGROUP_INDEX:
			return {
				...state,
				subGroupIndex: action.subGroupIndex,
			};
		case actionTypes.RESET_CATALOGUE_PATH:
		case actionTypes.UPDATE_CATALOGUE_PATH:
			return {
				...state,
				path: action.path,
			};
		default:
			return state;
	}
};

export default catalogueReducer;

import has from 'lodash/has';
import { ISO_MAPPING } from '@Constants/umbraco';

export const setCulture = (market: string, language: string): string => {
	const isNativeLanguage = market.toLowerCase() === language;
	const acceptLanguage = isNativeLanguage || language.includes('-') ? language : `${language}-${market.toUpperCase()}`;
	const culture = has(ISO_MAPPING, acceptLanguage) ? ISO_MAPPING[acceptLanguage] : acceptLanguage;

	return culture;
};

import { useEffect } from 'react';
import { Events } from '@Constants/common';

export interface UseEventOptions {
	element?: HTMLElement;
	init?: boolean;
	data?: any;
}

const useEvent = (event, callback = null, options: UseEventOptions = null) => {
	const { element = null, init = false } = options || {};
	useEffect(() => {
		(element || window).addEventListener(event, callback);
		if (init) callback();
		return () => {
			(element || window).removeEventListener(event, callback);
		};
	});
};

export const useScroll = (callback, options: UseEventOptions = null) => useEvent(Events.SCROLL, callback, options);
export const useResize = (callback, options: UseEventOptions = null) => useEvent(Events.RESIZE, callback, options);
export default useEvent;

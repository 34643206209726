import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import Container from '@isp/container';
import Loading, { LoadingSizes } from '@isp/loading';
import Icon, { IconType, IconSize, IconVariant } from '@isp/icon';
import Pagination, { PaginationTheme } from '@isp/pagination';
import EquipmentItem from '@Partials/Equipment/Catalogue/EquipmentItem';
import SadCarError, { ErrorAction, ErrorAwareness, ErrorIntroduction, ErrorScenario } from '@Shared/error/SadCarError';
import { EquipmentCatalogueData } from '@Apis/shop';
import { EQUIPMENT_DEFAULT_PAGE_SIZE } from '@Constants/product/equipment';
import '@Partials/Equipment/Catalogue/index.scss';

interface EquipmentCatalogueProps {
	searchKeyword: string;
	catalogueData: EquipmentCatalogueData;
	catalogueProductsError: boolean;
	catalogueProductsLoading: boolean;
	getCatalogueProducts(pageNumber: number): void;
}

const EquipmentCatalogue: FC<EquipmentCatalogueProps> = ({
	searchKeyword,
	catalogueData,
	getCatalogueProducts,
	catalogueProductsError,
	catalogueProductsLoading,
}) => {
	const { t } = useTranslation(['common', 'account', 'shop']);
	const showNoResult = catalogueData && searchKeyword && isEmpty(catalogueData.equipments);
	const showCatalogue =
		catalogueData && !catalogueProductsError && !catalogueProductsLoading && !isEmpty(catalogueData.equipments);

	const xentryPurchaseAuthorization = catalogueData ? catalogueData.purchaseAllowed : true;

	return (
		<div className="bg-white-smoke">
			<Container className="p-t-10 p-b-10">
				{catalogueProductsError && (
					<div className="catalogue-error m-t-6">
						<SadCarError
							intro={ErrorIntroduction.WAS_NOT_INTENTION}
							scenario={ErrorScenario.WORKSHOP_EQUIPMENT_ERROR}
							awareness={ErrorAwareness.WORKING_ON_IT}
							action={ErrorAction.TRY_AGAIN}
						/>
					</div>
				)}
				{catalogueProductsLoading && (
					<div className="text-center m-t-6">
						<Loading size={LoadingSizes.TINY} text={t('common:loading-data')} />
					</div>
				)}
				{showCatalogue && (
					<div className="equipment-catalogue m-t-6">
						<div className="equipment-catalogue__header color-boulder m-b-1">
							<div />
							<div>{t('shop:equipment-name')}</div>
							<div>{t('shop:equipment-item-number')}</div>
							<div>{t('shop:equipment-availability')}</div>
							<div />
							<div className="equipment-catalogue__header__price no-white-space">{t('shop:equipment-price')}</div>
							<div />
						</div>
						<div className="equipment-catalogue__list bg-white">
							{catalogueData.equipments.map((equipment, index) => (
								<EquipmentItem
									key={index}
									id={index}
									equipment={equipment}
									authorizationDisabled={!xentryPurchaseAuthorization}
								/>
							))}
						</div>
						<Pagination
							theme={PaginationTheme.LIGHT}
							itemPerPage={EQUIPMENT_DEFAULT_PAGE_SIZE}
							className="equipment-catalogue disp-flex train__column train__center m-t-5"
							forcePage={catalogueData.activePageNumber}
							totalCount={catalogueData.totalCount}
							onPageChange={page => getCatalogueProducts(page)}
						/>
					</div>
				)}
				{showNoResult && (
					<div className="text-center m-t-6 color-boulder">
						<Icon type={IconType.SEARCH} size={IconSize.SMALLER} variant={IconVariant.VERYLIGHTPINK2} />
						<p>{t('shop:equipment-search-no-result', { searchKeyword })}</p>
					</div>
				)}
			</Container>
		</div>
	);
};

export default EquipmentCatalogue;

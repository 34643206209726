import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import rootReducer from '@Reducers/index';

export function initializeStore(initialState = {}) {
	return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)));
}

export type RootState = ReturnType<typeof rootReducer>;

const store = initializeStore();
type AppAction = ReturnType<typeof store.dispatch>;
export type AppDispatch = ThunkDispatch<RootState, any, AppAction>;

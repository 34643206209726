import get from 'lodash/get';
import { toFormattedPrice } from '@isp/price';
import { actionTypes } from '@Reducers/organization/actionTypes';
import {
	getDealers,
	addDealer,
	setPreferredDealerOrDeliveryAddress,
	hideDealerRequest,
	deleteRetailer,
} from '@Apis/dealers';
import {
	getOrganizationUsers,
	getOrganization,
	assignAdminRight,
	revokeAdminRight,
	updateOrganizationMailAddress,
	getConsentSettings,
	getOrganizationType,
} from '@Apis/organization';
import { CheckWebPartsDealerStatus } from '@Helpers/webparts/CheckWebPartsDealerStatus';
import { OrganizationTypes } from '@Constants/account';
import { ResponseCode } from '@Constants/common';

export const SET_DEALER_ACCORDION = selectedRetailerID => dispatch => {
	dispatch({
		type: actionTypes.SET_DEALER_ACCORDION,
		selectedRetailerID,
	});
};

export const GET_ORGANIZATION_DEALERS = () => (dispatch, getState) => {
	const { organization } = getState();
	dispatch({
		type: actionTypes.GET_ORGANIZATION_DEALERS,
		dealers: organization.dealers,
		dealersLoading: true,
		dealersError: false,
	});
	return getDealers()
		.then(dealers => {
			const { isWebpartsInactive, isWebpartsPending } = CheckWebPartsDealerStatus(dealers);
			dispatch({
				type: actionTypes.GET_ORGANIZATION_DEALERS,
				dealers: dealers || [],
				dealersWebpartsInactive: isWebpartsInactive,
				dealersWebpartsPending: isWebpartsPending,
				dealersLoading: false,
				dealersError: false,
			});
			return dealers;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_ORGANIZATION_DEALERS,
				dealers: null,
				dealersLoading: false,
				dealersError: true,
			});
			throw err;
		});
};

export const ADD_ORGANIZATION_DEALER = id => dispatch => {
	return addDealer(id)
		.then(res => {
			dispatch({
				type: actionTypes.ADD_ORGANIZATION_DEALER,
				error: null,
			});
			dispatch(GET_ORGANIZATION_DEALERS());
			return res;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.ADD_ORGANIZATION_DEALER,
				error: err,
			});
			throw err;
		});
};

export const HIDE_ORGANIZATION_DEALER_REQUEST = id => dispatch => {
	dispatch({
		type: actionTypes.HIDE_ORGANIZATION_DEALER_REQUEST,
		hideDealerLoading: true,
		selectedDealerToHide: id,
	});
	return hideDealerRequest(id)
		.then(res => {
			dispatch({
				type: actionTypes.HIDE_ORGANIZATION_DEALER_REQUEST,
			});
			dispatch(GET_ORGANIZATION_DEALERS());
			return res;
		})
		.finally(() => {
			dispatch({
				type: actionTypes.HIDE_ORGANIZATION_DEALER_REQUEST,
				hideDealerLoading: false,
				selectedDealerToHide: null,
			});
		});
};

export const GET_ORGANIZATION_DETAILS = () => dispatch => {
	return getOrganization()
		.then(res => {
			dispatch({
				type: actionTypes.GET_ORGANIZATION_DETAILS,
				organizationInfo: res,
			});
			return res;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_ORGANIZATION_DETAILS,
				organizationInfo: null,
			});
			throw err;
		});
};

export const SET_PREFERRED_DEALER_OR_DELIVERY_ADDRESS = (retailerId, deliveryAddressId = '') => dispatch => {
	return setPreferredDealerOrDeliveryAddress({ retailerId, deliveryAddressId })
		.then(() => {
			dispatch({
				type: actionTypes.SET_PREFERRED_DEALER_OR_DELIVERY_ADDRESS,
				error: null,
			});
			dispatch(GET_ORGANIZATION_DEALERS());
		})
		.catch(err => {
			dispatch({
				type: actionTypes.SET_PREFERRED_DEALER_OR_DELIVERY_ADDRESS,
				error: err,
			});
			throw err;
		});
};

export const UPDATE_PREFERRED_DEALER = (retailerId, deliveryAddressId = '') => (dispatch, getState) => {
	const { dealers } = getState().organization;

	dispatch({
		type: actionTypes.UPDATE_PREFERRED_DEALER,
		dealers,
		dealersLoading: true,
		dealersError: false,
	});

	const dealersUpdated = dealers.map(dealer => {
		if (dealer.id === retailerId) {
			Object.assign(dealer, { isPreferred: true });
		} else {
			Object.assign(dealer, { isPreferred: false });
		}
		return dealer;
	});

	return new Promise<void>((resolve, reject) => {
		setPreferredDealerOrDeliveryAddress({ retailerId, deliveryAddressId })
			.then(() => {
				dispatch({
					type: actionTypes.UPDATE_PREFERRED_DEALER,
					dealers: dealersUpdated,
					dealersLoading: false,
					dealersError: false,
				});
				dispatch(GET_ORGANIZATION_DEALERS());
				resolve(); // Resolve the promise when everything is successful
			})
			.catch(err => {
				dispatch({
					type: actionTypes.UPDATE_PREFERRED_DEALER,
					dealersLoading: false,
					dealersError: true,
				});
				reject(err); // Reject the promise if there's an error
			});
	});
};

export const UPDATE_DELIVERY_ADDRESS = (retailerId, deliveryAddressId = '') => (dispatch, getState) => {
	const { dealers } = getState().organization;

	const preferredRetailer = dealers.find(retailer => retailer.isPreferred);
	const deliveryAddresses = get(preferredRetailer, 'deliveryAddresses', []);

	dispatch({
		type: actionTypes.UPDATE_DELIVERY_ADDRESS,
		dealersLoading: true,
		dealersError: false,
	});

	const addressesUpdated = deliveryAddresses.map(address => {
		if (address.customerNumber === deliveryAddressId) {
			Object.assign(address, { isPreferred: true });
		} else {
			Object.assign(address, { isPreferred: false });
		}
		return address;
	});

	const dealersUpdated = dealers.map(dealer => {
		if (dealer.id === retailerId) {
			Object.assign(dealer, { deliveryAddresses: addressesUpdated });
		}
		return dealer;
	});

	return setPreferredDealerOrDeliveryAddress({ retailerId, deliveryAddressId })
		.then(() => {
			dispatch({
				type: actionTypes.UPDATE_DELIVERY_ADDRESS,
				dealers: dealersUpdated,
				dealersLoading: false,
				dealersError: false,
			});
		})
		.catch(err => {
			dispatch({
				type: actionTypes.UPDATE_DELIVERY_ADDRESS,
				dealersLoading: false,
				dealersError: true,
			});
			throw err;
		});
};

export const GET_ORGANIZATION_USERS = () => dispatch => {
	return getOrganizationUsers()
		.then(res => {
			dispatch({
				type: actionTypes.GET_ORGANIZATION_USERS,
				organizationUsers: res,
			});
			return res;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_ORGANIZATION_USERS,
				organizationUsers: null,
			});
			throw err;
		});
};

export const ASSIGN_ADMIN_RIGHT = id => dispatch => {
	dispatch({
		type: actionTypes.ASSIGN_ADMIN_RIGHT,
		loading: true,
	});
	return assignAdminRight(id)
		.then(res => {
			dispatch({
				type: actionTypes.ASSIGN_ADMIN_RIGHT,
				loading: false,
			});
			return res;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.ASSIGN_ADMIN_RIGHT,
				loading: false,
				error: err,
			});
			throw err;
		});
};

export const REVOKE_ADMIN_RIGHT = id => dispatch => {
	dispatch({
		type: actionTypes.REVOKE_ADMIN_RIGHT,
		loading: true,
	});
	return revokeAdminRight(id)
		.then(res => {
			dispatch({
				type: actionTypes.REVOKE_ADMIN_RIGHT,
				loading: false,
			});
			return res;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.REVOKE_ADMIN_RIGHT,
				loading: false,
				error: err,
			});
			throw err;
		});
};

export const GET_CONSENT_SETTINGS = fin => dispatch => {
	dispatch({
		type: actionTypes.GET_CONSENT_SETTINGS,
		isConsentGiven: false,
		finBasedOrders: [],
		ordersLoading: true,
		ordersError: false,
	});

	return getConsentSettings(fin)
		.then(res => {
			const isForbidden = res.responseCode === ResponseCode.FORBIDDEN;
			const newVINBasedOrders = res.data?.map(order => {
				const { price, totalPrice, ...restOfOrder } = order;
				return {
					price: toFormattedPrice(price),
					totalPrice: toFormattedPrice(totalPrice),
					...restOfOrder,
				};
			});
			dispatch({
				type: actionTypes.GET_CONSENT_SETTINGS,
				isConsentGiven: isForbidden ? false : !!res,
				finBasedOrders: isForbidden ? [] : newVINBasedOrders,
				ordersLoading: false,
				ordersError: false,
			});
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_CONSENT_SETTINGS,
				isConsentGiven: false,
				finBasedOrders: [],
				ordersLoading: false,
				ordersError: true,
			});
		});
};

export const EDIT_ORGANIZATION_MAIL_ADDRESS = mailAddress => dispatch => {
	dispatch({
		type: actionTypes.EDIT_ORGANIZATION_MAIL_ADDRESS,
		editOrganizationMailAddressLoading: true,
		editOrganizationMailAddressError: null,
	});
	return updateOrganizationMailAddress(mailAddress)
		.then(res => {
			dispatch({
				type: actionTypes.EDIT_ORGANIZATION_MAIL_ADDRESS,
				editOrganizationMailAddressLoading: false,
				editOrganizationMailAddressError: null,
			});
			dispatch(GET_ORGANIZATION_DETAILS());
			return res;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.EDIT_ORGANIZATION_MAIL_ADDRESS,
				editOrganizationMailAddressLoading: false,
				editOrganizationMailAddressError: err,
			});
			throw err;
		});
};

export const GET_ORGANIZATION_TYPE = () => dispatch => {
	return getOrganizationType()
		.then(res => {
			dispatch({
				type: actionTypes.GET_ORGANIZATION_TYPE,
				organizationType: res.type as OrganizationTypes,
			});
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_ORGANIZATION_TYPE,
				organizationType: null,
			});
		});
};

export const DELETE_RETAILER = gssnId => dispatch => {
	dispatch({
		type: actionTypes.DELETE_RETAILER,
		loading: true,
	});
	return deleteRetailer(gssnId)
		.then(res => {
			dispatch({
				type: actionTypes.DELETE_RETAILER,
				loading: false,
			});
			dispatch(GET_ORGANIZATION_DEALERS());
			return res;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.DELETE_RETAILER,
				loading: false,
				error: err,
			});
			throw err;
		});
};

import { useRef, useState } from 'react';
import { useTranslation } from '@Lib/i18n';
import classNames from 'classnames';
import { ScrollIntoViewOptions, scrollToElement } from '@Helpers/common/document';
import { COLLAPSIBLE_SCROLL_DELAY } from '@Constants/common';

const CMSCollapsible = ({ children, initiallyCollapsed = false }) => {
	const { t } = useTranslation(['common']);
	const hideLabel = `- ${t('common:show-less')}`;
	const showLabel = `+ ${t('common:show-more')}`;
	const [showMore, setShowMore] = useState(!initiallyCollapsed);
	const buttonRef = useRef(null);
	const contentRef = useRef(null);

	const getClassName = () => {
		return classNames('description-section', { 'description-section--visible': !showMore });
	};

	const handleClick = () => {
		setShowMore(!showMore);
		if (showMore) {
			setTimeout(() => {
				scrollToElement(buttonRef.current, ScrollIntoViewOptions.CENTER);
			}, COLLAPSIBLE_SCROLL_DELAY);
		}
	};

	return (
		<>
			<div ref={contentRef} className={getClassName()}>
				{children}
			</div>
			<p ref={buttonRef} className="collapse-btn--active" onClick={handleClick}>
				{!showMore ? showLabel : hideLabel}
			</p>
		</>
	);
};

export default CMSCollapsible;

/* eslint-disable */
import React from 'react';
import withRouter, { WithRouterProps } from 'next/dist/client/with-router';
import { WithTranslation } from 'next-i18next';
import NextI18Next from '@Lib/i18n';
import { getComponentId, Pages, Components, Buttons } from '@Constants/google-analytics';
import Button, { ButtonVariant } from '@isp/button';
import Container from '@isp/container';
import Grid from '@isp/grid';
import GridItem from '@isp/grid-item';
import ISPIcon, { IconSize, IconType, IconVariant } from '@isp/icon';
import { WindowTargetAttributes } from '@Constants/common';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';

interface ErrProps {}
interface ErrState {
	hasError?: boolean;
}
const NAMESPACES_REQUIRED = ['common'];
class ErrorBoundary extends React.Component<ErrProps & WithRouterProps & WithTranslation, ErrState> {
	constructor(props) {
		super(props);

		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	onClickBack = e => {
		const { router } = this.props;
		const { market } = router.query;
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		window.open(`${window.location.origin}/${market}`, WindowTargetAttributes.SELF);
	};

	render() {
		const { t } = this.props;
		if (this.state.hasError) {
			return (
				<div>
					<div className="bg-white-three train__center train__flex" style={{ height: '100vh' }}>
						<Container>
							<Grid>
								<GridItem size="1/6" className="train__justify-center train__center">
									<ISPIcon type={IconType.ERROR_500} size={IconSize.VERY_HUGE} variant={IconVariant.DARKGRAY} />
								</GridItem>
								<GridItem size="1/2" className="train__justify-center train__center">
									<div>
										<p className="DaimlerCAC-Regular color-black-two fs-64">
											webservice currently unavailable Error 500
										</p>
										<p className="color-greyish-brown-2 fs-14">
											An unexpected condition encountered. Our service team has been dispatched to bring it back online.
										</p>
										<Button
											variant={ButtonVariant.PRIMARY}
											className="p-l-4 p-r-4 m-t-8 fs-14 color-white-two"
											style={{ width: '172px' }}
											id={getComponentId([Pages.GLOBAL, Components.ERROR, Buttons.BACK_TO_HOME])}
											onClick={e => this.onClickBack(e)}
										>
											{t('back-to-home')}
										</Button>
									</div>
								</GridItem>
							</Grid>
						</Container>
					</div>
				</div>
			);
		}

		// Return children components in case of no error

		return this.props.children;
	}
}

export default NextI18Next.withTranslation(NAMESPACES_REQUIRED)(withRouter(ErrorBoundary));

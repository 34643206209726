import { useEffect } from 'react';
import { VideoPlayer } from '@isp/video-player';
import { replaceNodeWithReactComponent } from '@Helpers/common/replaceNode';

export const useVideoPlayer = () => {
	useEffect(() => {
		if (document) {
			const videos = document.querySelectorAll(`[data-type*="video"]`);
			videos.forEach((video: HTMLElement) => {
				if (video) {
					replaceNodeWithReactComponent(
						video,
						<VideoPlayer src={video.getAttribute('data-source')} posterSrc={video.getAttribute('data-thumbnail')} />
					);
				}
			});
		}
	});
};

import map from 'lodash/map';
import groupBy from 'lodash/groupBy';

export const INVALID_CONTENT_ID = '00000000-0000-0000-0000-000000000000';
export const UNSET_DATE_TIME = '0001-01-01T00:00:00';

const getChildren = (parent: any, grouped: any) => {
	if (grouped[parent._id]) {
		return {
			...parent,
			children: map(grouped[parent._id], l => {
				return getChildren(l, grouped);
			}),
		};
	}
	return { ...parent, children: [] };
};

export default function getHierarchicalDescendants(parentId: string, list: any[]) {
	const grouped = groupBy(list, 'parentId');
	const nested = getChildren({ _id: parentId }, grouped);
	return nested;
}

export const filterInvalidItems = items => {
	return items?.filter(item => item._id !== INVALID_CONTENT_ID);
};

const getSortOrderIndexes = (row: HTMLCollection, sortOrder: string[]) => {
	const sortOrderIndexArr = [0];
	const nodes = Array.prototype.slice.call(row);
	sortOrder.forEach(sortIdentifier => {
		const searchVal = nodes.find(cellValue => cellValue.attributes['data-sort-id'].value === sortIdentifier);
		if (searchVal) sortOrderIndexArr.push(nodes.indexOf(searchVal));
	});
	return sortOrderIndexArr;
};

export const insertSortedTable = (table: Element, sortOrder: string[]) => {
	if (table) {
		const rows = table.getElementsByTagName('tr');
		const sortOrderIndex = getSortOrderIndexes(rows[0].children, sortOrder);
		for (let i = 0; i < rows.length; i += 1) {
			const sortedColumns = [];
			const cols = rows[i].children;

			sortOrderIndex.forEach(columnIndex => {
				sortedColumns.push(cols[columnIndex]);
			});
			rows[i].replaceChildren(...sortedColumns);
		}
	}
};

import { actionTypes } from '@Reducers/payment/actionTypes';
import { BasketValidationStatus } from '@Constants/shop';

const initialState = {
	paymentUrl: '',
	paymentFailReason: BasketValidationStatus.VALID,
};

export const paymentReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PAYMENT_URL:
			return {
				...state,
				paymentUrl: action.paymentUrl,
				paymentFailReason: action.paymentFailReason,
			};
		case actionTypes.RESET_PAYMENT_ERROR:
			return {
				...state,
				paymentFailReason: action.paymentFailReason,
			};
		default:
			return state;
	}
};

export default paymentReducer;

import { createRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { login } from '@User-operations';
import ISPTooltip, { TooltipThemes } from '@isp/tooltip';
import { useTranslation } from 'react-i18next';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import ISPTextButton, { HoverTypes, TextColors } from '@isp/text-button';
import { Buttons, Components, getComponentId, Pages, TagManagerClassNames } from '@Constants/google-analytics';
import { Events } from '@Constants/common';

const LoginTooltip = ({ children, register }) => {
	const loginTooltipRef = createRef<HTMLDivElement>();

	return (
		<>
			<div
				data-tip=""
				ref={loginTooltipRef}
				data-effect="solid"
				data-event={Events.CLICK}
				data-for="login-tooltip"
				onMouseEnter={() => ReactTooltip.show(loginTooltipRef.current)}
				onMouseLeave={() => ReactTooltip.hide(loginTooltipRef.current)}
			>
				{children}
			</div>
			<ISPTooltip
				theme={TooltipThemes.DARK}
				id="login-tooltip"
				getContent={() => <LoginTooltipContent register={register} />}
			/>
		</>
	);
};

const LoginTooltipContent = ({ register }) => {
	const { t: translate } = useTranslation(['common', 'login']);
	const handleClick = (e, action) => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		action();
	};
	return (
		<div className="m-v-2 fs-12">
			<p className="m-b-2">{translate('login:create-an-account')}</p>
			<div className="disp-flex">
				<ISPTextButton
					color={TextColors.AZURE}
					hoverType={HoverTypes.CERULEAN}
					className={`${TagManagerClassNames.REGISTER} fs-12`}
					onClick={e => handleClick(e, register)}
					id={getComponentId([Pages.EQUIPMENT, Components.EQUIPMENT_ITEM, Buttons.REGISTER])}
					underline
				>
					{translate('common:register')}
				</ISPTextButton>
				<ISPTextButton
					color={TextColors.WHITE}
					hoverType={HoverTypes.AZURE}
					className={`${TagManagerClassNames.LOGIN} m-l-2 fs-12`}
					underline
					onClick={e => handleClick(e, login)}
					id={getComponentId([Pages.EQUIPMENT, Components.EQUIPMENT_ITEM, Buttons.LOGIN])}
				>
					{translate('common:login')}
				</ISPTextButton>
			</div>
		</div>
	);
};

export default LoginTooltip;

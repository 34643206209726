import { Languages } from '@Constants/account';

const ONE_DOC_LANGUAGES = [
	'ar-AE',
	'af-ZA',
	'ar-BH',
	'ar-DZ',
	'ar-EG',
	'ar-IQ',
	'ar-JO',
	'ar-KW',
	'ar-LB',
	'ar-LY',
	'ar-MA',
	'ar-OM',
	'ar-QA',
	'ar-SA',
	'ar-SY',
	'ar-TN',
	'ar-YE',
	'az-AZ',
	'az-AZ',
	'be-BY',
	'bg-BG',
	'bs-BA',
	'ca-ES',
	'cs-CZ',
	'cy-GB',
	'da-DK',
	'de-AT',
	'de-CH',
	'de-DE',
	'de-LI',
	'de-LU',
	'de-NL',
	'dv-MV',
	'el-GR',
	'en-AU',
	'en-BZ',
	'en-CA',
	'en-CB',
	'en-GB',
	'en-IE',
	'en-JM',
	'en-NZ',
	'en-PH',
	'en-TT',
	'en-US',
	'en-ZA',
	'en-ZW',
	'en-AE',
	'en-NL',
	'es-AR',
	'es-BO',
	'es-CL',
	'es-CO',
	'es-CR',
	'es-DO',
	'es-EC',
	'es-ES',
	'es-ES',
	'es-GT',
	'es-HN',
	'es-MX',
	'es-NI',
	'es-PA',
	'es-PE',
	'es-PR',
	'es-PY',
	'es-SV',
	'es-UY',
	'es-VE',
	'et-EE',
	'eu-ES',
	'fa-IR',
	'fi-FI',
	'fo-FO',
	'fr-BE',
	'fr-CA',
	'fr-CH',
	'fr-FR',
	'fr-LU',
	'fr-MC',
	'gl-ES',
	'gu-IN',
	'he-IL',
	'hi-IN',
	'hr-BA',
	'hr-HR',
	'hu-HU',
	'hy-AM',
	'id-ID',
	'is-IS',
	'it-CH',
	'it-IT',
	'ja-JP',
	'ka-GE',
	'kk-KZ',
	'kn-IN',
	'ko-KR',
	'kok-IN',
	'ky-KG',
	'lt-LT',
	'lv-LV',
	'mi-NZ',
	'mk-MK',
	'mn-MN',
	'mr-IN',
	'ms-BN',
	'ms-MY',
	'mt-MT',
	'nb-NO',
	'nl-BE',
	'nl-NL',
	'nn-NO',
	'ns-ZA',
	'pa-IN',
	'pl-PL',
	'ps-AR',
	'pt-BR',
	'pt-PT',
	'qu-BO',
	'qu-EC',
	'qu-PE',
	'ro-RO',
	'ru-RU',
	'sa-IN',
	'se-FI',
	'se-FI',
	'se-FI',
	'se-NO',
	'se-NO',
	'se-NO',
	'se-SE',
	'se-SE',
	'se-SE',
	'sk-SK',
	'sl-SI',
	'sq-AL',
	'sr-BA',
	'sr-BA',
	'sr-SP',
	'sr-SP',
	'sv-FI',
	'sv-SE',
	'sw-KE',
	'syr-SY',
	'ta-IN',
	'te-IN',
	'th-TH',
	'tl-PH',
	'tn-ZA',
	'tr-TR',
	'tt-RU',
	'uk',
	'uk-UA',
	'ur-PK',
	'uz-UZ',
	'uz-UZ',
	'vi-VN',
	'xh-ZA',
	'zh-CN',
	'zh-HK',
	'zh-MO',
	'zh-SG',
	'zh-TW',
	'zu',
	'zu-ZA',
];

export const ONE_DOC_FALLBACK_LANGUAGES = {
	[Languages.DE]: 'de-DE',
	[Languages.EN]: 'en-GB',
	[Languages.EN_AU]: 'en-GB',
	[Languages.EN_TW]: 'en-GB',
	[Languages.RO]: 'ro-RO',
	[Languages.CS]: 'cs-CZ',
	[Languages.DA]: 'da-DK',
	[Languages.EL]: 'el-GR',
	[Languages.ES]: 'es-ES',
	[Languages.BE]: 'be-BY',
	[Languages.FI]: 'fi-FI',
	[Languages.FR]: 'fr-FR',
	[Languages.IN]: 'hi-IN',
	[Languages.IT]: 'it-IT',
	[Languages.KO]: 'ko-KR',
	[Languages.NL]: 'nl-NL',
	[Languages.NO]: 'nn-NO',
	[Languages.PL]: 'pl-PL',
	[Languages.PT]: 'pt-PT',
	[Languages.SV]: 'sv-SE',
	[Languages.TR]: 'tr-TR',
	[Languages.ZH]: 'zh-CN',
	[Languages.ZH_HK]: 'zh-HK',
	[Languages.ZH_TW]: 'zh-TW',
	[Languages.HU]: 'hu-HU',
	[Languages.SK]: 'sk-SK',
};

export default ONE_DOC_LANGUAGES;

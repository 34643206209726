import { useEffect, useState } from 'react';
import noop from 'lodash/noop';
import { useSelector } from 'react-redux';
import { RootState } from '@Redux';
import { getSearchHistory, postSearchHistoryTerm } from '@Apis/customer';
import { SearchHistoryTypes } from '@Constants/common';

const useRecentQueries = (searchHistoryType: SearchHistoryTypes, parameters = {}) => {
	const [recentQueries, setRecentQueries] = useState<string[]>([]);
	const userLoggedIn = useSelector<RootState, boolean>(state => state.user.isUserLoggedIn);

	const fetchRecentQueries = () => {
		if (userLoggedIn) {
			return getSearchHistory(searchHistoryType, parameters)
				.then(res => setRecentQueries(res))
				.catch(() => setRecentQueries([]));
		}
		return new Promise(noop);
	};

	const postRecentQuery = (query, queryParameters = parameters || {}) => {
		if (userLoggedIn && query && query?.length !== 0) {
			return postSearchHistoryTerm(searchHistoryType, query, queryParameters).then(fetchRecentQueries);
		}
		return new Promise(noop);
	};

	useEffect(() => {
		if (searchHistoryType !== SearchHistoryTypes.VIN_FOR_PARTS) fetchRecentQueries();
	}, []);

	return { recentQueries, fetchRecentQueries, postRecentQuery };
};

export default useRecentQueries;

import getAppEnvironment from '@Helpers/common/getAppEnvironment';
import { Environments, StringReplacePatterns } from '@Constants/common';

export const ADD_USER_RELOAD_TIMEOUT = 3000;
export const SCROLL_TIMEOUT = 1500;
export const ROUTE_SLICE_MARKET_CHAR_COUNT = 10;

export const APPLICATION_UTM_SOURCE_ID = 'b2bc';
export const UTM_TRAFFIC_TYPE = 'referral';

export enum UtmParameters {
	SOURCE = 'utm_source',
	TYPE = 'utm_medium',
	CAMPAIGN = 'utm_campaign',
	CONTENT = 'utm_content',
	TERM = 'utm_term',
}

export enum TagManagerClassNames {
	LOGIN = 'x-google-login',
	REGISTER = 'x-google-register',
	PL24 = 'x-google-pl24',
	VIN_SEARCH = 'x-google-products-vin-search',
	FAQ_YES_BUTTON = 'google-faq-yes',
	FAQ_NO_BUTTON = 'google-faq-no',
}

export enum TagManagerEventKeys {
	EXTERNAL_URL_BUTTON_CLICK = 'externalUrlButtonClick',
	FAQ_QUESTION_CLICK = 'faqQuestionClick',
	SCROLL_DEPTH = 'scrollDepth',
	BUTTON_CLICK = 'buttonClick',
	SEARCH = 'search',
}

export enum Pages {
	ACCOUNT = 'account',
	ADVANTAGES = 'landing-advantages',
	BASKET = 'basket',
	BAUMUSTER_ENTRY = 'baumuster-entry',
	CAMPAIGN = 'campaign',
	CAMPAIGNS = 'campaigns',
	CHEMICAL_PRODUCTS_AND_CAR_CARE = 'chemical-products-car-care',
	CONSENT = 'consent',
	EQUIPMENT = 'equipment',
	FAQ = 'faq',
	GENUINE_PARTS = 'genuine-parts',
	GLOBAL = 'global',
	HOME = 'home',
	HELP = 'help',
	LEGAL = 'legal',
	LICENCES = 'licences',
	MODAL = 'modal',
	MY_WORKSHOP = 'my-workshop',
	NAVIGATION = 'navigation',
	ORDERS = 'orders',
	PARTS = 'parts',
	PARTS_CAMPAIGNS = 'parts-campaigns',
	PLATFORM_SEARCH = 'platform-search',
	PRODUCTS = 'products',
	RDS = 'remote-diagnostic-support',
	RETAILER = 'retailer',
	REMAN_PARTS = 'reman-parts',
	RMS = 'remote-maintenance-support',
	STAR_PARTS = 'star-parts',
	TECHNICAL_DATA = 'technical-data',
	TIRES = 'tires',
	TOOL_TIP = 'tool-tip',
	VEHICLE = 'vehicle',
	WORKING_ON_VEHICLE = 'working-on-vehicle',
	VEHICLE_DATA_CARD = 'vehicle-data-card',
}

export enum Components {
	ACCESS = 'access',
	ACCESS_REQUEST = 'access-request',
	ACCOUNT = 'account',
	ADD_TO_BASKET_DIRECT = 'add-to-basket-directly',
	AGGREGATE_SELECTION = 'aggregate-selection',
	APPLICATION = 'application',
	ARVATO_BLOCKED = 'arvato-blocked',
	ASSISTANCE = 'assistance',
	ASSISTANCE_VALIDATION = 'assistance-validation',
	ASSISTANCE_REQUEST_MAIL = 'assistance-request-mail',
	AUTHORIZATION = 'authorization',
	ACCORDION_ITEM = 'accordion-item',
	BANNER = 'banner',
	BASKET_DELETE_MODAL = 'basket-delete-modal',
	BENEFITS_MODULE = 'benefits-module',
	CAMPAIGNS_NO_SSR = 'campaigns-no-ssr',
	CATALOG_AGGREGATE_SLIDER = 'catalog-aggregate-slider',
	CATALOGUE_BY_TYPE = 'catalogue-by-type',
	CATALOGUE_FILTERS = 'catalogue-filters',
	CATALOGUE_SELECTIONS = 'catalogue-selections',
	COLOR_SELECTION = 'color-selection',
	CONTACT_CARD = 'contact-card',
	CONTENT_REPORTING_MODAL = 'content-reporting-modal',
	COMPANY = 'company',
	CHECKOUT_SUMMARY = 'checkout-summary',
	CHECKOUT_SUMMARY_PAYMENT = 'checkout-summary-payment',
	WEBPART_CHECKOUT_SUMMARY_PAYMENT = 'webpart_checkout-summary-payment',
	CHANGE_DISTANCE_UNIT = 'change-distance-unit',
	CHANGE_HARDWARE_ID = 'change-hardware-id',
	COMPANY_MAIL = 'company-mail',
	CODE_VALIDITY = 'code-validity',
	DATA = 'data',
	DATA_SHEET = 'data-sheet',
	DATA_CARD_SECOND_LAYER = 'data-card-second-layer',
	DEALER_LOCATOR = 'dealer-locator',
	DIVISION_CARD = 'division-card',
	DSB = 'dsb',
	DSB_SERVICE_DOCUMENTATION = 'dsb-service-documentation',
	EDIT_COMPANY_INFO = 'edit-company-info-modal',
	ERROR = 'error',
	ENGIN_FINDER = 'engine-finder',
	ENTER_VIN = 'enter-vin',
	EQUIPMENT_ITEM = 'equipment-item',
	EXPORT_PDF_OPTIONS = 'export-pdf-options',
	EXPLOSION_DRAWING = 'explosion-drawing',
	EXPLOSION_DRAWING_SLIDER = 'explosion-drawing-slider',
	EXTENSIONS = 'extensions',
	FAQ = 'faq',
	FEATURE = 'feature',
	FEEDBACK = 'feedback',
	FLYOUT_MENU = 'flyout-menu',
	FOOTER = 'footer',
	FOOTER_GROUP = 'footer-group',
	FOOTNOTE = 'footnote',
	GENUINE_PARTS = 'genuine-parts',
	GENUINE_CATEGORY = 'genuine-category',
	HEADER = 'header',
	INITIAL_REGISTRATION = 'initial-registration',
	KDM = 'kdm',
	LANGUAGES = 'languages',
	LINAS = 'linas',
	LOGIN = 'login',
	LOGOUT = 'logout',
	MAIN_CATEGORY_TITLE = 'main-category-title',
	MAIN_GROUP = 'main-group',
	MANIFACTURAR_SPECS = 'manifacturar-specifications',
	MARKET_NOT_ALLOWED = 'market-not-allowed',
	MARKETING_CAMPAIGN = 'marketing-campaign-register',
	MORE_APPLICATIONS = 'more-applications',
	MODAL = 'modal',
	MODEL_IDENTIFICATION_MODAL = 'model-identification-modal',
	NEED_HELP = 'need-help',
	NEW_PARTNER = 'new-partner',
	NEW_FEATURE = 'new-feature',
	NEWSLETTER = 'newsletter',
	NO_ADDITIONAL_WORK = 'no-additional-work',
	NO_RETAILER = 'no-retailer',
	NOT_REPUBLISHER = 'not-republisher',
	NOTIFICATION = 'notification',
	ORDER_DETAILS = 'order-details',
	ORDER_ITEM = 'order-item',
	ORDER_LIST = 'order-list',
	ORDER_RATING = 'order-rating',
	ORDER_SUCCESS = 'order-success',
	ONE_DOC_MODAL = 'one-doc-modal',
	PAYMENT_RESULT = 'payment-result',
	PACKAGES = 'packages',
	PARTS = 'parts',
	PARTS_CATALOGUE = 'parts-catalogue',
	PARTS_ITEM = 'parts-item',
	PARTS_ORDERS = 'parts-orders',
	PARTS_TEASER = 'parts-teaser',
	PART_PACKAGES = 'part-packages',
	PRIVATE_CUSTOMER = 'private-customer',
	PRIVACY_POLICY = 'privacy-policy',
	PRODUCT_DETAIL = 'product-detail',
	PRODUCT_CARD = 'product-card',
	PRODUCT_SUGGESTION = 'product-sugestion',
	PROTECTION = 'protection',
	PROVIDER_DATA_PROTECTION = 'provider-data-protection',
	PURCHASE = 'purchase',
	SPEC = 'spec',
	STICKY = 'sticky',
	PUBLIC_HOME = 'public-home',
	SUB_NAVIGATION_MENU = 'sub-navigation-menu',
	QUESTIONS = 'questions',
	QUICKSTART = 'quickstart',
	QUOTE_MODULE = 'quote-module',
	REGISTER_MODULE = 'register-module',
	REMAN_PARTS = 'reman-parts',
	REMAN_CATEGORY = 'reman-category',
	REMAN_MODAL = 'reman-modal',
	REMAN_ENGINE_SUGGESTION = 'reman-engine-suggestion',
	REPUBLISHER = 'republisher',
	REGISTER = 'register',
	REGISTER_AS_A_COMPANY = 'register-as-a-company',
	REQUEST_SUCCESSFUL = 'request-successful',
	RESULT_LIST = 'result-list',
	RETAILER_CONTENT = 'retailer-content',
	RETAIL_DATA_STORAGE = 'retail-data-storage',
	RETAILER_DELETE_MODAL = 'retailer-delete-modal',
	RETAILER_LIST = 'retailer-list',
	RMI_LICENSES = 'rmi-licenses',
	RDS = 'remote-diagnostic-support-purchase',
	RMS = 'remote-maintenance-support-purchase',
	SATISFACTION_SCORE = 'satisfaction-score',
	SEARCH_BAR = 'search-bar',
	SERVICE_EDIT = 'service-edit',
	SERVICE_DELETION = 'service-deletion',
	SET_PREFERRED_ADDRESS = 'set-preferred-address',
	SET_PREFERRED_RETAILER = 'set-preferred-retailer',
	SETTINGS = 'settings',
	SIDEBAR = 'sidebar',
	SITEMAP = 'site-map',
	SHOPPING_BASKET = 'shopping-basket',
	SHOPPING_CARD_FOOTER = 'shopping-card-footer',
	STANDARDISED_NAVIGATION = 'standardised-navigation',
	SUBSCRIBE = 'subscribe',
	SUB_CATEGORY_TITLE = 'sub-category-title',
	SUB_GROUP = 'sub-group',
	SUCCESS_MODAL = 'success-modal',
	SUPPORT_MODAL = 'support-modal',
	SYSTEMS = 'systems',
	SPECIAL_MODIFICATION_VEHICLE_MODAL = 'special-modification-vehicle-modal',
	STAR_PARTS = 'star-parts',
	STICKY_REGISTER = 'sticky-register',
	TAB = 'tab',
	TECHNICAL_NECESSITIES = 'technical-necessities',
	TEMPLATE = 'template',
	TERMS_AND_DATA_PROTECTION = 'terms-and-data-protection',
	TIME_CREDITS = 'time-credits',
	USER = 'user',
	USERCENTRICS = 'usercentrics',
	VEHICLE_ADDITION = 'vehicle-addition',
	VEHICLE_DELETION = 'vehicle-deletion',
	VEHICLE_EDIT = 'vehicle-edit',
	VEHICLE_LIST = 'vehicle-list',
	VEHICLE_WIDGET = 'vehicle-widget',
	VEHICLE_PART = 'vehicle-widget',
	VEHICLE_PRINT = 'vehicle-print',
	WEBPARTS = 'webparts',
	XENTRY_ACCESSORIES = 'xentry-accessories',
	XENTRY_DIAGNOSIS = 'xentry-diagnosis',
}

// Should be a general action e.g. Add, Delete, Edit
export enum Buttons {
	ACCEPT = 'accept',
	ACCEPT_ALL = 'accept-all',
	ACCEPT_LATER = 'accept-later',
	ACCESS = 'access',
	ACCORDION_TOGGLE = 'accordion-toggle',
	ACTION_LINK = 'action-link',
	ACTIVATION = 'activation',
	ADD = 'add',
	ADD_TO_BASKET = 'add-to-basket',
	ADD_TO_BASKET_ALL = 'add-to-basket-all',
	ADD_TO_BASKET_SELECTED = 'add-to-basket-selected',
	ADMIN_RIGHT = 'admin-right',
	ALL_RETAILERS = 'all-retailers',
	BACK = 'back',
	BACK_TO_HOME = 'back-to-home',
	BUY = 'buy',
	CANCEL = 'cancel',
	CATALOG_ITEM = 'catalog-item',
	CATEGORY_CLICK = 'category-click',
	CHANGE = 'change',
	CLEAR = 'clear',
	CLOSE = 'close',
	CONFIRM = 'confirm',
	CONTINUE = 'continue',
	CONTACT_US = 'contact-us',
	CREATE_ACCOUNT = 'create-account',
	CUSTOMER_NOTE = 'customer-note',
	DATA_PROTECTION = 'data-protection',
	DATE_PICKER = 'date-picker',
	DELETE = 'delete',
	DENY = 'deny',
	DETAILS = 'details',
	DISPLAY = 'display',
	DOCUMENT_NEW_SERVICE = 'document-new-service',
	DOWNLOAD = 'download',
	EXPAND_COLLAPSE = 'expand-collapse',
	EDIT_USER = 'edit-user',
	EDIT_DATA = 'edit-data',
	EDIT_DETAILS = 'edit-details',
	EXPORT = 'export',
	EXTERNAL_PURCHASE = 'external-purchase',
	FILLINGS = 'fillings',
	FILTER = 'filter',
	FORGOT_PASSWORD = 'forgot-password',
	FASTER_AVAILABLE = 'faster-available',
	GO_TO_URL = 'go-to-url',
	GO_TO_WEBPARTS = 'go-to-webparts',
	GO_TO_WHEELS = 'go-to-wheels',
	IMPORT = 'import',
	KDM = 'kdm',
	KEEP_VEHICLE = 'keep-vehicle',
	IMAGE = 'image',
	INTERNAL_NOTE = 'internal-note',
	LINK = 'link',
	LOADING = 'loading',
	LOAD_MORE = 'load-more',
	LOCATOR_CLICK = 'locator-click',
	LOGIN = 'login',
	LOGOUT = 'logout',
	MAIN_GROUP = '-main-group',
	MFAQ = 'mfaq',
	MOBILE = 'mobile',
	MORE_HELP = 'more-help',
	MORE_INFORMATION = 'more-information',
	NEXT = 'next',
	NEXT_SERVICE = 'next-service',
	NO = 'no',
	ORDER_STATUS = 'order-status',
	OPEN = 'open',
	OPENING_HOURS = 'opening-hours',
	OPEN_SEND_MAIL = 'open-send-mail',
	QR_CODE = 'qr-code',
	QUESTION_CLICK = 'question-click',
	PARTS_INFORMATION = 'parts-information',
	PARTS_LINK = 'parts-link',
	PART_NAME = 'part-name',
	PLAY = 'play',
	PREVIOUS = 'previous',
	PREVIOUS_PAGE_BUTTON = 'previous-page-button',
	PRINT = 'print',
	PRODUCT_INFORMATION = 'product-information',
	PRODUCT_INFORMATION_MOBILE = 'product-information-mobile',
	PURCHASE_CREDITS = 'purchase-credits',
	RADIO_BUTTON_CLICK = 'radio-button-click',
	RATE = 'rate',
	READ_MORE = 'read-more',
	REDIRECT = 'redirect',
	REGISTER = 'register',
	RELOAD = 'reload',
	REVOKE = 'revoke',
	REQUEST = 'request',
	RETAILER_LINK = 'retailer-link',
	RETRY = 'retry',
	REMIND_LATER = 'remind-later',
	ROUND = 'round',
	SAVE = 'save',
	SEARCH = 'search',
	SEND = 'send',
	SELECT = 'select',
	SERIES_ITEM = 'series-item',
	SCROLL_LEFT = 'scroll-left',
	SCROLL_RIGHT = 'scroll-right',
	SHOW_MORE = 'show-more',
	SHOW_ALL = 'show-all',
	SHOW_OPTIONS = 'show-options',
	SLIDER_DOT = 'slider-dot',
	SUB_GROUP = '-sub-group',
	SUBMIT = 'submit',
	SUBMIT_EXPORT = 'submit-export',
	SUB_CATEGORY_CLICK = 'sub-category-click',
	SWITCH = 'switch',
	TAB_CLICK = 'tab-click',
	TERMS_OF_USEAGE = 'terms-of-useage',
	TOGGLE = 'toggle',
	UNDERSTOOD = 'understood',
	UNLOCK_VIN = 'unlock-vin',
	UPLOAD = 'upload',
	VIDEO = 'video',
	VIEW = 'view',
	YES = 'yes',
}

export enum ButtonOrientation {
	TOP = 'top',
	BOTTOM = 'bottom',
	LEFT = 'left',
	RIGHT = 'right',
}

export enum EventCategories {
	NAVIGATION = 'navigation',
	LINK = 'link',
	SEARCH = 'search',
	FILTER = 'filter',
	FEATURE = 'feature',
	LISTING = 'listing',
	IMAGE = 'image',
}

export enum SearchType {
	PLATFORM_SEARCH = 'platformSearch',
	VEHICLE_SEARCH = 'vehicleSearch',
	PART_SEARCH = 'partSearch',
	LINAS_SEARCH = 'linasSearch',
}

export enum EventActions {
	SEARCH_EXECUTED = 'search_executed',
	SEARCH_RESULT_SELECTED = 'search_result.selected',
	SELECT_VEHICLE = 'select_vehicle',
	ADD_TO_BASKET = 'add_to_basket',
	REMOVE_FROM_BASKET = 'remove_from_basket',
	START_ADD_TO_BASKET_MANUALLY = 'start_add_to_basket_manually',
	BEGIN_CHECKOUT = 'begin_checkout',
	COMMIT_TO_BUY = 'commit_to_buy',
	EDIT_DATA_ATTEMPT = 'edit_data_attempt',
	EDIT_DATA_SUCCESS = 'edit_data_success',
	SERVICE_DOCUMENTATION_PAGE = 'service documentation page',
	SERVICE_DETAIL_PAGE = 'service detail page',
	REGISTRATION = 'registration',
	DOWNLOAD = 'download',
	VEHICLE_INFORMATION_PAGE = 'vehicle_information_page',
	LINK_CLICK = 'clicked link',
	OPEN = 'open',
	START = 'start',
	SELECT = 'select',
	LOGIN = 'login',
}

export enum EventLocation {
	VEHICLE_INFORMATION_PAGE = 'vehicle_information_page',
	HEADAER = 'header',
	SIDEBAR = 'sidebar',
	FOOTER = 'footer',
}

export interface EventDetail {
	action?: string;
	category?: string;
	custom?: string;
	label?: string;
	search?: {
		results?: string;
		term?: string;
	};
	search_term?: string;
	add_to_basket_module?: string;
	remove_from_basket_module?: string;
}

export interface EventApplication {
	sub_id: string;
	sub_version: string;
}

export const getAppEnvironmentGTM = () => {
	const config = {
		APP_ENV: getAppEnvironment(),
	};
	return config.APP_ENV;
};

export const getComponentId = (
	identifiers: (string | Pages | Components | Buttons)[],
	toLower = false,
	format = false
) => {
	let id = identifiers.join('-');
	const envPrefix = getAppEnvironmentGTM() === Environments.PROD ? '' : getAppEnvironment();

	if (toLower) id = id.toLowerCase();
	if (format) id = id.replace(new RegExp('/|_| ', StringReplacePatterns.REGEX_REPLACE_ALL_GLOBAL), '-');
	return `gtm${envPrefix === Environments.LOCAL ? Environments.DEV : envPrefix}-${id}`;
};

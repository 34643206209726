import isNil from 'lodash/isNil';
import Router from 'next/router';
import queryString from 'query-string';

export const RemoveFromQueryString = (url: any, key: string) => {
	// parse url
	const fullUrl = url;
	const parsedUrl = fullUrl.split('#');

	// url without any hash, with query parameters
	const originUrl = parsedUrl[0] || null;
	const queries = queryString.parseUrl(originUrl);
	const hash = parsedUrl[1] || null;

	if (queries.query[key]) {
		queries.query[key] = undefined;
	}

	const newQs = queryString.stringify(queries.query, { skipNull: true });

	// return new url
	return queries.url + (newQs ? `?${newQs}` : '') + (hash ? `#${hash}` : '');
};

const getQueryParam = (value: string) => {
	return value === null || value === '' ? undefined : value;
};

export const createEmailLink = (toMail: string, ccMail: string, subject: string, body: string) => {
	const emailQuery = queryString.stringify(
		{
			cc: getQueryParam(ccMail),
			subject: getQueryParam(subject),
			body: getQueryParam(body),
		},
		{ skipNull: true }
	);
	return `mailto:${toMail}?${emailQuery}`;
};

export const URLContainsQueryValue = (variable: string, value: string = null) => {
	// parse url
	const parsed = window.location.href.split('#');

	// url without any hash, with query parameters
	const originUrl = parsed[0] || null;
	const queries = queryString.parseUrl(originUrl);

	if (queries.query[variable]) {
		return isNil(value) ? true : queries.query[variable] === value;
	}
	return false;
};

export const removeHashFromURL = () => {
	if (Router.asPath?.includes('#')) {
		Router.replace(Router.asPath.split('#')[0], null, { shallow: true });
	}
};

export const actionTypes = {
	GET_ORGANIZATION_DEALERS: 'GET_ORGANIZATION_DEALERS',
	ADD_ORGANIZATION_DEALER: 'ADD_ORGANIZATION_DEALER',
	HIDE_ORGANIZATION_DEALER_REQUEST: 'HIDE_ORGANIZATION_DEALER_REQUEST',
	GET_ORGANIZATION_DETAILS: 'GET_ORGANIZATION_DETAILS',
	SET_PREFERRED_DEALER_OR_DELIVERY_ADDRESS: 'SET_PREFERRED_DEALER_OR_DELIVERY_ADDRESS',
	UPDATE_PREFERRED_DEALER: 'UPDATE_PREFERRED_DEALER',
	UPDATE_DELIVERY_ADDRESS: 'UPDATE_DELIVERY_ADDRESS',
	SET_DEALER_ACCORDION: 'SET_DEALER_ACCORDION',
	GET_ORGANIZATION_USERS: 'GET_ORGANIZATION_USERS',
	ASSIGN_ADMIN_RIGHT: 'ASSIGN_ADMIN_RIGHT',
	REVOKE_ADMIN_RIGHT: 'REVOKE_ADMIN_RIGHT',
	EDIT_ORGANIZATION_MAIL_ADDRESS: 'EDIT_ORGANIZATION_MAIL_ADDRESS',
	GET_CONSENT_SETTINGS: 'GET_CONSENT_SETTINGS',
	GET_ORGANIZATION_TYPE: 'GET_ORGANIZATION_TYPE',
	DELETE_RETAILER: 'DELETE_RETAILER',
};

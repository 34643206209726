export const actionTypes = {
	UPDATE_EXPLOSION_DRAWING_HIGHLIGHTED_PARTS: 'UPDATE_EXPLOSION_DRAWING_HIGHLIGHTED_PARTS',
	CLEAR_OLD_VEHICLE_REMARK: 'CLEAR_OLD_VEHICLE_REMARK',
	CLEAR_VEHICLE_DETAILS: 'CLEAR_VEHICLE_DETAILS',
	DELETE_VEHICLE: 'DELETE_VEHICLE',
	GET_CATALOGS_WITH_VIN: 'GET_CATALOGS_WITH_VIN',
	GET_DSB_EDIT_DATA: 'GET_DSB_EDIT_DATA',
	GET_DWD_OPTION: 'GET_DWD_OPTION',
	GET_FILLING_TAB_DATA: 'GET_FILLING_TAB_DATA',
	GET_FILTERED_VEHICLE_LIST: 'GET_FILTERED_VEHICLE_LIST',
	GET_KDM_INFO: 'GET_KDM_INFO',
	GET_VEHICLE_NOTE: 'GET_VEHICLE_NOTE',
	GET_NON_SAVED_VEHICLE_DETAILS: 'GET_NON_SAVED_VEHICLE_DETAILS',
	GET_SEARCH_PARTS_BY_PART_NUMBER: 'GET_SEARCH_PARTS_BY_PART_NUMBER',
	GET_SERVICE_DOCUMENTATION: 'GET_SERVICE_DOCUMENTATION',
	GET_SERVICE_HISTORY: 'GET_SERVICE_HISTORY',
	GET_SERVICE_HISTORY_DETAIL: 'GET_SERVICE_HISTORY_DETAIL',
	GET_TO_BE_DELETED_VEHICLE_COUNT: 'GET_TO_BE_DELETED_VEHICLE_COUNT',
	GET_USER_VEHICLES_OR_PART: 'GET_USER_VEHICLES_OR_PART',
	GET_VEHICLE_DETAILS: 'GET_VEHICLE_DETAILS',
	RESET_VEHICLE_DETAILS: 'RESET_VEHICLE_DETAILS',
	GET_VEHICLE_INFO: 'GET_VEHICLE_INFO',
	GET_VEHICLE_PARTS_BY_VIN_NUMBER: 'GET_VEHICLE_PARTS_BY_VIN_NUMBER',
	GET_VEHICLE_PARTS_BY_VIN_AND_PART_NUMBER: 'GET_VEHICLE_PARTS_BY_VIN_AND_PART_NUMBER',
	GET_VEHICLE_VIN_VALIDATION: 'GET_VEHICLE_VIN_VALIDATION',
	MAP_VEHICLE_DSB_EDIT_DATA: 'MAP_VEHICLE_DSB_EDIT_DATA',
	RESET_DOCUMENTATION_FORM: 'RESET_DOCUMENTATION_FORM',
	RESET_VALIDATION_COUNTER: 'RESET_VALIDATION_COUNTER',
	ROUTE_VEHICLE_PAGE_SECTION: 'ROUTE_VEHICLE_PAGE_SECTION',
	SAVE_VEHICLE: 'SAVE_VEHICLE',
	SELECT_AGGREGATE: 'SELECT_AGGREGATE',
	SET_DELETED_VEHICLE_VIN: 'SET_DELETED_VEHICLE_VIN',
	SET_DSB_SERVICES: 'SET_DSB_SERVICES',
	SET_FILTERED_VEHICLE_LIST: 'SET_FILTERED_VEHICLE_LIST',
	SET_HIGHLIGHTED_VEHICLE_VIN: 'SET_HIGHLIGHTED_VEHICLE_VIN',
	SET_RECENTLY_OPENED_VEHICLES: 'SET_RECENTLY_OPENED_VEHICLES',
	SET_RECENT_ORDERS_FILTER_AVAILABLE: 'SET_RECENT_ORDERS_FILTER_AVAILABLE',
	SET_SCROLL_TO_KDM: 'SET_SCROLL_TO_KDM',
	SET_SCROLL_TO_VEHICLE_ORDERS: 'SET_SCROLL_TO_VEHICLE_ORDERS',
	SET_SEARCHED_PART: 'SET_SEARCHED_PART',
	SET_SEARCHED_VEHICLES: 'SET_SEARCHED_VEHICLES',
	SET_SELECTED_VEHICLE: 'SET_SELECTED_VEHICLE',
	SET_UPDATED_VEHICLE_NOTE: 'SET_UPDATED_VEHICLE_NOTE',
	SET_VEHICLE_LIST: 'SET_VEHICLE_LIST',
	SET_VEHICLE_LIST_ACTIVE_SORTING_BUTTON: 'SET_VEHICLE_LIST_ACTIVE_SORTING_BUTTON',
	SET_VEHICLE_LIST_LAST_LOADED_INDEX: 'SET_VEHICLE_LIST_LAST_LOADED_INDEX',
	SET_VEHICLE_LIST_PAGE_INDEX: 'SET_VEHICLE_LIST_PAGE_INDEX',
	SHOW_OLD_VEHICLE_REMARK: 'SHOW_OLD_VEHICLE_REMARK',
	TOGGLE_ALL_SERVICES: 'TOGGLE_ALL_SERVICES',
	TOGGLE_DSB_SAVED_NOTIFICATION: 'TOGGLE_DSB_SAVED_NOTIFICATION',
	TOGGLE_EXPORT_PDF_SELECTION: 'TOGGLE_EXPORT_PDF_SELECTION',
	TRIGGER_VALIDATION: 'TRIGGER_VALIDATION',
	UPDATE_ADDITIONAL_CHECKBOXES_STATUS: 'UPDATE_ADDITIONAL_CHECKBOXES_STATUS',
	UPDATE_CHAPTER_NAV_BUTTON_STATUS: 'UPDATE_CHAPTER_NAV_BUTTON_STATUS',
	UPDATE_DSB_DATA: 'UPDATE_DSB_DATA',
	UPDATE_DSB_EXPORT_ERROR: 'UPDATE_DSB_EXPORT_ERROR',
	UPDATE_FILTERED_CHECKBOXES_STATUS: 'UPDATE_FILTERED_CHECKBOXES_STATUS',
	UPDATE_GENERAL_SEARCH_KEYWORD: 'UPDATE_GENERAL_SEARCH_KEYWORD',
	UPDATE_KDM_EXPORT_ERROR: 'UPDATE_KDM_EXPORT_ERROR',
	UPDATE_SERVICE_DOCUMENTATION_FORM: 'UPDATE_SERVICE_DOCUMENTATION_FORM',
	UPDATE_SERVICE_DOCUMENTATION_FORM_ERRORS: 'UPDATE_SERVICE_DOCUMENTATION_FORM_ERRORS',
	SELECTED_CATALOG: 'SELECTED_CATALOG',
	SET_ALL_SUPPLEMENTARY_PARTS: 'SET_ALL_SUPPLEMENTARY_PARTS',
	SET_VEHICLE_CARD_OPENED: 'SET_VEHICLE_CARD_OPENED',
};

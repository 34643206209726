import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DMY_FORMAT, MONTH_COUNT, Y_FORMAT } from '@Constants/common';
import {
	DATE_MAXIMUM_CHARACTER,
	DSB_SELECTABLE_DAY_LIMIT,
	FIRST_DOT_INDEX,
	SECOND_DOT_INDEX,
} from '@Constants/vehicle';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);

export const today = dayjs(new Date()).format(DMY_FORMAT);

export const getMilliseconds = (date, format = DMY_FORMAT) => dayjs(date, format).valueOf();
export const formatDate = (date, format = DMY_FORMAT) => dayjs(date).format(format).toString();
export const isDateValid = (date, format = DMY_FORMAT) => dayjs(date, format, true).isValid();
export const isDateAfter = (firstDate, secondDate, format = DMY_FORMAT) =>
	dayjs(firstDate, format).isAfter(dayjs(secondDate, format));
export const isDateEqualOrAfter = (firstDate, secondDate, format = DMY_FORMAT) =>
	dayjs(firstDate, format).isSameOrAfter(dayjs(secondDate, format));
export const isDateInFuture = date => dayjs(date, DMY_FORMAT).isAfter(dayjs(today, DMY_FORMAT));
export const addTimeToDate = (date, type, time, format = DMY_FORMAT) => dayjs(date, format).add(time, type);

export const isDateInRange = (date, MAX_DAY_COUNT_FROM_TODAY) => {
	const maximumDateFromToday = dayjs(today, DMY_FORMAT).add(MAX_DAY_COUNT_FROM_TODAY, 'days');
	const isDateInMaximumRange = dayjs(date, DMY_FORMAT).isBefore(maximumDateFromToday);

	return !isDateInMaximumRange;
};

export const repositionDateDots = date => {
	let newValue = date.replace(/\D/g, '').slice(0, DATE_MAXIMUM_CHARACTER);
	if (newValue.length >= SECOND_DOT_INDEX) {
		newValue = `${newValue.slice(0, FIRST_DOT_INDEX)}.${newValue.slice(
			FIRST_DOT_INDEX,
			SECOND_DOT_INDEX - 1
		)}.${newValue.slice(SECOND_DOT_INDEX - 1)}`;
	} else if (newValue.length > FIRST_DOT_INDEX) {
		newValue = `${newValue.slice(0, FIRST_DOT_INDEX)}.${newValue.slice(FIRST_DOT_INDEX)}`;
	}
	return newValue;
};

export const setMomentLanguage = lang => {
	dayjs.locale(lang);
};

export const getDatePickerYears = () => {
	const oldestSelectableYear = parseInt(dayjs().subtract(DSB_SELECTABLE_DAY_LIMIT, 'days').format(Y_FORMAT), 10);
	const newestSelectableyear = parseInt(dayjs().add(2, 'years').format(Y_FORMAT), 10);
	const oldestDisabledYear = oldestSelectableYear - ((newestSelectableyear - oldestSelectableYear + 1) % MONTH_COUNT);
	return [
		{ startingYear: oldestSelectableYear, endingYear: newestSelectableyear },
		{ startingYear: oldestDisabledYear, endingYear: oldestSelectableYear - 1 },
	];
};

import { FC, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from '@Lib/i18n';
import Icon, { IconType } from '@isp/icon';
import './index.scss';

export enum ErrorIntroduction {
	WAS_NOT_INTENTION = 'common:error-not-intention',
	USUALLY_WORKS_BETTER = 'common:error-works-better',
	NOT_STANDARD = 'common:error-not-standard',
	NOT_WENT_AS_PLANNED = 'common:error-not-planned',
}

export enum ErrorAwareness {
	WILL_FIX_THAT = 'common:will-fix-that',
	WORKING_ON_IT = 'common:working-on-it',
}

export enum ErrorScenario {
	EXPLOSION_DRAWING_ERROR = 'common:explosion-drawing-options-error',
	PURCHASE_OPTIONS_ERROR = 'common:purchase-options-error',
	LICENSES_ERROR = 'common:licenses-error',
	DIAGNOSIS_LICENSE_ERROR = 'common:diagnosis-license-error',
	DSB_ERROR = 'common:dsb-error',
	RETAILERS_ERROR = 'common:retailers-error',
	PARTS_ORDERS_ERROR = 'common:parts-orders-error',
	RETAILER_MAP_ERROR = 'common:retailer-map-error',
	RETAILER_CAMPAIGN_ERROR = 'common:retailer-campaign-error',
	REQUEST_ERROR = 'common:request-error',
	VEHICLE_PARTS_ERROR = 'common:vehicle-parts-error',
	VEHICLE_PARTS_PACKAGE_ERROR = 'common:vehicle-parts-package-error',
	CATALOG_ERROR = 'common:catalog-error',
	USER_INFO_ERROR = 'common:user-info-error',
	COMPANY_INFO_ERROR = 'common:company-info-error',
	PRODUCT_INFO_ERROR = 'common:product-info-error',
	BASKET_INFO_ERROR = 'common:basket-info-error',
	LINAS_SEARCH_ERROR = 'common:linas-search-error',
	WORKSHOP_EQUIPMENT_ERROR = 'common:workshop-equipment-error',
	CONTENT_ERROR = 'common:content-error',
	ORDER_DETAILS_ERROR = 'common:order-details-error',
	PURCHASE_AUTH_ERROR = 'common:purchase-auth-error',
	RMS_ERROR = 'common:rms-error',
	RDS_ERROR = 'common:rds-error',
}

export enum ErrorAction {
	TRY_AGAIN = 'common:try-again-later',
	TRY_AGAIN_ACTION = 'common:try-again-action',
	TRY_AGAIN_INPUT_SAVED = 'common:try-again-input-saved',
	TRY_AGAIN_CONTACT_US = 'common:try-again-contact-us',
	UPDATE_YOUR_BROWSER = 'common:update-browser',
	RELOAD_PAGE = 'common:reload-page',
	CHECK_YOUR_NOTIFICATIONS = 'common:check-notifications',
	CONTACT_US_ACTION = 'common:contact-us-action',
	MEANWHILE_SEARCH_PARTS = 'common:error-search-part-number',
	MEANWHILE_VEHICLE_PARTS_SEARCH = 'common:meanwhile-enter-part-number-above',
}

export interface SadCarErrorProps {
	flow?: 'row' | 'column';
	intro?: ErrorIntroduction;
	scenario: ErrorScenario;
	awareness?: ErrorAwareness;
	action: ErrorAction;
	className?: string;
	fullWidth?: boolean;
	onClick?: MouseEventHandler;
	tParams?: any;
}

const SadCarError: FC<SadCarErrorProps> = ({
	flow = 'row',
	intro = '',
	scenario,
	awareness = '',
	action,
	className = '',
	fullWidth = false,
	onClick = () => {},
	tParams = {},
}) => {
	const { t } = useTranslation(['common']);
	const errorMmessage = [t(intro, tParams), t(scenario, tParams), t(awareness, tParams)].join(' ');
	return (
		<div className={classNames(className, 'sadcar-error p-5 disp-flex train__center train__justify-center', flow)}>
			<Icon className="sadcar-error__icon" type={IconType.SAD_CAR} />
			<div className={classNames('fs-16 sadcar-error__text', { 'full-width': fullWidth })}>
				<p>{errorMmessage}</p>
				<br />
				<p>
					<Trans i18nKey={action} keyParams={tParams}>
						action
						<span className="text-underline cursor-pointer" onClick={onClick} data-hover-azure>
							link
						</span>
					</Trans>
				</p>
			</div>
		</div>
	);
};

export default SadCarError;

import { actionTypes } from '@Reducers/organization/actionTypes';

export const initialOrganizationState = {
	organizationType: undefined,
	organizationInfo: {},
	dealers: undefined,
	dealersWebpartsInactive: false,
	dealersWebpartsPending: false,
	dealersLoading: true,
	dealersError: false,
	selectedRetailerID: null,
	hideDealerLoading: false,
	selectedDealerToHide: null,
	isConsentGiven: false,
	finBasedOrders: [],
	ordersLoading: false,
	ordersError: false,
	organizationUsers: undefined,
};

export const organizationReducer = (state = initialOrganizationState, action) => {
	switch (action.type) {
		case actionTypes.GET_ORGANIZATION_DEALERS:
			return {
				...state,
				dealers: action.dealers,
				dealersWebpartsInactive: action.dealersWebpartsInactive,
				dealersWebpartsPending: action.dealersWebpartsPending,
				dealersLoading: action.dealersLoading,
				dealersError: action.dealersError,
			};
		case actionTypes.HIDE_ORGANIZATION_DEALER_REQUEST:
			return {
				...state,
				hideDealerLoading: action.hideDealerLoading,
				selectedDealerToHide: action.selectedDealerToHide,
			};
		case actionTypes.GET_ORGANIZATION_DETAILS:
			return {
				...state,
				organizationInfo: action.organizationInfo,
			};
		case actionTypes.GET_ORGANIZATION_USERS:
			return {
				...state,
				organizationUsers: action.organizationUsers,
			};
		case actionTypes.ASSIGN_ADMIN_RIGHT:
			return {
				...state,
				loading: action.loading,
				error: action.error,
			};
		case actionTypes.REVOKE_ADMIN_RIGHT:
			return {
				...state,
				loading: action.loading,
				error: action.error,
			};
		case actionTypes.EDIT_ORGANIZATION_MAIL_ADDRESS:
			return {
				...state,
				editOrganizationMailAddressLoading: action.editOrganizationMailAddressLoading,
				editOrganizationMailAddressError: action.editOrganizationMailAddressError,
			};
		case actionTypes.UPDATE_PREFERRED_DEALER:
		case actionTypes.UPDATE_DELIVERY_ADDRESS:
			return {
				...state,
				dealers: action.dealers,
				dealersLoading: action.dealersLoading,
				dealersError: action.dealersError,
			};
		case actionTypes.SET_DEALER_ACCORDION:
			return {
				...state,
				selectedRetailerID: action.selectedRetailerID,
			};
		case actionTypes.GET_CONSENT_SETTINGS:
			return {
				...state,
				isConsentGiven: action.isConsentGiven,
				finBasedOrders: action.finBasedOrders,
				ordersLoading: action.ordersLoading,
				ordersError: action.ordersError,
			};
		case actionTypes.GET_ORGANIZATION_TYPE:
			return {
				...state,
				organizationType: action.organizationType,
			};
		case actionTypes.DELETE_RETAILER:
			return {
				...state,
				loading: action.loading,
				error: action.error,
			};
		default:
			return state;
	}
};

export default organizationReducer;

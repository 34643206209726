import { CSSProperties, FC } from 'react';
import { Trans } from '@Lib/i18n';
import ISPIcon, { IconSize, IconType, IconVariant } from '@isp/icon';
import ISPLink, { LinkVariant } from '@isp/link';
import classNames from 'classnames';

interface ISPErrorProps {
	icon?: IconType;
	iconVariant?: IconVariant;
	iconSize?: IconSize;
	handleReload?: Function;
	errorMessage?: string;
	reloadElementId?: string;
	direction?: 'flex-start' | 'justify-center' | 'flex-end';
	className?: string;
	style?: CSSProperties;
	stack?: boolean;
	translatedValueProvided?: boolean;
}

const ISPError: FC<ISPErrorProps> = ({
	icon = IconType.ERROR2,
	iconVariant = IconVariant.BLOODRED,
	iconSize = IconSize.SMALL,
	handleReload = null,
	errorMessage = null,
	reloadElementId = null,
	direction = 'flex-start',
	stack = false,
	className = '',
	style = {},
	translatedValueProvided = false,
}) => {
	const getMessage = () => {
		switch (true) {
			case handleReload !== null:
				return 'common:content-load-error-reload-page';
			case errorMessage !== null:
				return errorMessage;
			default:
				return 'common:something-went-wrong';
		}
	};

	return (
		<div
			className={classNames(`train__flex fs-14 train__center train__${direction} ${className}`, {
				'disp-flex-column': stack,
			})}
			style={style}
		>
			<ISPIcon type={icon} size={iconSize} variant={iconVariant} className="m-r-1" />
			<div className="color-black-two">
				{!translatedValueProvided && (
					<Trans i18nKey={getMessage()}>
						{'Content can’t be loaded. Please try to '}
						<ISPLink
							inline
							underline
							variant={LinkVariant.SECONDARY}
							onClick={e => handleReload(e)}
							id={reloadElementId}
						>
							{'reload the page'}
						</ISPLink>
						{'.'}
					</Trans>
				)}
				{translatedValueProvided && errorMessage}
			</div>
		</div>
	);
};

export default ISPError;

import { createRef, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import Icon, { IconSize, IconType, IconVariant } from '@isp/icon';
import classNames from 'classnames';
import ISPTooltip, { TooltipThemes, TooltipPositions } from '@isp/tooltip';
import { EquipmentAvailabilityStatus } from '@Constants/product/equipment';

const AvailabilityInfo = ({ id, availabilityValue, showInfoIcon, description, isItemUnavailable = false }) => {
	const { t } = useTranslation(['common', 'shop']);
	const [showTooltip, setShowTooltip] = useState(false);
	const tooltipRef = createRef<HTMLDivElement>();
	const { LOW_AVAILABILITY, NOT_AVAILABLE } = EquipmentAvailabilityStatus;
	const iconVariant = LOW_AVAILABILITY === availabilityValue ? IconVariant.AMBER : IconVariant.BLOODRED;

	const TOOLTIP_DESCRIPTION = {
		[NOT_AVAILABLE]: <p className="fs-12">{t('shop:equipment-out-of-stock')}</p>,
		[LOW_AVAILABILITY]: (
			<>
				<p className="fs-12 m-b-2">{t('shop:low-availability-tooltip-description1')}</p>
				<p className="fs-12">{t('shop:low-availability-tooltip-description2')}</p>
			</>
		),
	};

	useEffect(() => {
		if (showTooltip) {
			ReactTooltip.show(tooltipRef.current);
		} else {
			ReactTooltip.hide(tooltipRef.current);
		}
	}, [showTooltip]);

	return (
		<div
			ref={tooltipRef}
			data-tip=""
			className="float-right disp-flex train__center"
			data-for={`availability-tooltip${id}`}
			onMouseEnter={() => setShowTooltip(true)}
			onMouseLeave={() => setShowTooltip(false)}
		>
			<span className={classNames({ 'op-4': isItemUnavailable })}>{t(description)}</span>
			{showInfoIcon && (
				<Icon
					type={IconType.INFO}
					variant={iconVariant}
					size={IconSize.SMALL}
					className={classNames('m-l-1', { 'op-4': isItemUnavailable })}
				/>
			)}
			{showTooltip && showInfoIcon && (
				<ISPTooltip
					theme={TooltipThemes.DARK}
					id={`availability-tooltip${id}`}
					place={TooltipPositions.TOP}
					getContent={() => {
						return TOOLTIP_DESCRIPTION[availabilityValue];
					}}
				/>
			)}
		</div>
	);
};

export default AvailabilityInfo;

import { createRef } from 'react';
import Link, { LinkVariant } from '@isp/link';
import ReactTooltip from 'react-tooltip';
import { Trans, useTranslation } from 'react-i18next';
import { goToXshopOverview } from '@Helpers/shop/redirectXshopOverView';
import ISPTooltip, { TooltipThemes } from '@isp/tooltip';
import { Events, WindowTargetAttributes } from '@Constants/common';

const XentryShopAccessTooltip = ({ children }) => {
	const { t } = useTranslation(['shop']);
	const loginTooltipRef = createRef<HTMLDivElement>();

	return (
		<>
			<div
				data-tip=""
				ref={loginTooltipRef}
				data-effect="solid"
				data-event={Events.CLICK}
				data-for="login-tooltip"
				onMouseEnter={() => ReactTooltip.show(loginTooltipRef.current)}
				onMouseLeave={() => ReactTooltip.hide(loginTooltipRef.current)}
			>
				{children}
			</div>
			<ISPTooltip
				theme={TooltipThemes.DARK}
				id="login-tooltip"
				getContent={() => <Content linkout={goToXshopOverview} t={t} />}
			/>
		</>
	);
};

const Content = ({ t, linkout }) => (
	<div className="m-v-2 fs-12">
		<div>{t('shop:authorization-note')}</div>
		<div>
			<Trans i18nKey="shop:request-purchase-authorization">
				<Link inline underline variant={LinkVariant.QUINARY} target={WindowTargetAttributes.SELF} onClick={linkout}>
					{'Request purchase authorization'}
				</Link>
				{' first.'}
			</Trans>
		</div>
	</div>
);

export default XentryShopAccessTooltip;

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';

export const getUniqueObjectListByProp = (listToUniquify, property: string) => {
	return [...Array.from(new Map(listToUniquify.map(item => [get(item, property), item])).values())];
};

export const unique = array => uniqWith(array, isEqual);

export const chain = collection => {
	const lodashChain = {
		_wrapped: collection,

		value() {
			return this._wrapped;
		},
		map(iteratee) {
			this._wrapped = this._wrapped?.map(iteratee);
			return this;
		},
		filter(predicate: (item) => boolean) {
			this._wrapped = this._wrapped?.filter(predicate);
			return this;
		},
		flatten() {
			this._wrapped = this._wrapped?.flat(1);
			return this;
		},
		uniq() {
			this._wrapped = this._wrapped?.filter((value, index, array) => array.indexOf(value) === index);
			return this;
		},
		take(count) {
			this._wrapped = this._wrapped?.slice(0, count);
			return this;
		},
		orderBy(iteratees: ((item) => any)[] | ((item) => any), orders?: ('asc' | 'desc')[]) {
			const compareFunction = (a, b): number => {
				for (let i = 0; i < iteratees.length; i += 1) {
					const iteratee = iteratees[i];
					const order = orders && orders[i] === 'desc' ? -1 : 1;

					const aValue = typeof iteratee === 'function' ? iteratee(a) : a[iteratee];
					const bValue = typeof iteratee === 'function' ? iteratee(b) : b[iteratee];

					if (aValue < bValue) {
						return -1 * order;
					}
					if (aValue > bValue) {
						return 1 * order;
					}
				}
				return 0;
			};

			this._wrapped.sort(compareFunction);
			return this;
		},
		sumBy(iteratee: ((item) => number) | string) {
			const sum = this._wrapped.reduce((acc, item) => {
				const value = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];
				return acc + value;
			}, 0);
			return sum;
		},
	};

	return lodashChain;
};

export const splitArrayByCondition = (arrayToBeSplit: any[], condition) => {
	const context = arrayToBeSplit.reduce(
		(ctx, element) => {
			if (condition(element)) {
				ctx.arrayMeetCondition.push(element);
			} else {
				ctx.arrayNotMeetCondition.push(element);
			}
			return ctx;
		},
		{ arrayMeetCondition: [], arrayNotMeetCondition: [] }
	);

	return [context.arrayMeetCondition, context.arrayNotMeetCondition];
};

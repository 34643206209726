import { client } from '@Umbraco/client';
import get from 'lodash/get';
import CONFIG from '@Umbraco/config.json';
import { Markets } from '@Constants/market';

export interface Newsletter {
	id: string;
	title?: string;
	description?: string;
	buttonLabel?: string;
	buttonUrl?: string;
}

export interface FooterMenuExternalUrl {
	title: string;
	url: string;
	id: string;
}

export interface FooterOrder {
	order: string[];
	internalLinksOrder: string[];
}

const toQrCodeData = item => ({
	id: item._id,
	qrCode: item?.qrCode?._url,
	website: item?.website,
	altText: item?.qrCode?.AltText || '',
});

const toFooterMenuExternalUrl = item => ({
	id: item._id,
	title: item?.title || '',
	url: item?.url || '',
});

const toFooterOrder = item => ({
	order: item.order,
	internalLinksOrder: item.internalLinksOrder,
});

const toNewsletter = item => ({
	id: item._id,
	key: get(item, 'key', ''),
	title: get(item, 'title', ''),
	description: get(item, 'description', ''),
	buttonLabel: get(item, 'button[0].linkCaption', ''),
	buttonUrl: get(item, 'button[0].linkUrl', ''),
});

export function getFooterOrder(market: string, lang: string) {
	const id = CONFIG.FOOTER_ORDER;

	return client(`/content/${id}`, market, lang)
		.then(res => toFooterOrder(res))
		.catch(() => {
			return null;
		});
}

export function getFooterMenuExternalUrls(market: string, lang: string) {
	const id = CONFIG.FOOTER_MENU_EXTERNAL_LINKS;

	return client(`/content/${id}`, market, lang)
		.then(async res => {
			const externalUrls = get(res, 'externalLinks', []);
			return externalUrls.map(toFooterMenuExternalUrl);
		})
		.catch(() => {
			return null;
		});
}

export function getNewsletter(market: string, lang: string) {
	const id = CONFIG.NEWSLETTER;

	return client(`/content/${id}`, market, lang)
		.then(toNewsletter)
		.catch(() => {
			return null;
		});
}

export function getQrCodeData(market: string, lang: string) {
	if (market.toLowerCase() !== Markets.TURKEY) {
		return null;
	}
	const id = CONFIG.ECOMMERCE_QR_CODE;
	return client(`/content/${id}`, market, lang)
		.then(toQrCodeData)
		.catch(() => {
			return null;
		});
}

import routes from '@Routes';
import { ErrorScenario } from '@Shared/error/SadCarError';

export const DEFAULT_EXPLOSION_DRAWING_INDEX = 0;

export enum PartCardType {
	REMAN_ADVANTAGES = 'ADVANTAGES',
	CARS = 'CARS',
	VANS = 'VANS',
	REMANUFACTURING_PROCESS = 'REMANUFACTURING_PROCESS',
	GENUINE_ADVANTAGES = 'GENUINE_ADVANTAGES',
	FAQ = 'FAQ',
}

export const DIVISON_MAPPING = {
	[PartCardType.FAQ]: routes.FAQ,
	[PartCardType.REMANUFACTURING_PROCESS]: routes.REMAN_MANUFACTURING_PROCESS,
	[PartCardType.GENUINE_ADVANTAGES]: routes.GENUINE_ADVANTAGES,
	[PartCardType.REMAN_ADVANTAGES]: routes.REMAN_ADVANTAGES,
};

export enum ModalScreenTypes {
	CATEGORY_OVERVIEW = 'CATEGORY_OVERVIEW',
	PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
	PRODUCT_DETAIL = 'PRODUCT_DETAIL',
	ERROR = 'ERROR',
	LOADING = 'LOADING',
}

export const PARTS_PAGE_AREAS_IDENTIFIER = 'areas';

export enum CatalogueErrors {
	GENERAL = 1,
	RETAILER,
	PACKAGE,
}

export const CATALOGUE_ERROR_MESSAGE_MAPPING = {
	[CatalogueErrors.GENERAL]: ErrorScenario.VEHICLE_PARTS_ERROR,
	[CatalogueErrors.PACKAGE]: ErrorScenario.VEHICLE_PARTS_PACKAGE_ERROR,
};

import { useEffect } from 'react';
import get from 'lodash/get';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { TransformAttributes } from '@Constants/umbraco';

const useMarketTemplate = () => {
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const store = getOrCreateStore({});
			const market = get(store.getState(), 'configuration.market.code', '').toLowerCase();
			const spanElements = document.querySelectorAll(`span[${TransformAttributes.MARKET_URL_TEMPLATE_ID}]`);
			spanElements.forEach(spanElement => {
				const anchorElement = spanElement.children[0];
				anchorElement.setAttribute(
					'href',
					get(anchorElement, 'href').replace(TransformAttributes.MARKET_URL_TEMPLATE, market)
				);
			});
		}
	});
};

export default useMarketTemplate;

import storage from '@Storage';
import get from 'lodash/get';

// TODO: differ session and local storage
export enum StorageKeys {
	APP_VERSION = 'APP_VERSION',
	STORAGE_KEY_REMAN_REQUEST_MODAL = 'remanRequestModalStorageKey',
	STORAGE_KEY_FEATURE_MODAL_REMIND_LATER_START_TIME = 'newFeatureRemindLaterClickingTime',
	STORAGE_KEY_SUPPORT_MODAL = 'supportModalStorageKey',
	STORAGE_KEY_INCIDENT_MANAGEMENT_BANNER = 'incidentManagementBanner',
	STORAGE_KEY_BASKET_SELECTION = 'sessionBasedBasketSelection',
	VEHICLE_LIST_ACTIVE_SORTING_TYPE = 'vehicleListActiveSortingType',
	LUCKY_DRAW_DISPLAYED = 'luckyDrawDisplayed',
}

/**
 * Compare version strings and return true if first version is greater.
 * @param v1 first version string.
 * @param v2 second version string.
 */
export function compareVersions(v1: string, v2: string) {
	const version1 = v1.split('.');
	const version2 = v2.split('.');
	const [v1Major, v1Minor, v1Patch] = [
		Number(get(version1, '[0]', '0')),
		Number(get(version1, '[1]', '0')),
		Number(get(version1, '[2]', '0')),
	];
	const [v2Major, v2Minor, v2Patch] = [
		Number(get(version2, '[0]', '0')),
		Number(get(version2, '[1]', '0')),
		Number(get(version2, '[2]', '0')),
	];
	return v1Major > v2Major || (v1Major === v2Major && v1Minor > v2Minor) || (v1Minor === v2Minor && v1Patch > v2Patch);
}

export function getLatestVersion(releases: any[]) {
	let latestVersion = get(releases, '[0]', {});
	releases.forEach(function f(release) {
		if (compareVersions(release.version, latestVersion.version)) {
			latestVersion = release;
		}
	});
	return latestVersion;
}

export const clearStorageIncidentManagementBanner = () =>
	sessionStorage.removeItem(StorageKeys.STORAGE_KEY_INCIDENT_MANAGEMENT_BANNER);

export const clearCacheNewFeature = () => {
	sessionStorage.removeItem(StorageKeys.STORAGE_KEY_FEATURE_MODAL_REMIND_LATER_START_TIME);
};

export const clearStorageBasketSelection = () => sessionStorage.removeItem(StorageKeys.STORAGE_KEY_BASKET_SELECTION);

export const clearSessionStorageLuckyDrawDisplayed = () => sessionStorage.removeItem(StorageKeys.LUCKY_DRAW_DISPLAYED);

export const setLuckyDrawDisplayed = () => {
	sessionStorage.setItem(StorageKeys.LUCKY_DRAW_DISPLAYED, 'true');
};

export const isNewFeatureDisplayed = () => {
	return (
		Boolean(sessionStorage.getItem(StorageKeys.STORAGE_KEY_FEATURE_MODAL_REMIND_LATER_START_TIME)) ||
		Boolean(storage.getItem(StorageKeys.APP_VERSION))
	);
};

export const isLuckyDrawDisplayed = () => {
	return Boolean(sessionStorage.getItem(StorageKeys.LUCKY_DRAW_DISPLAYED));
};

import { FC, ReactElement, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import classNames from 'classnames';
import Stepper from '@isp/stepper';
import Loading, { LoadingSizes } from '@isp/loading';
import Price from '@isp/price';
import Icon, { IconSize, IconType, IconVariant } from '@isp/icon';
import { Equipment, updateEquipmentBasket } from '@Apis/shop';
import AvailabilityInfo from '@Partials/Equipment/Catalogue/EquipmentItem/AvailabilityInfo';
import LoginTooltip from '@Partials/Equipment/Catalogue/EquipmentItem/LoginTooltip';
import XentryShopAccessTooltip from '@Shared/tooltips/xentry-shop-access-tooltip';
import { AppDispatch } from '@Redux';
import { UPDATE_BASKET } from '@Reducers/shop/actions';
import { OPEN_REGISTRATION } from '@Reducers/modals/actions';
import { PriceConfigurationType } from '@Reducers/configuration/models';
import { UpdateEquipmentBasketRequestBody } from '@Reducers/shop/models';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { BASE64_PREFIX } from '@Constants/common';
import {
	STEPPER_MIN_COUNT,
	STEPPER_MAX_COUNT,
	EquipmentAvailabilityStatus,
	EQUIPMENT_AVAILABILITY_MAPPING,
} from '@Constants/product/equipment';
import { getComponentId, Pages, Components, Buttons } from '@Constants/google-analytics';
import { OrderType } from '@Constants/orders';

interface EquipmentItemProps {
	id: number;
	equipment: Equipment;
	isUserLoggedIn: boolean;
	updatedProductId: string;
	showBasketNotification: boolean;
	addToBasketLoading: boolean;
	authorizationDisabled: boolean;
	priceConfiguration: PriceConfigurationType;
}

const EquipmentItem: FC<EquipmentItemProps> = ({
	id,
	equipment,
	isUserLoggedIn,
	updatedProductId,
	addToBasketLoading,
	showBasketNotification,
	authorizationDisabled,
}) => {
	const [equipmentCount, setEquipmentCount] = useState(1);
	const dispatch: AppDispatch = useDispatch();
	const { LOW_AVAILABILITY, NOT_AVAILABLE } = EquipmentAvailabilityStatus;
	const { image, equipmentName, itemNumber, availability, price } = equipment;
	const availabilityMapping = EQUIPMENT_AVAILABILITY_MAPPING[availability];
	const { color, description } = availabilityMapping;
	const showInfoIcon = [LOW_AVAILABILITY, NOT_AVAILABLE].includes(availability);
	const isAddToBasketAvailable = isUserLoggedIn && availability !== NOT_AVAILABLE && !authorizationDisabled;
	const isAddToBasketDisabled = !isAddToBasketAvailable || addToBasketLoading || showBasketNotification;

	const register = () => dispatch(OPEN_REGISTRATION());

	const handleTooltip = (component: ReactElement) => {
		if (!isUserLoggedIn) {
			return <LoginTooltip register={register}>{component}</LoginTooltip>;
		}
		if (authorizationDisabled) {
			return <XentryShopAccessTooltip>{component}</XentryShopAccessTooltip>;
		}
		return component;
	};

	const handleAddToBasket = async e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		const requestData: UpdateEquipmentBasketRequestBody = {
			productId: itemNumber,
			quantity: equipmentCount,
		};
		const isItemAdded = await dispatch(
			UPDATE_BASKET({
				data: requestData,
				addedProductName: equipmentName,
				showNotification: true,
				quantity: equipmentCount,
				cartType: OrderType.EQUIPMENT,
				updateBasketFunction: updateEquipmentBasket,
				useTimeZone: true,
			})
		);
		if (isItemAdded) setEquipmentCount(1);
	};

	return (
		<div className="equipment-item train__center">
			<div
				className={classNames(
					'equipment-item__image h-100-p b-r-1 bc-asphalt',
					{ 'bg-white': image },
					{ 'bg-white-smoke': !image }
				)}
			>
				{image && <img alt={equipmentName} src={`${BASE64_PREFIX}${image}`} />}
				{!image && <Icon type={IconType.CAMERA} variant={IconVariant.BROWNGREY} />}
			</div>
			<div data-public={!isUserLoggedIn} className="DaimlerCS-Demi fs-18 color-black-two equipment-item__name">
				{equipmentName}
			</div>
			<div className="color-boulder">{itemNumber}</div>
			<div className={`DaimlerCS-Bold disp-flex train__center ${color}`}>
				<AvailabilityInfo
					id={id}
					description={description}
					showInfoIcon={showInfoIcon}
					availabilityValue={availability}
				/>
			</div>
			{handleTooltip(
				<Stepper
					value={equipmentCount}
					minCount={STEPPER_MIN_COUNT}
					maxCount={STEPPER_MAX_COUNT}
					onChange={setEquipmentCount}
					disabled={!isAddToBasketAvailable}
				/>
			)}
			<Price amount={price} />
			{updatedProductId === itemNumber && <Loading size={LoadingSizes.MICRO} />}
			{updatedProductId !== itemNumber &&
				handleTooltip(
					<Icon
						colorOnHover
						size={IconSize.SMALL}
						type={IconType.BASKET}
						variant={IconVariant.BLUE}
						onClick={e => handleAddToBasket(e)}
						id={getComponentId([Pages.EQUIPMENT, Components.EQUIPMENT_ITEM, Buttons.ADD_TO_BASKET])}
						clickable={!isAddToBasketDisabled}
						disabled={isAddToBasketDisabled}
					/>
				)}
		</div>
	);
};

const mapStateToProps = state => ({
	isUserLoggedIn: state.user.isUserLoggedIn,
	addToBasketLoading: state.shop.addToBasketLoading,
	updatedProductId: state.shop.updatedProductId,
	showBasketNotification: state.shop.showBasketNotification,
	priceConfiguration: state.configuration.priceConfiguration,
});

export default connect(mapStateToProps)(EquipmentItem);

import Router from 'next/router';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { RootState } from '@Redux';
import routes, { getRoutesAs } from '@Routes';

export const handleASPNavigation = () => {
	const store = getOrCreateStore({});
	const state: RootState = store.getState();
	if (!state.user.isUserLoggedIn) return;

	const isASPPath = Router.pathname.split('/')?.[2] === 'asp';

	if (state.user.isASPUser && !isASPPath) {
		Router.replace(routes.ASP_HOME, getRoutesAs(routes.ASP_HOME, { market: 'de', ...Router.query }));
	}
	if (!state.user.isASPUser && isASPPath) {
		Router.replace(routes.HOME, getRoutesAs(routes.HOME, { market: 'de', ...Router.query }));
	}
};

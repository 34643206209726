import axios, { AxiosRequestConfig, CancelTokenSource, Method } from 'axios';
import axiosRetry from 'axios-retry';
import queryString from 'query-string';
import get from 'lodash/get';
import format from 'string-format';
import serviceEndpoints from '@Service-endpoints';
import { getToken, login } from '@User-operations';
import { getOrCreateStore } from '@Lib/with-redux-store';
import getAppEnvironment from '@Helpers/common/getAppEnvironment';
import {
	HTTPResponseCodes,
	HTTP_METHODS_WITH_QUERY_PARAMS,
	MIMETypes,
	HTTPHeader,
	CustomHTTPHeader,
} from '@Constants/common';

interface RequestOptions {
	data?: any;
	formData?: any;
	customHeaders?: any;
	isFile?: boolean;
	suffix?: string;
	interpolate?: any;
	withCredentials?: boolean;
	meta?: any;
	shouldRetry?: boolean;
	timeout?: number;
	bodyData?: any;
	cancelToken?: CancelTokenSource;
	params?: any;
	useTimeZone?: boolean;
	preferredLanguage?: string;
}

interface Headers {
	[key: string]: any;
}

interface RequestConfig extends AxiosRequestConfig {
	'axios-retry': any;
}

const config = {
	APP_ENV: getAppEnvironment(),
};

axiosRetry(axios, { retries: 3 });

export const getEndpointPath = (path: string): string => {
	return get((serviceEndpoints as any).endpoints, path);
};

export const getBaseURL = (): string => {
	const env = config.APP_ENV;
	let configVar: string;

	if (typeof window === 'undefined') configVar = global.process.env.BASE_URL;

	return configVar || get((serviceEndpoints as any).config[env], 'baseUrl');
};

const requestData = (
	requestType: Method,
	path: string,
	options?: RequestOptions,
	contentType?: string,
	resolveWithHeaders?: boolean
): Promise<any> => {
	const timeoutSec = 60;
	const ms = 1000;
	const store = getOrCreateStore({});
	const { language } = store.getState().configuration || '';
	// eslint-disable-next-line max-len
	const selectedLang = options?.preferredLanguage || language;
	const market = (get(store.getState(), 'configuration.market.code') || '').toLowerCase();

	let headers: Headers = {
		[HTTPHeader.ACCEPT_LANGUAGE]: selectedLang,
		[HTTPHeader.CONTENT_TYPE]: contentType || MIMETypes.JSON,
		[HTTPHeader.CACHE_CONTROL]: 'no-cache',
		[HTTPHeader.MODIFIED_SINCE]: '0',
		[HTTPHeader.AUTHORIZATION]: getToken() ? `Bearer ${getToken()}` : '',
		[CustomHTTPHeader.MARKET_CODE]: market,
	};

	const opts = Object.assign({}, options);

	if (opts.useTimeZone)
		headers = { ...headers, [CustomHTTPHeader.TIMEZONE]: Intl.DateTimeFormat().resolvedOptions().timeZone };

	if (opts.customHeaders) Object.assign(headers, opts.customHeaders);

	let url = getEndpointPath(path);
	const baseURL = getBaseURL();

	if (opts.interpolate) url = format(url, opts.interpolate);

	if (opts.suffix) url += `/${String(opts.suffix)}`;

	if (HTTP_METHODS_WITH_QUERY_PARAMS.includes(requestType) && opts.data) {
		url += `?${queryString.stringify(opts.data)}`;
	}

	if (opts.params) {
		const query = opts.params?.values?.reduce(
			(accumulator, currentValue, currentIndex) =>
				`${accumulator}${currentIndex !== 0 ? '&' : ''}${opts.params.name}=${currentValue}`,
			opts.data ? '&' : '?'
		);
		url += query;
	}

	const requestConfig: RequestConfig = {
		url,
		baseURL,
		headers,
		method: requestType,
		responseType: opts.isFile ? 'blob' : 'json',
		timeout: opts.timeout ? opts.timeout * ms : timeoutSec * ms,
		withCredentials: opts.withCredentials,
		'axios-retry': opts.shouldRetry ? { retries: 3 } : { retries: 0 },
	};

	if (requestType !== 'GET' && requestType !== 'DELETE') requestConfig.data = opts.data || opts.formData;

	if (opts.bodyData) requestConfig.data = opts.bodyData;

	if (opts.cancelToken) requestConfig.cancelToken = opts.cancelToken.token;

	return new Promise((resolve, reject) => {
		axios(requestConfig)
			.then(response => {
				return resolve(resolveWithHeaders ? response : response.data);
			})
			.catch(error => {
				if (error.response && error.response.status === HTTPResponseCodes.UNAUTHORIZED) {
					if (typeof window !== 'undefined') {
						return login();
					}
				}
				return reject(error);
			});
	});
};

export default requestData;

/* Helper network functions */

export const checkEndpointAvailability = async (endpoint: string) => {
	return new Promise((resolve, reject) => {
		axios
			.head(endpoint)
			.then(() => {
				return resolve(true);
			})
			.catch(() => {
				// eslint-disable-next-line prefer-promise-reject-errors
				return reject(false);
			});
	});
};

export const getCancelToken = () => {
	return axios.CancelToken.source();
};

export const parseResponseWithHeaders = (response, parseData = null) => ({
	config: response?.config || null,
	data: parseData ? parseData(response) : response || null,
	headers: response?.headers || null,
	request: response?.request || null,
	status: response?.status || null,
	statusText: response?.status || '',
});

import { actionTypes } from '@Reducers/pageVisitHistory/actionTypes';

export const initialState = [];

export const pageVisitHistoryReducer = (state = initialState, action) => {
	if (action.type === actionTypes.UPDATE_PAGE_VISIT_HISTORY) {
		return [...state, action.pageVisitHistory];
	}

	return state;
};

export default pageVisitHistoryReducer;

export enum EquipmentAvailabilityStatus {
	AVAILABLE,
	LOW_AVAILABILITY,
	NOT_AVAILABLE,
}

export const STEPPER_MIN_COUNT = 1;
export const STEPPER_MAX_COUNT = 99;
export const WEBPARTS_STEPPER_MAX_COUNT = 200;
export const MIN_LENGTH_EQUIPMENT_SEARCH_TERM = 3;
export const EQUIPMENT_DEFAULT_PAGE_SIZE = 10;
export const EQUIPMENT_DEFAULT_PAGE_NUMBER = 1;
export const EQUIPMENT_HEADER_WIS_PRODUCT_CODE = '000000006523113299';
export const EQUIPMENT_HEADER_PARTS_INFORMATION_PRODUCT_CODE = '000000006523111199';

export const EQUIPMENT_AVAILABILITY_MAPPING = {
	[EquipmentAvailabilityStatus.AVAILABLE]: {
		color: 'color-status-ok',
		description: 'shop:equipment-available',
	},
	[EquipmentAvailabilityStatus.LOW_AVAILABILITY]: {
		color: 'color-amber',
		description: 'shop:equipment-low-availability',
	},
	[EquipmentAvailabilityStatus.NOT_AVAILABLE]: {
		color: 'color-red',
		description: 'shop:equipment-not-available',
	},
};

import { Markets } from '@Constants/market';

export const TERMS_AND_CONDITIONS_PAYMENT_CLIENT_APPROVAL = false;
export const ORDER_LIST_LIMIT = 10;
export const PARTS_ORDERS_LIST_LIMIT = 3;
export const ORDER_LOAD_MORE_COUNT = 5;

export const EMAIL_ORDER_TEXT_MARKETS = [Markets.CHINA];
export const CUSTOMS_FEE_MARKETS = [Markets.SWITZERLAND, Markets.UNITED_KINGDOM];

export enum OrderPageLevel {
	VEHICLE_OVERVIEW_ORDERS = 'VEHICLE_OVERVIEW_ORDERS',
	ACCOUNT_ORDERS = 'ACCOUNT_ORDERS',
}

export enum OrderType {
	WEBPARTS = 'WEBPARTS',
	DIGITAL = 'DIGITAL',
	EQUIPMENT = 'EQUIPMENT',
	DIAGNOSIS = 'DIAGNOSIS',
	RMI = 'RMI',
}

export enum OrderResponseCode {
	SUCCESS = 'SUCCESS',
	ARVATO_ORDERS_FAILED = 'ARVATO_ORDERS_FAILED',
	WEBPARTS_ORDERS_FAILED = 'WEBPARTS_ORDERS_FAILED',
	EXTERNAL_API_EXCEPTION = 'EXTERNAL_API_EXCEPTION',
}

export enum OrderNumberType {
	OrderNumber = 'ORDER_NUMBER',
	JobNumber = 'JOB_NUMBER',
}
export const MARKETS_WITH_GST_TAX = [Markets.AUSTRALIA];

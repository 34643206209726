import { updatePersonalSettings } from '@User-operations';
import Dexie, { Table } from 'dexie';
import NextI18Next from '@Lib/i18n';
import { OrderStockType } from '@Constants/account';

export interface PersonalSettingsDB {
	useShowNetPriceShortcut: boolean;
	isVehiclePartsListView: boolean;
	selectedPartLanguage: string;
	contentLanguages: string[];
	selectedWebPartsOrderType: string;
	id?: number;
}

export class UserSettingsDB extends Dexie {
	userSettingsData!: Table<PersonalSettingsDB>;

	public async getLastItem() {
		try {
			const data = await userSettingsDb.userSettingsData.orderBy('id').last();
			return data;
		} catch (error) {
			return {
				useShowNetPriceShortcut: false,
				isVehiclePartsListView: true,
				selectedPartLanguage: NextI18Next.i18n.language,
				selectedWebPartsOrderType: OrderStockType.IMMEDIATE_REQUIREMENT,
				id: '',
			};
		}
	}

	public async updateLastItem(changes) {
		const lastItemInDb = await userSettingsDb.getLastItem();
		return userSettingsDb.userSettingsData.update(lastItemInDb.id, changes).then(() => {
			const personalSettingsData = {
				netPriceShortcut: changes?.useShowNetPriceShortcut,
				partCatalogView: changes?.isVehiclePartsListView,
				catalogLanguage: changes?.selectedPartLanguage || lastItemInDb.selectedPartLanguage,
				selectedWebPartsOrderType: changes?.selectedWebPartsOrderType,
			};
			updatePersonalSettings(personalSettingsData);
		});
	}

	constructor() {
		super('userSettingsDataBase');
		this.version(1).stores({
			userSettingsData:
				// eslint-disable-next-line max-len
				'++id, *contentLanguages, useShowNetPriceShortcut,isVehiclePartsListView, selectedPartLanguage, selectedWebPartsOrderType',
		});
	}
}

export const userSettingsDb = new UserSettingsDB();

export const actionTypes = {
	DELETE_PRODUCT_FROM_BASKET: 'DELETE_PRODUCT_FROM_BASKET',
	DELETE_WEBPARTS_FROM_BASKET: 'DELETE_WEBPARTS_FROM_BASKET',
	GET_BASKET_TOTAL_COUNT: 'GET_BASKET_TOTAL_COUNT',
	GET_ALL_BASKET_DATA: 'GET_ALL_BASKET_DATA',
	GET_DIAGNOSIS_BASKET_PRODUCTS: 'GET_DIAGNOSIS_BASKET_PRODUCTS',
	GET_DIGITAL_BASKET_PRODUCTS: 'GET_DIGITAL_BASKET_PRODUCTS',
	GET_EQUIPMENT_BASKET_PRODUCTS: 'GET_EQUIPMENT_BASKET_PRODUCTS',
	GET_WEBPARTS_BASKET_PRODUCTS: 'GET_WEBPARTS_BASKET_PRODUCTS',
	GET_WEBPARTS_BASKET_CHECKOUT_SUMMARY: 'GET_WEBPARTS_BASKET_CHECKOUT_SUMMARY',
	UPDATE_BASKET: 'UPDATE_BASKET',
	TOGGLE_SHOPPING_BASKET_NOTIFICATION: 'TOGGLE_SHOPPING_BASKET_NOTIFICATION',
	GET_ORDER_ADDRESSES: 'GET_ORDER_ADDRESSES',
	UPDATE_HARDWARE_ID: 'UPDATE_HARDWARE_ID',
	GET_ACCESS_STATUS: 'GET_ACCESS_STATUS',
	UPDATE_ACCESS_STATUS: 'UPDATE_ACCESS_STATUS',
	SET_ROW_ORDER_PRODUCTS: 'SET_ROW_ORDER_PRODUCTS',
	PLACE_WEBPARTS_ORDER: 'PLACE_WEBPARTS_ORDER',
	UPDATE_LAST_DELETED_BASKET: 'UPDATE_LAST_DELETED_BASKET',
	UPDATE_BASKET_MULTIPLE_ITEMS: 'UPDATE_BASKET_MULTIPLE_ITEMS',
	SET_SHOW_NET_PRICE: 'SET_SHOW_NET_PRICE',
	SET_WEBPARTS_CHECKOUT_COMMENT: 'SET_WEBPARTS_CHECKOUT_COMMENT',
	SET_CACHE_STATUS_FOR_DIAGNOSIS_LICENSES: 'SET_CACHE_STATUS_FOR_DIAGNOSIS_LICENSES',
	BULK_DELETE_NON_WEBPARTS_BASKET: 'BULK_DELETE_NON_WEBPARTS_BASKET',
	BULK_DELETE_WEBPARTS_BASKET: 'BULK_DELETE_WEBPARTS_BASKET',
	SET_PART_SEARCH_INSIDE_BASKET: 'SET_PART_SEARCH_INSIDE_BASKET',
	RESET_FAILED_DELETION_BASKET_IDS: 'RESET_FAILED_DELETION_BASKET_IDS',
};
